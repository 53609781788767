import axios from 'axios';
import React , { useState , useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';

import {apiUrl} from '../../utils/config';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const WithdrawalPage = () => {

    const [WithdrawArr,setWithdrawArr] = useState([]);
    const [WithdrawDetail,setWithdrawDetail] = useState({});

    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);
  
    const handleShowText = (keyText) =>{
  
        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];
                      
            if(textObj.key === keyText){
                
                return textObj.value
            }
            
        }
        return null
        
    }
  
    
    const handleShowImage = (keyImage) =>{
      for (let i = 0; i < AllImageSite.length; i++) {
          const imageObj = AllImageSite[i];
                    
          if(imageObj.key === keyImage){
              
              return imageObj.value
          }
          
      }
      return null
    }


    const handleSystemWithdraw = (pageNo) =>{
        axios({
            url:apiUrl + `/withdraw?page=${pageNo}&per_page=12`,
            method:'get',
        }).then((res) =>{

            setWithdrawArr(res.data.data);
            setWithdrawDetail(res.data.message);
            
        }).catch((error) => {
            toast.error(error)
        });
    }

    const handlePageClick = (event) => {
        handleSystemWithdraw(event.selected);
    }

    useEffect(() => {
        handleSystemWithdraw(1);
    },[])

    return ( 
        <>
            <Helmet>
                <title>Last Withdrawals</title>
            </Helmet>
            <div className="withdrawalTexture">
                <img src={`${handleShowImage('withrawalTexture')}`} alt="" />
            </div>

            <div className="withdrawalMainBox">

                <div className="withdrawalTitle">
                    Last Withdrawals
                </div>

                <div className="withdrawalText">
                    {handleShowText("withdrawal_text")}
                </div>

                <div className="withdrawalBox">
                    <div className="totalWithdra">
                        <div className="WB-title"> Total Withdrawal </div>
                        <div className="WB-totalVal">
                            {/* <img src="/assets/image/dollorWhite.svg" alt="" /> */}
                            <div>{WithdrawDetail.sum_withdraw}</div>
                            <div>XBIT</div>
                        </div>
                    </div>
                    <img src={`${handleShowImage('cashWallet')}`} alt=""  className='WB-image' />
                    <div className="totalTransaction">
                        <div className="WB-title"> All Transaction </div>
                        <div className="WB-totalVal">
                            <div>{WithdrawDetail.total_withdraw}</div>
                        </div>
                    </div>
                </div>

                <div className="withdrawalTable">
                    <table className="secondSiteTable">
                        <tr>
                            <th>Username</th>
                            <th>Amount</th>
                            <th>Tx ID</th>
                        </tr>

                        {WithdrawArr.map((withdraw,index) =>
                            
                            <tr className="homeTableTr">
                                <td>
                                    {withdraw.client.username}
                                </td>
                                <td>
                                    {/* <img src="/assets/image/redDollar.svg" alt="" /> */}
                                    <span>{withdraw.amount}</span>
                                    <span>XBIT</span>
                                </td>
                                <td>
                                    {withdraw.tx_id ? withdraw.tx_id : 'Processing' }
                                </td>
                            </tr>

                        )}

                    </table>

                    <ReactPaginate
                        pageClassName="sitePaginationCard"
                        containerClassName="sitePagination"
                        activeClassName="active"
                        breakLabel="..."
                        nextLabel=""
                        onPageChange={(event) => handlePageClick(event)}
                        pageRangeDisplayed={5}
                        pageCount={WithdrawDetail.total_page}
                        previousLabel=""
                        renderOnZeroPageCount={null}

                    />


                    {/* <div className="sitePagination">
                        <div className="sitePaginationCard active">
                            <a href="#">1</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">2</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">3</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">4</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">5</a>
                        </div>
                        
                    </div> */}
                </div>

            </div>

        </>
     );
}
 
export default WithdrawalPage;