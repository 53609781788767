import Web3 from "web3";
import abiFile from "./abi.json";
import { ethers } from "ethers";
import { convertWeiToEth } from "..";
import { store } from "../../store";
import { updateWalletBalance } from "../../actions/app";
// import { ethers } from "ethers";

const contractAddress = process.env.REACT_APP_CONTRACT;
const provider = window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
// const appStage = process.env.NODE_ENV;
const appStage = process.env.REACT_APP_APP_STAGE;
const sepoliaRpcUrl = process.env.REACT_APP_SEPOLIA_TEST_NET_RPC;
const ethereumRpcUrl = process.env.REACT_APP_ETHEREUM_MAIN_NET_RPC;

const RPC_URLS = appStage === "development" ? sepoliaRpcUrl : ethereumRpcUrl;

const web3 = new Web3(RPC_URLS);
const signer = provider && provider.getSigner();
const contractProject = new web3.eth.Contract(abiFile, contractAddress , provider);



export const updateActiveBalance = () => {

    web3.eth.getBalance(store.getState().app.wallet.address).then((res) => {
        store.dispatch(updateWalletBalance(convertWeiToEth(res)))
    }).catch((err) => {
        console.log(err)
    })

}
export const getBalance = async (walletAddress) => web3.eth.getBalance(walletAddress)


// export const getLotteryValue = () => contractProject.methods.getBalance().call()

export const getLotteryCurrentId = () => contractProject.methods.currentLotteryId().call();

export const getLotteryValue = () => web3.eth.getBalance(contractAddress)

// export const getLotteryPlayers = () => contractProject.methods.getPlayers().call()
export const getLotteryAllPlayers = () => contractProject.methods.users().call()

export const getLotteryPlayers = (lotteryId , page = 1 , perPage = 10) => contractProject.methods.getLastFiveUsers(lotteryId , page , perPage).call()

export const getTotalLotteryPlayersCount = (lotteryId) => contractProject.methods.getTotalUserCount(lotteryId).call()

export const checkWinningPrize = async (account, index, lotteryId) => {

    return contractProject.methods.checkWinningPrize(index, lotteryId).call({
        from: account
    })

    const data = contractProject.methods.checkWinningPrize(index).encodeABI();

    return web3.eth.accounts.signTransaction({
        to: contractAddress,
        data: data,
        gas: 2000000 // Adjust gas as needed
    }, account);

    // Send the signed transaction to Alchemy for broadcasting
    return web3.eth.sendSignedTransaction(
        signedTransaction.rawTransaction
    );

    // contractProject.methods.checkWinningPrize(index).send({from : account})
}

export const getDetailOfLottery = (lotteryId) => contractProject.methods.lotteries(lotteryId).call()

export const getLotteryPlayerTicket = (userAddress, lotteryId) => contractProject.methods.getUserNumbers(userAddress, lotteryId).call()

export const getIsDrawingCompleted = () => contractProject.methods.isDrawingCompleted().call()

export const getLotteryNumbers = (lotteryId) => contractProject.methods.getLotteryWinningNumber(lotteryId).call()

export const getLotteryWinners = () => contractProject.methods.getWinners().call()

export const getUserTickets = (userAddress) => contractProject.methods.getTickets(userAddress).call()

export const getUserCanClaim = (userAddress) => contractProject.methods.claimable(userAddress).call()

// export const getUserClaimPrize = (userAddress , index , lotteryId) => contractProject.methods.claimPrize(index , lotteryId).call({
//     from : userAddress
// })

export const getUserClaimPrize = async (userAddress, index, lotteryId) => {
    // Connect to the provider (e.g., MetaMask)
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // await window.ethereum.enable();

    // Get the signer
    // const signer = provider.getSigner();

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    ethers.utils.getAddress(contractAddress);

    return signer.sendTransaction({
        to: contractAddress,
        // value: ethers.utils.parseEther(amount),
        data: contractProject.methods.claimPrize(index, lotteryId).encodeABI(),
    });

    // try {
    //     // Call the claimPrize function
    //     const result = await contractProject.methods.claimPrize(index, lotteryId).call({
    //         from : userAddress
    //     });
        
    //     return result;
    // } catch (error) {
    //     console.error("Error claiming prize:", error);
    //     throw error;
    // }
};

export const signWallet = async (wallet, message = "msg") => {
    await window.ethereum.send("eth_requestAccounts");
    const sign = await window.ethereum.request({
        method: 'personal_sign',
        params: [message, wallet, 'Random text'],
    });
    return sign
}

export const createTransaction = async (fromAddress, signKey, toAddress, value, gas = "21000") => {
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    ethers.utils.getAddress(contractAddress);

    const tx = await signer.sendTransaction({
        to: contractAddress,
        value: ethers.utils.parseEther("0.01"),
        data: contractProject.methods.enter([1, 2, 3, 4, 5], [1, 2]).encodeABI(),
    });

    console.log({ value: "0.01", contractAddress });
    console.log("tx", tx);

}

export const enterToLottery = async (numbers, amount) => {
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    ethers.utils.getAddress(contractAddress);

    return signer.sendTransaction({
        to: contractAddress,
        value: ethers.utils.parseEther(amount),
        data: contractProject.methods.compete(numbers).encodeABI(),
    });
}

export const enterToLotteryArr = async (numbers, amount) => {
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    ethers.utils.getAddress(contractAddress);

    return signer.sendTransaction({
        to: contractAddress,
        value: ethers.utils.parseEther(amount),
        data: contractProject.methods.competeArray(numbers).encodeABI(),
    });
}