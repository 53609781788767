import React, {useEffect, useState} from "react";
import axios from 'axios';
import Select from 'react-select';
import $ from 'jquery';

import {apiUrl} from '../../utils/config';
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import { Helmet } from "react-helmet";

const SearchLotteryPage = () => {

    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);

    
    const location = useLocation();
    const LotteryIdParams = new URLSearchParams(location.search).get('Lottery');

    let date = new Date();
  
    const handleShowText = (keyText) =>{
  
        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];
                      
            if(textObj.key == keyText){
                
                return textObj.value
            }
            
        }
        return null
        
    }
  
    
    const handleShowImage = (keyImage) =>{
      for (let i = 0; i < AllImageSite.length; i++) {
          const imageObj = AllImageSite[i];
                    
          if(imageObj.key == keyImage){
              
              return imageObj.value
          }
          
      }
      return null
  }

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color:'#111',
          color: state.isSelected ? '#fff' : '#111',
          backgroundColor: state.isSelected ? '#b92f28' : '',
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 90,
            display:'flex',
            borderRadius: '10px',
            backgroundColor:'#fff',
            border:'1px solid #000',
            color:"#111",
            fontSize:"14px",
            marginRight:"10px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color:'#111',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display : 'none'
        }),
        dropdownIndicator:(provided, state) => ({
            ...provided,
            color:'#DC3636'
        })
    }

    const customStyles1 = {
        option: (provided, state) => ({
          ...provided,
          color:'#111',
          color: state.isSelected ? '#fff' : '#111',
          backgroundColor: state.isSelected ? '#b92f28' : '',
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 130,
            display:'flex',
            borderRadius: '10px',
            backgroundColor:'#fff',
            border:'1px solid #000',
            color:"#111",
            fontSize:"14px",
            marginRight:"10px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color:'#111',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display : 'none'
        }),
        dropdownIndicator:(provided, state) => ({
            ...provided,
            color:'#DC3636'
        })
    }

    const SelectOptions = [
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        
    ];

    const monthOption=[
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ]

    // const [defaultOption , setdefaultOption] = useState(SelectOptions[(date.getFullYear() - 2021)]);
    // const [defaultOption1 , setdefaultOption1] = useState(monthOption[date.getMonth()]);

    let defaultOption = SelectOptions[(date.getFullYear() - 2021)];
    let defaultOption1 = monthOption[date.getMonth()];

    const [LotterySearch,setLotterySearch] = useState([]);
    const [LotterySelectResult,setLotterySelectResult] = useState([]);

    const [SearchMonth,setSearchMonth] = useState(date.getMonth());
    const [SearchYear,setSearchYear] = useState(date.getFullYear());
    
    
    const [TotalWinner,setTotalWinner] = useState(0);
    const [TotalPrize,setTotalPrize] = useState(0);

    const [LotterSelectNo,setLotterSelectNo] = useState({No5:[],No2:[]});

    let SearchMonthVal = date.getMonth(); 
    let SearchYearVal = date.getFullYear(); 
    

    const changeYearFunc = (e) =>{
        var get_year = e.value; 
        setSearchYear(get_year);
        SearchYearVal = get_year;
        SearchMonthVal = SearchMonth;
        handleSearchLottery();
    }

    const changeMonthFunc = (e) =>{
        var get_month = e.value; 
        setSearchMonth(get_month);
        SearchYearVal = SearchYear;
        SearchMonthVal = get_month;
        handleSearchLottery();
    }

    const handleSearchLottery = () => {
        $('.SSRM-rightShowLottoryResults').fadeOut(400);
        $('.SSRM-loading').fadeIn(400).css('display','flex');
        axios({
            url:apiUrl + `/lottery?year=${SearchYearVal}&month=${SearchMonthVal}`,
            method:'get',
        }).then((res) =>{

            // console.log(res.data.data)
            setLotterySearch(res.data.data);
            $('.SSRM-rightShowLottoryResults').fadeIn(400);
            if(LotteryIdParams){
                handleSelectResultLottery(false,LotteryIdParams,res.data.data);
            }else{
                $('.SSRM-loading').fadeOut(400);
            }

            
        }).catch((error) => {
            $('.SSRM-loading').fadeOut(400); 
            toast.error(error)
        });
    }

    const compareTableLottoByNum = ( a, b ) => {
        if ( a.number < b.number ){
          return -1;
        }
        else if ( a.number > b.number ){
          return 1;
        }
        else{
            if ( a.joker < b.joker ){
                return -1;
            }
              else if ( a.joker > b.joker ){
                return 1;
            }
            else{
                return 0;
            }
        }
    }

    const handleSelectResultLottery = (e,lotteryId,helperSearch=[]) =>{
        


        var selectLotto = '';

        if(e){
            selectLotto = e.target;
    
            if(selectLotto.classList.contains('SSRM-RSLR-card')){}
            else{
                selectLotto = selectLotto.parentElement;
            }
    
            if(selectLotto.classList.contains('active')){
    
            }else{
                var searchParams = new URLSearchParams(window.location.search);
                searchParams.set("Lottery", lotteryId);
                var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
                window.history.pushState({path:newurl},'',newurl);
    
                $('.SSRM-RSLR-card').removeClass('active');
                selectLotto.classList.add('active');
                // selectLotto.addClass('active')
                $('.SSRM-loading').fadeIn(400).css('display','flex');
                axios({
                    url:apiUrl + `/prize`,
                    method:'post',
                    data:{lottery_id:lotteryId}
                }).then((res) =>{
                    $('.SSRM-loading').fadeOut(400);
                    console.log(res.data.data)
                    var resultData = res.data.data;
                    var totalWin = 0;
                    var totalPerson = 0;
    
                    for (let i = 0; i < resultData.length; i++) {
                        const element = resultData[i];
    
                        if(element.prizes == null || element.prizes.length == 0){}
                        else{
                            totalWin = totalWin + element.prizes.amount;
                            totalPerson = totalPerson + element.prizes.count;
                        }
                    }
    
                    for (let i = 0; i < LotterySearch.length; i++) {
                        const lotto = LotterySearch[i];
                        if(lotto._id == lotteryId){
                            setLotterSelectNo({No5:lotto.numbers,No2:lotto.jokers});
                        }
                    }
    
                    // $('.searchShowResultMain').scrollTop();
    
                    setTotalWinner(totalPerson);
                    setTotalPrize(totalWin);
                    setLotterySelectResult(res.data.data.sort(compareTableLottoByNum).reverse());
    
                    $('html, body').animate({scrollTop:$('.SSRM-RRSN-ticketMain').offset().top - 50}, 'slow');
        
                    
                }).catch((error) => {
                    $('.SSRM-loading').fadeOut(400);
                    toast.error(error)
                });
            }
        }else{
            axios({
                url:apiUrl + `/prize`,
                method:'post',
                data:{lottery_id:lotteryId}
            }).then((res) =>{
                $('.SSRM-loading').fadeOut(400);
                console.log(res.data.data)
                var resultData = res.data.data;
                var totalWin = 0;
                var totalPerson = 0;

                for (let i = 0; i < resultData.length; i++) {
                    const element = resultData[i];

                    if(element.prizes == null || element.prizes.length == 0){}
                    else{
                        totalWin = totalWin + element.prizes.amount;
                        totalPerson = totalPerson + element.prizes.count;
                    }
                }

                for (let i = 0; i < helperSearch.length; i++) {
                    const lotto = helperSearch[i];
                    // console.log(lotto._id == lotteryId);
                    if(lotto._id == lotteryId){
                        setLotterSelectNo({No5:lotto.numbers,No2:lotto.jokers});
                    }
                }

                // $('.searchShowResultMain').scrollTop();

                setTotalWinner(totalPerson);
                setTotalPrize(totalWin);
                setLotterySelectResult(res.data.data.sort(compareTableLottoByNum).reverse());

                $('html, body').animate({scrollTop:$('.SSRM-RRSN-ticketMain').offset().top - 50}, 'slow');
    
                
            }).catch((error) => {
                $('.SSRM-loading').fadeOut(400);
                toast.error(error)
            });
        }

    }

    useEffect(()=>{
        
        let getYearNow = date.getFullYear();
        setSearchYear(SelectOptions[getYearNow - 2021].value);
        setSearchMonth(monthOption[date.getMonth()].value);

        changeMonthFunc(monthOption[date.getMonth()]);


    },[])


    return ( 
        <>
            <Helmet>
                <title>All results</title>
            </Helmet>
            <div className="withdrawalTexture">
                <img src={`${handleShowImage('withrawalTexture')}`} alt="" />
            </div>

            <div className="searchLottoMainBox">
                <div className="SLMB-image">
                    <img src={`${handleShowImage('winnerImage')}`} alt="" />

                    <div className="SLMB-title">
                        WINNERS
                    </div>
                </div>

                <div className="searchShowResultMain">
                    <div className="SSRM-left">

                        <div className="SSRM-RRSN-ticket">
                            <img className='SSRM-RRSN-ticketImage' src={`${handleShowImage('resultTicket')}`} alt="" />
                            <div className="SSRM-RRSN-ticketMain">
                                <div className="SSRM-RRSN-ticket5No">

                                    {LotterSelectNo.No5.map((Number,i) =>
                                        <div className="SSRM-RRSN-ticket5NoCard">{Number}</div>
                                    )}
                                  
                                </div>
                                <div className="SSRM-RRSN-ticket2No">
                                    {LotterSelectNo.No2.map((Number,i) =>
                                        <div className="SSRM-RRSN-ticket2NoCard">
                                            <img src="/assets/image/polygonStar3.png" alt="" />
                                            <div className="SSRM-RRSN-ticket2NoCardNo">{Number}</div>
                                        </div> 
                                    )}
                                </div>
                            </div>
                        </div>

                        <table className="secondSiteTable">
                            <tr>
                                <th>Numbers Matched</th>
                                <th>winners</th>
                                <th>Prize Per Winner</th>
                                <th>Total</th>
                            </tr>

                            {LotterySelectResult.map((result,index)=>
                            
                                <tr className="homeTableTr searchTableTr">
                                    <td>
                                        <div className="winTempTable">
                                            <div className="WTT-No">{result.number}</div>
                                            {result.joker > 0 ? 
                                                <>

                                                    <div className="WTT-icon">+</div>

                                                    {Array.from(Array(result.joker), (e, i) => {
                                                        return (
                                                            <div className="WTT-joker">
                                                                <img src="/assets/image/PolygonStar1.png" alt="" />
                                                            </div>
                                                        )
                                                    })}

                                                </>
                                            
                                            :null}
                                        </div>
                                    </td>
                                    <td>
                                        <img src="/assets/image/userRedIcon.svg" alt="" />
                                        <span>
                                            {result.prizes == null || result.prizes.length == 0 ? 0 : result.prizes.count}
                                        </span>
                                    </td>
                                    <td>
                                        {/* <img src="/assets/image/redDollar.svg" alt="" /> */}
                                        <span>
                                            {result.prizes == null || result.prizes.length == 0 ? 0 : (result.prizes.amount/result.prizes.count)?.toFixed(2)}
                                        </span>
                                        <span className="tableUnit">ETH</span>
                                    </td>
                                    <td>
                                        {/* <img src="/assets/image/redDollar.svg" alt="" /> */}
                                        <span>
                                            {result.prizes == null || result.prizes.length == 0 ? 0 : result.prizes.amount?.toFixed(2)}
                                        </span>
                                        <span className="tableUnit">ETH</span>
                                    </td>
                                    
                                </tr>
                            )}


                            
                        </table>

                                
                        <div className="SSRM-leftTotalPrize">
                            <div className="totalWithdra">
                                <div className="WB-title"> Total Winner </div>
                                <div className="WB-totalVal">
                                    <div>{TotalWinner}</div>
                                </div>
                            </div>
                            <div className="totalTransaction">
                                <div className="WB-title"> Total Prize </div>
                                <div className="WB-totalVal">
                                    <div>{TotalPrize?.toFixed(2)}</div>
                                    <div className="WB-totalValUnit">ETH</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="SSRM-right">
                        <div className="SSRM-rightDateBox">
                            <div className="SSRM-rightDateTitle">Choose Lottery Date</div>
                            <div className="SSRM-rightDateText">
                                {handleShowText("winners_text")}
                            </div>

                            <div className="SSRM-rightDateSelect">

                                <div className="SSRM-rightDateYear">
                                    <Select 
                                        styles={customStyles}
                                        options={SelectOptions} 
                                        onChange={(e) => changeYearFunc(e)}
                                        defaultValue={defaultOption} 
                                        placeholder="Select an option" 
                                    />
                                </div>
                                
                                <div className="SSRM-rightDateMon">
                                    <Select 
                                        styles={customStyles1}
                                        options={monthOption} 
                                        onChange={(e) => changeMonthFunc(e)}
                                        defaultValue={defaultOption1} 
                                        placeholder="Select an option" 
                                    />
                                </div>
                                
                            </div>

                            <div className="customLoading SSRM-loading">
                                <span></span>
                                <span style={{"--delay": "0.1s"}}></span>
                                <span style={{"--delay": "0.2s"}}></span>
                                <span style={{"--delay": "0.3s"}}></span>
                                <span style={{"--delay": "0.4s"}}></span>
                            </div>
                            
                        </div>

                        <div className="SSRM-rightShowLottoryResults">

                            {LotterySearch.length > 0 ?
                                <>
                                    {LotterySearch.map((lottery,index) =>
                                        <div className={`SSRM-RSLR-card${lottery._id == LotteryIdParams ? " active" :""}`} key={lottery._id} onClick={(e) => handleSelectResultLottery(e,lottery._id)}>
                                            <div className="SSRM-RSLR-cardDate">
                                                {(() =>{
                                                    let endLottery = lottery.end_date;
                                        
                                                    var result = new Date(endLottery);
                                                    var newDate = moment(result).format("DD , MMMM , YYYY");

                                                    return newDate
                                                })()}
                                            </div>
                                            <div className="SSRM-RSLR-cardShow"> Draw Detail </div>
                                        </div>
                                    )}
                                </>
                            :
                                <>
                                    <div className="SSRM-RSLR-noResult">
                                    There is no lottery on your chosen date
                                    </div>    
                                </>
                            
                            }

                            
                            
                        </div>

                        

                    </div>
                    
                </div>

            </div>
            
        </>
     );
}
 
export default SearchLotteryPage;