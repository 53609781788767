import { combineReducers } from "redux";

import { textReducer } from "./text";
import { imageReducer } from "./image";
import { appReducer } from "./app";


export const reducers = combineReducers({
    app : appReducer,
    text:textReducer,
    image:imageReducer,
})
