import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";

import { apiUrl } from '../../utils/config';
import "react-datepicker/dist/react-datepicker.css";
import { checkUserExists, registerVerify } from "../../api/user";

const cookies = new Cookies();

const RegisterPage = () => {

    const [RegisterStep, setRegisterStep] = useState(1);

    const [startDate, setStartDate] = useState(new Date());

    const [ConfirmRules, setConfirmRules] = useState(false);

    const [UsernameError, setUsernameError] = useState(false);
    const [EmailError, setEmailError] = useState(false);
    const [PassValidate, setPassValidate] = useState({})

    const [Email, setEmail] = useState('');
    const [Pass, setPass] = useState('');
    const [RePass, setRePass] = useState('');
    const [Firstname, setFirstname] = useState('');
    const [Surname, setSurname] = useState('');
    const [Nickname, setNickname] = useState('');
    const [Brithday, setBrithday] = useState(new Date());
    const [Gender, setGender] = useState('male');
    const [Username, setUsername] = useState('');
    const [InvalidEmail, setInvalidEmail] = useState(true);

    const [_2fa, set_2fa] = useState(0);

    const [Postcode, setPostcode] = useState();
    const [UserLocation, setUserLocation] = useState('');
    const [Street, setStreet] = useState('');
    const [UserNumber, setUserNumber] = useState('');

    const firstnameInput = useRef(null);
    const emailInput = useRef(null);
    const passInput = useRef(null);
    const rePassInput = useRef(null);
    const surnameInput = useRef(null);
    const nicknameInput = useRef(null);
    const birthdayInput = useRef(null);
    const genderInput = useRef(null);
    const usernameInput = useRef(null);

    let setToCorrectTime = 0;

    const handlShowPassIn = (refIn) => {
        if (refIn.current.type == 'password') {
            refIn.current.type = 'text'
        } else {
            refIn.current.type = 'password'
        }
    }



    const handleGotoNextStep = () => {
        let step = RegisterStep;
        step++;
        setRegisterStep(step)
    }

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleCheckUsernameOrEmail = (e, checkType) => {
        let reqData = {};
        var user = false;
        var newChar = '';
        if (checkType == 'email') {
            if (validateEmail(e.target.value)) {
                if (e.target.value.split('.')[1].length > 1) {
                    newChar = e.target.value;
                    setEmail(newChar);
                    console.log(validateEmail(newChar));
                    reqData = { email: newChar }
                }
            }
        } else {
            newChar = e.target.value;
            setUsername(newChar);
            // console.log(e.target.value)
            reqData = { username: newChar }
            user = true;
        }
        // console.log(reqData)
        if (!_.isEmpty(newChar)) {

            checkUserExists(reqData).then((res) => {
                if (user) {
                    setUsernameError(false);
                } else {
                    setEmailError(false);
                    setInvalidEmail(false);
                }
                console.log(res);
            }).catch((err) => {
                if (user) {
                    setUsernameError(true);
                } else {
                    if (err.response.data == '"email" must be a valid email') {
                        setInvalidEmail(true)
                    } else {
                        setEmailError(true);
                    }
                }
                console.log(err.response);
            })
        }
    }

    const handleCheckPass = () => {
        // var validation = passwordStrength(Pass);
        // console.log(validation)
        setPassValidate({id:2 , value : "strong"});
        // console.log(PassValidate.id)
    }

    const handleAuthReq = () => {

        // console.log(`${Brithday.getMonth()}/${Brithday.getDate()}/${Brithday.getFullYear()}`)

        let arrCheckVal = [Email, Pass, RePass, Firstname, Surname, Nickname, Brithday, Gender, Username];

        if (_.isEmpty(Firstname)) {
            toast.error("please complete empty inputs")
            firstnameInput.current.style.border = '1px solid red'
        }
        else if (_.isEmpty(Email)) {
            toast.error("please complete empty inputs")
            emailInput.current.style.border = '1px solid red'
        }
        else if (InvalidEmail) {
            toast.error("please write valid Email Address")
            emailInput.current.style.border = '1px solid red'
        }
        else if (_.isEmpty(Surname)) {
            toast.error("please complete empty inputs")
            surnameInput.current.style.border = '1px solid red'
        }
        else if (_.isEmpty(Nickname)) {
            toast.error("please complete empty inputs")
            nicknameInput.current.style.border = '1px solid red'
        }
        else if (Brithday === startDate) {
            toast.error("please select birthday correctly")
            // birthdayInput.current.style.border='1px solid red'
        }
        else if (_.isEmpty(Gender)) {
            toast.error("please complete empty inputs")
            genderInput.current.style.border = '1px solid red'
        }
        else if (_.isEmpty(Username)) {
            toast.error("please complete empty inputs")
            usernameInput.current.style.border = '1px solid red'
        }
        else if (_.isEmpty(Pass)) {
            toast.error("please complete empty inputs")
            passInput.current.style.border = '1px solid red'
        }
        else if (_.isEmpty(RePass)) {
            toast.error("please complete empty inputs")
            rePassInput.current.style.border = '1px solid red'
        }
        else if (Pass !== RePass) {
            toast.error("your password and repeat password not match")
        }
        // if(_.isEmpty(Email) ||  || _.isEmpty(RePass) || _.isEmpty(Firstname) || _.isEmpty(Surname) || _.isEmpty(Nickname) || _.isEmpty(Brithday) || _.isEmpty(Gender) || _.isEmpty(Username) ){
        //     console.log(this)
        // toast.error("please complete all inputs" )
        // }
        else if (PassValidate.id < 1) {
            toast.error(`your password must be Better`)
        }
        else if (!ConfirmRules) {
            toast.error("please confirm rules")
        }
        else if (EmailError) {
            toast.error("Email already exist!")
        }
        else if (UsernameError) {
            toast.error("username already exist!")
        }
        else {
            setToCorrectTime = `${Brithday.getMonth()}/${Brithday.getDate()}/${Brithday.getFullYear()}`;

            // setBrithday(setToCorrectTime);
            handleGotoNextStep();
        }
    }

    var historyGo = useHistory();
    const handleVerficationEmail = () => {

        if (_.isEmpty(_2fa)) {
            toast.error("please enter Verification Code")
        } else {
            registerVerify(_2fa).then((res) => {
                console.log(res);
                toast.success('Email Verify Successfully!')
                document.cookie = `token=${res.headers['x-auth-token']}`;

                handleGotoNextStep();
                window.location.href = "/";
            })
        }
    }

    const handleStep3 = () => {
        if (_.isEmpty(Postcode) || _.isEmpty(UserLocation) || _.isEmpty(Street) || _.isEmpty(UserNumber)) {
            toast.error("please complete all inputs")
        } else {

            var data = {
                'email': Email.toString().toLowerCase(),
                'password': Pass,
                'firstname': Firstname,
                'surname': Surname,
                'nickname': Nickname,
                'birthdate': Brithday,
                'salutation': Gender,
                'username': Username,
                'street': Street,
                'number': UserNumber,
                'post_code': Postcode,
                'location': UserLocation
            };

            var configReq = {
                method: 'post',
                url: apiUrl + '/client',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(configReq)
                .then((response) => {
                    console.log(response);
                    toast.success(`${response.data.message}`)
                    handleGotoNextStep();
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    const loginWithCounos = () => {

        const randNumber = Math.floor(Math.random() * 100000) + 10000;
        cookies.set('randomNumber', randNumber);

        axios({
            url: apiUrl + '/auth/conous/' + randNumber,
            method: 'get',
        }).then((res) => {
            window.location.href = res.data;
        }).catch((error) => {
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            } else {
                toast.error(`${error.response.data.message}`)
            }
        })
    }


    const loginWithGoogle = () => {

        const randNumber = Math.floor(Math.random() * 100000) + 10000;
        cookies.set('randomNumber', randNumber);

        axios({
            url: apiUrl + '/auth/google/',
            method: 'get',
        }).then((res) => {
            window.location.href = res.data;
        }).catch((error) => {
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            } else {
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    useEffect(() => { handleCheckPass() }, [Pass])



    return (
        <>

            <Helmet>
                <title>Sign Up</title>
            </Helmet>

            <div className="registerStepMainBox">
                <div className={`registerStepCard ${RegisterStep > 1 ? "filled" : null} ${RegisterStep == 1 ? "active" : null}`}>1</div>
                <div className="registerStepline"></div>
                <div className={`registerStepCard ${RegisterStep > 2 ? "filled" : null} ${RegisterStep == 2 ? "active" : null}`}>2</div>
                <div className="registerStepline"></div>
                <div className={`registerStepCard ${RegisterStep > 3 ? "filled" : null} ${RegisterStep == 3 ? "active" : null}`}>3</div>
            </div>

            <div className="authTitleAndDesc">
                <div className="authTitle">SIGN UP</div>
                <div className={`authDesc ${RegisterStep == 2 ? "d-none" : null}`}> open your personal gaming account with just a few detail . We are  legally obliged to check your identity and place of residence. </div>
            </div>


            <div className={`"authMainBox registerMainBox registerBoxStep1" ${RegisterStep == 1 ? "" : "d-none"}`} >

                <div className="connectWithAnotherWay CWAW-register">

                    <div className="CWAW-body">
                        <div className="btnTemp LoginWithOther" onClick={loginWithGoogle}>
                            <img src="/assets/image/googleLogo.png" alt="" />
                            <div>Sign up with Google</div>
                        </div>
                        <div className="btnTemp LoginWithOther" onClick={loginWithCounos}>
                            <img src="/assets/image/counos.png" alt="" />
                            <div>Sign up with Counos</div>
                        </div>
                    </div>
                </div>
                <div className="authInputBox registerInputBox">
                    <div className="registerInputTitle">First Name</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Enter Your First Name"
                        onChange={e => setFirstname(e.target.value)}
                        ref={firstnameInput}
                    />
                </div>

                <div className="authInputBox registerInputBox">
                    <div className="registerInputTitle">
                        <div>Email Address</div>
                        {EmailError ?

                            <div className="inputError">
                                This email has already been selected
                            </div>
                            : null}
                    </div>
                    <input type="email" id="authEmail" className="inputSite" placeholder="Enter Your Email"
                        onChange={e => { handleCheckUsernameOrEmail(e, 'email') }}
                        ref={emailInput}
                        name='username'
                    />
                </div>

                <div className="authInputBox registerInputBox">
                    <div className="registerInputTitle">Surname</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Enter Your Surname"
                        onChange={e => setSurname(e.target.value)}
                        ref={surnameInput}
                    />
                </div>

                <div className="authInputBox registerInputBox">
                    <div className="registerInputTitle">Nick Name</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Enter Your Nick Name"
                        onChange={e => setNickname(e.target.value)}
                        ref={nicknameInput}
                    />
                </div>

                <div className="authInputBox registerInputBox">
                    <div className="registerInputTitle">Gender</div>
                    <select className="selectTemp" onChange={e => setGender(e.target.value)}
                        ref={genderInput}
                    >
                        <option value="male">male</option>
                        <option value="female">female</option>
                        <option value="other">other</option>
                    </select>
                    {/* <input type="text" id="authEmail" className="inputSite" placeholder="Enter Your Nick Name"
                            onChange={e => setGender(e.target.value)}
                        /> */}
                </div>

                <div className="authInputBox registerInputBox">
                    <div className="registerInputTitle">Birth Date</div>

                    {/* onChange={e => setBrithday(e.target.value)} */}

                    <DatePicker
                        selected={Brithday}
                        onChange={(date) => setBrithday(date)}
                        ref={birthdayInput}
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={80}
                        scrollableYearDropdown
                        customInput={
                            <input type="text" id="authEmail" className="inputSite" placeholder="Enter Your Birth Date"

                            />
                        }
                    />
                </div>

                <hr className="registerHr" />


                <div className="registerBox2">
                    <div className="setRegisterUser">
                        <div className="authInputBox registerInputBox">

                            <div className="registerInputTitle">
                                <div>Username</div>
                                {UsernameError ?

                                    <div className="inputError">
                                        This username has already been selected
                                    </div>
                                    : null}
                            </div>
                            <input type="text" className="inputSite" placeholder="Enter Your Username"
                                onChange={e => { handleCheckUsernameOrEmail(e, 'username') }}
                                ref={usernameInput}
                            />
                        </div>

                        <div className="passwordRules">
                            <div className="passwordRulesTitle">Password Rules</div>
                            <div>use numeric character</div>
                            <div>use uppercase alphabetical character</div>
                            <div>use lowercase alphabetical character</div>
                            <div>use special character ([!@#$%^&]))</div>
                        </div>
                    </div>
                    <div className="setregisterPass">
                        <div className="authInputBox registerInputBox">
                            <div className="registerInputTitle">
                                <div>Password</div>
                                {PassValidate.id <= '2' ?
                                    <>


                                        <div className="inputError">
                                            {PassValidate.value}
                                        </div>

                                    </>

                                    : <><div className="inputSuccess">{PassValidate.value}</div></>}
                            </div>
                            <div className="passInWithShowPass">
                                <div className="showPassIcon" onClick={() => handlShowPassIn(passInput)}>
                                    <img src="/assets/image/eye.svg" alt="" />
                                </div>
                                <input type="password" className="inputSite" placeholder="Password"
                                    onChange={e => setPass(e.target.value)}
                                    ref={passInput}

                                />
                            </div>
                            {/* <div className="showPasswordIcon">
                                    <img src="/assets/image/" alt="" />
                                </div> */}
                        </div>
                        <div className="authInputBox registerInputBox">
                            <div className="registerInputTitle">
                                <div>Repeat Password</div>
                                <div className="inputError">

                                </div>
                            </div>
                            <div className="passInWithShowPass">
                                <div className="showPassIcon" onClick={() => handlShowPassIn(rePassInput)}>
                                    <img src="/assets/image/eye.svg" alt="" />
                                </div>
                                <input type="password" className="inputSite" placeholder="repeat your Password"
                                    onChange={e => setRePass(e.target.value)}
                                    ref={rePassInput}

                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="registerRulesMain">
                    <div className="registerRulesCard">
                        <input type="checkbox" id="" onChange={e => setConfirmRules(e.target.checked)} />
                        <div>I confirm that I have read the conditions of participation and accept them.</div>
                        {/* <label class="TPB-jackpotSubTitleLabel">
                                I confirm that I have read the conditions of participation and accept them.
                                    <input type="checkbox" id="TPB-jackpotSubInput" 
                                        onChange={e => setConfirmRules(e.target.checked)}
                                    />
                                    <span class="checkmark"></span>
                            </label> */}
                    </div>
                    {/* <div className="registerRulesCard">
                            <input type="checkbox" id="" />
                            <div>Please send me information from Lotto Xbit.</div>
                        </div> */}
                </div>

                <div className="btnTemp1 customRegisterbtn" onClick={handleAuthReq}>
                    CONTINUE
                    <img src="/assets/image/arrow4.svg" alt="" />
                </div>

                {/* <div className="btnTemp LoginWithOther registerWithOther" onClick={loginWithGoogle}>
                        <img src="/assets/image/googleLogo.png" alt="" />
                        <div>Register with Google</div>
                    </div>

                    <div className="btnTemp LoginWithOther registerWithOther" onClick={loginWithCounos}>
                        <img src="/assets/image/counos.png" alt="" />
                        <div>Register with Counos</div>
                    </div> */}
            </div>

            <div className={`redisterBoxStep2 ${RegisterStep >= 3 ? "" : 'd-none'}`}>
                <div className="RBS2-text">
                    We send verification code to your email
                </div>
                <div className="RBS2-email">{Email}</div>

                <div className="authInputBox registerInputBox w-100">
                    {/* <div className="registerInputTitle">First Name</div> */}
                    <input type="text" id="authEmail" className="inputSite" placeholder="Verfication Code"
                        onChange={e => set_2fa(e.target.value)}
                    />
                </div>


                <div className="btnTemp1 customRegisterbtn" onClick={handleVerficationEmail}>
                    SUBMIT
                </div>



            </div>

            <div className={`redisterBoxStep3 ${RegisterStep == 2 ? "" : 'd-none'}`}>

                <div className="authInputBox registerInputBox w-100">
                    <div className="registerInputTitle">Post Code</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Your Post Code"
                        onChange={e => setPostcode(e.target.value)}
                    />
                </div>
                <div className="authInputBox registerInputBox w-100">
                    <div className="registerInputTitle">Location</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Your Location"
                        onChange={e => setUserLocation(e.target.value)}
                    />
                </div>
                <div className="authInputBox registerInputBox w-100">
                    <div className="registerInputTitle">Street</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Your Street"
                        onChange={e => setStreet(e.target.value)}
                    />
                </div>
                <div className="authInputBox registerInputBox w-100">
                    <div className="registerInputTitle">Number</div>
                    <input type="text" id="authEmail" className="inputSite" placeholder="Your Number"
                        onChange={e => setUserNumber(e.target.value)}
                    />
                </div>


                <div className="btnTemp1 customRegisterbtn" onClick={handleStep3}>
                    SUBMIT
                </div>



            </div>


        </>
    )
}

export default RegisterPage;
