import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import CustomModal from "../customModal";
// import { formatAddress } from "../helpers";
import { connectorsByName, getTrustWalletInjectedProvider, Metamask, WalletConnect } from "./connectors";
import { ConnectWalletCont } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { activeAndSetWallet, disconnectWallet, updateWalletBalance } from "../../actions/app";
import { updateActiveBalance } from "../../utils/contractWeb3";
import { toast } from "react-toastify";
import { siteAddress } from "../../utils/config";

const ConnectWallet = ({ showConnectModal, changeConnectModalData, showDisconnectModal, changeDisconnectModalData }) => {
    const userWallet = useSelector(store => store.app.wallet);

    const dispatch = useDispatch();

    const [ShowConnectWalletModal, setShowConnectWalletModal] = useState({
        show: false,
    })

    // const [showWalletOptions, setShowWalletOptions] = useState(false);
    // const [showDisconnectWalllet, setShowDisconnectWallet] = useState(false);

    const { account, activate, deactivate, connector, getAccount } = useWeb3React();

    useEffect(() => {
        /*
        Reconnect to metamask wallet after refresh if aready connected
        */
        const walletConnectStatus =
            localStorage.getItem("walletConnect") || "";

        try {
            if (walletConnectStatus === "true")
                handleConnectWallet();
        } catch (error) {
            console.log(error);
        }
    }, []); //eslint-disable-line

    // Function to check if Trust Wallet is installed
    const isTrustWalletInstalled = () => {
        // Check if window.Web3 object is available
        if (typeof window.Web3 !== 'undefined' && typeof window.Web3.currentProvider !== 'undefined') {
            // Check if Web3 provider is Trust Wallet
            console.log(window.Web3.currentProvider)
            if (window.Web3.currentProvider.isTrust) {
                return true;
            }
        }
        return false;
    };

    const handleConnectWallet = async () => {
        const { ethereum } = window;
        

        if(window.innerWidth < 999 && !ethereum){
            window.open(`https://metamask.app.link/dapp/${siteAddress}` , "_blank")
        }else{

            if (!ethereum) {
                toast.info(
                    "Please install Metamask!"
                )
                console.log("Please install metamask!");
                return;
            }

            try {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                const networkId = await ethereum.request({ method: 'net_version' });
    
                // console.log({networkId})
    
                // Check if the connected chain is not "Sepolia" (replace 'SepoliaNetworkId' with the actual network ID)
                if (networkId === "11155111") {
                    handleCloseModal();
                    dispatch(activeAndSetWallet(accounts[0]));
                    localStorage.setItem("walletConnect", "true");
                } else {
                    console.log("Please switch to a different network.");
                    toast.error("Please switch to Sepolia");
                    // Perform any action you want when connected to "Sepolia"
                    // Example: Display a message or prevent further actions
                }
            } catch (err) {
                console.log("Connect Wallet Error: ", err);
                localStorage.setItem("walletConnect", "false");
            }
        }


        // try {
        //     const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        //     dispatch(activeAndSetWallet(accounts[0]));
        //     localStorage.setItem("walletConnect", "true");
        // } catch (err) {
        //     console.log("Connect Wallet Error : ", err);
        //     localStorage.setItem("walletConnect", "false");
        // }


    }

    function openTrustWallet() {
        // Check if the user is on a mobile device
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // If the user is on iOS, use the App Store link
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                window.location.href = 'https://apps.apple.com/us/app/trust-ethereum-wallet/id1288339409';
            }
            // If the user is on Android, use the Play Store link
            else if (/Android/i.test(navigator.userAgent)) {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp';
            }
        } else {
            // Provide a message for users on non-mobile devices
            alert('Trust Wallet can only be opened on mobile devices.');
        }
    }

    const handleConnectWalletByTrustWallet = async () => {
        const { ethereum } = window;

        // const injectedProvider = await getTrustWalletInjectedProvider();

        // alert(JSON.stringify(injectedProvider))
        try {
            const desiredChainId = 11155111; // Example: Mainnet chain ID
            if(window.innerWidth < 999 && !ethereum){
                // openTrustWallet();
                // window.ethereum.isTrust
                window.open(`https://link.trustwallet.com/open_url?url=${siteAddress}`, '_blank');
                // await activate(WalletConnect);

            }else if (isTrustWalletInstalled()) {
                if (WalletConnect.defaultChainId === desiredChainId) {
                    // Activate WalletConnect
                    await activate(WalletConnect);
                    const { account } = await getAccount();

                    dispatch(activeAndSetWallet(account[0]));
                    localStorage.setItem("walletConnect", "true");
                    handleCloseModal();
                } else {
                    console.error("Desired chain ID does not match the default chain ID.");
                    // Handle the error or prompt the user to switch networks
                }

            } else {
                toast.info(
                    "Please install Trust Wallet!"
                )
            }
        } catch (error) {
            console.error("Error connecting with WalletConnect:", error);
        }
    }

    const handleDisconnectWallet = () => {
        localStorage.setItem("walletConnect", "false");
        dispatch(disconnectWallet());
        deactivate();
    }

    const handleCloseModal = () => {
        setShowConnectWalletModal({
            show: false
        })
    }

    useEffect(() => {
        if (account !== undefined) {
            dispatch(activeAndSetWallet(account));
            updateActiveBalance();
        }
    }, [account, dispatch])

    return (
        <>

            <CustomModal
                show={ShowConnectWalletModal.show}
                toggleModal={handleCloseModal}
            >

                <div className="connectWalletModalTitle">
                    Connect Wallet
                </div>

                <div className="connectWalletModalBody">
                    <div className="connectWalletModalCard" onClick={handleConnectWallet}>
                        <div className="connectWalletModalCardImage">
                            <img src="/assets/image/metamask.png" alt="" />
                        </div>
                        <div className="connectWalletModalCardTitle">
                            Metamask
                        </div>
                    </div>
                    <div className="connectWalletModalCard" onClick={handleConnectWalletByTrustWallet}>
                        <div className="connectWalletModalCardImage">
                            <img src="/assets/image/trustWallet.svg" alt="" />
                        </div>
                        <div className="connectWalletModalCardTitle">
                            Trust Wallet
                        </div>
                    </div>
                </div>

            </CustomModal>

            {userWallet.active ?

                <div className="profile">
                    <div className="profileBox">

                        <div className="user-data">
                            <span className="wallet">
                                <span>{`${userWallet.address.substring(0, 5)}....${userWallet.address.substring(userWallet.address.length - 5, userWallet.address.length)}`}</span>
                            </span>
                        </div>

                        <div className="logoutIconHeader" onClick={handleDisconnectWallet}>
                            <img src="/assets/image/logout.svg" alt="" />
                        </div>
                    </div>

                </div>


                :
                <div className="authInHeaderLink">
                    <div
                        className="btnTemp1"
                        // onClick={handleConnectWallet}
                        onClick={() => setShowConnectWalletModal({ ...ShowConnectWalletModal, show: true })}
                    >
                        Connect Wallet
                    </div>
                </div>
            }
        </>
    );
};

export default ConnectWallet;
