// import ClientOAuth2 from "client-oauth2";
import Cookies from "universal-cookie";

const cookies = new Cookies();


const randNumber = cookies.get('randomNumber');

// const githubAuth = new ClientOAuth2({
//     clientId: 'lotto',
//     clientSecret: '71cfffe6-d2f1-46fd-80b1-8ec768972eda',
//     accessTokenUri: 'https://counos.com/connect/token',
//     authorizationUri: 'https://counos.com/connect/authorize',
//     redirectUri: 'https://xbitlotto.com/loginWithCouos',
//     scopes: ['openid', 'profile', 'email'],
//     state:randNumber
// });

const githubAuth = () =>{}

export default githubAuth

