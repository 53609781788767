/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import $ from 'jquery';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import Cookies from "universal-cookie";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Dashboard from './views/dashboard';
import BuyTicket from './views/ticket/buyTicket';
import MyTicket from './views/ticket/myTicket';
import Wallet from './views/wallet';
import LoginPage from "./views/auth/login";
import RegisterPage from "./views/auth/register";

import SettingsPage from './views/settings';
import SupportPage from './views/pages/support';
import RulePage from './views/pages/rule';
import AboutUsPage from './views/pages/aboutUs';
import PrivacyAndPolicyPage from './views/pages/privacyAndPolicy';


import ProtectedRoutes from "./ProtectedRoutes";
import ForgetPasswordPage from './views/auth/forgetPassword';
import DocumentPage from './views/pages/document';
import LoginWithOtherWayPage from './views/auth/loginWithOtherWay';

import { apiUrl } from "./utils/config";
import 'react-toastify/dist/ReactToastify.min.css';
import WithdrawalPage from './views/withdrawal';
import SearchLotteryPage from './views/lottery/searchLottery';
import { useSelector } from 'react-redux';
import ReceiptPage from './views/ticket/receipt';
import LoginWithGoogleWayPage from './views/auth/loginWithGoogle';
import CrashGamePage from './views/game/crash';
import CookiePolicyPage from './views/pages/cookiePolicy';
import HeaderSite from './components/header';
import WhitePaperPage from './views/whitePaper';
import LotteryPlayersPage from './views/lotteryPlayers';



function App() {

  const cookies = new Cookies();


  const [UserInfo, setUserInfo] = useState({});
  const [UserSymbol, setUserSymbol] = useState('');
  const [UserWalletVal, setUserWalletVal] = useState(0);

  const sideNavBox = useRef(null);
  const modalTemp = useRef(null);
  const headerBox = useRef(null);
  const MainContainerBox = useRef(null);
  const footerBox = useRef(null);


  let token = "";
  const location = useLocation();
  let history = useHistory();


  const AllTextSite = useSelector(state => state.text);
  const AllImageSite = useSelector(state => state.image);

  const handleShowText = (keyText) => {

    for (let i = 0; i < AllTextSite.length; i++) {
      const textObj = AllTextSite[i];

      if (textObj.key === keyText) {

        return textObj.value
      }

    }
    return null

  }


  const handleShowImage = (keyImage) => {
    for (let i = 0; i < AllImageSite.length; i++) {
      const imageObj = AllImageSite[i];

      if (imageObj.key === keyImage) {

        return imageObj.value
      }

    }
    return null
  }



  const handleOpenSideNav = (index = -1) => {

    if (window.innerWidth < 480) {
      sideNavBox.current.style.width = '300px';
    } else {
      sideNavBox.current.style.width = '350px';
    }
    console.log("object");
    headerBox.current.style.filter = 'blur(5px)';
    MainContainerBox.current.style.filter = 'blur(5px)';
    footerBox.current.style.filter = 'blur(5px)';
    // sideNavBox.current.style.visibility = 'visible';

  }

  const handleCloseSideNav = () => {
    // sideNavBox.current.style.width = 0;
    // headerBox.current.style.filter = 'unset';
    // MainContainerBox.current.style.filter = 'unset';
    // footerBox.current.style.filter = 'unset';
    // sideNavBox.current.style.visibility = 'hidden';
  }

  const handleOpenLogout = () => {
    modalTemp.current.style.opacity = 1;
    modalTemp.current.style.visibility = 'visible';
    if (window.innerWidth < 1000) {
      handleCloseSideNav();
    }

  }

  const handleCloseLogout = () => {
    modalTemp.current.style.opacity = 0;
    modalTemp.current.style.visibility = 'hidden';

  }


  const handleCheckUser = (typeCall) => {
    const allCookie = document.cookie;
    // let token = "";
    if (allCookie.search("token") > -1) {
      token = document.cookie
        .split('; ')
        .find(row => row.startsWith('token='))
        .split('=')[1];
      return true;
    }
    if (typeCall !== "check") {
      history.push('/login')
    }
    return false;
  }

  Array.prototype.shuffle = function () {
    let currentIndex = this.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [this[currentIndex], this[randomIndex]] = [
        this[randomIndex], this[currentIndex]];
    }

    return this;
  }

  const getInfoUser = () => {
    axios({
      url: apiUrl + "/client",
      method: 'get',
      headers: {
        'x-auth-token': token,
      },
    }).then((response) => {
      var user = response.data.data;
      setUserInfo(user);
      setUserSymbol(user.firstname.charAt(0) + user.surname.charAt(0));
      setUserWalletVal(user.wallet.toFixed(2))
    }).catch((error) => {
      if (error.response.status === 403) {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = '/login'
      }
    });
  }

  const handleLogoutUser = () => {
    cookies.remove('token');
    history.push('/');
    handleCloseSideNav();
    toast.success("Logout Successfully");
    handleCloseLogout();
  }

  useEffect(() => {
    if (handleCheckUser('check')) {

      getInfoUser()
    }
    $(document).on('click', function (e) {
      if (!(($(e.target).closest("#mySidenav").length > 0) || ($(e.target).closest(".resMenubtn").length > 0))) {
        handleCloseSideNav()
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname === '/wallet' || location.pathname === '/myTicket' || location.pathname === '/crash') {
      if (handleCheckUser('check')) {
        getInfoUser();
      }
    }
    if (location.pathname !== '/buyTicket') {
      $('html, body').animate({
        scrollTop: 0
      }, 1000);
    }
  }, [location])

  return (
    <>
      {/* Header */}

      <HeaderSite />

      {/* Header (END) */}


      <div className="siteModalmain" ref={modalTemp}>
        <div className="siteModalBox logoutMainBox">
          <>
            {/* {handleDynamicModal} */}

            {/* <div className="modalClosebtn" onClick={handleCloseLogout}>
                            &#10006;
                        </div> */}

            <div className="modalImage">
              <div className="modalImageTitle">  Logout Confirm</div>
            </div>

            <div className='modalLogoutText'>  Are you sure ? </div>

            <div className="modalLogoutbtnMainBox">
              <div className='MLBMB-confirm' onClick={handleLogoutUser}>Yes</div>
              <div className='MLBMB-reject' onClick={handleCloseLogout}>No</div>
            </div>

          </>

        </div>
      </div>

      {/* Body */}

      <div className="container MainContainerBox" ref={MainContainerBox}>
        <div className={`main ${location.pathname === "/login" || location.pathname === "/register" ? "inOtherPage" : ""}`}>
          {/* <TransitionGroup>
          
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          
          <CSSTransition
            key={location.pathname}
            classNames="fade"
            timeout={500}
          > */}

          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/white_paper" component={WhitePaperPage} />
            <Route path="/lottery_players" component={LotteryPlayersPage} />
            <Route path="/support" component={SupportPage} />
            <Route path="/terms_of_use" component={RulePage} />
            <Route path="/Privacy_and_policy" component={PrivacyAndPolicyPage} />
            <Route path="/cookie_policy" component={CookiePolicyPage} />
            <Route path="/aboutUs" component={AboutUsPage} />
            <Route path="/Work_with_us" component={DocumentPage} />
            <Route path="/withdrawal" component={WithdrawalPage} />
            <Route path="/lottery_explore" component={SearchLotteryPage} />
            <Route path="/receipt" component={ReceiptPage} />
            <Route path="/buyTicket" component={BuyTicket} />

            <ProtectedRoutes path="/wallet" component={Wallet} />
            <ProtectedRoutes path="/settings" component={SettingsPage} />
            <ProtectedRoutes path="/myTicket" component={MyTicket} />
            <ProtectedRoutes path="/crash" component={CrashGamePage} />

            <Route path="/login" render={() => {
              if (!handleCheckUser('check')) {
                return <LoginPage />
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/" }} />
                );
              }
            }} />

            <Route path="/register" render={() => {
              if (!handleCheckUser('check')) {
                return <RegisterPage />
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/" }} />
                );
              }
            }} />

            <Route path="/forgetPassword" render={() => {
              if (!handleCheckUser('check')) {
                return <ForgetPasswordPage />
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/" }} />
                );
              }
            }} />

            <Route path="/loginWithCouos" render={() => {
              if (!handleCheckUser('check')) {
                return <LoginWithOtherWayPage />
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/" }} />
                );
              }
            }} />

            <Route path="/loginWithGoogle" render={() => {
              if (!handleCheckUser('check')) {
                return <LoginWithGoogleWayPage />
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/" }} />
                );
              }
            }} />

          </Switch>

          {/* </CSSTransition>
        </TransitionGroup>   */}
        </div>
      </div>

      {/* Body (END) */}


      {/* Footer */}

      <footer className="siteFooter" ref={footerBox}>
        <div className="footerBox1">
          <div className="footerLogo">
            <img src={`/assets/image/logo.svg`} alt="logo" />
            <div className="siteName">LOTTERY</div>
          </div>
          <div className="footerText">
            Lottery is an international and top grade lottery system. The lottery completely takes place on Blockchain. In this way, Lottery is set apart from the existing and traditional systems. Using Blockchain technology will guarantee a level of full transparency and security for everyone, such that no other lottery system can provide
          </div>
          <div className="footerMenu">

            <NavLink to="/support" className="footerMenuCard">
              <div className="footerMenuCard">
                Support
              </div>
            </NavLink>

            <NavLink to="/terms_of_use" className="footerMenuCard">
              <div className="footerMenuCard">
                Terms of use
              </div>

            </NavLink>

            <NavLink to="/Privacy_and_policy" className="footerMenuCard">
              <div className="footerMenuCard">
                Privacy Policy
              </div>

            </NavLink>

            <NavLink to="/cookie_policy" className="footerMenuCard">
              <div className="footerMenuCard">
                Cookie Policy
              </div>

            </NavLink>

            <NavLink to="/aboutUs" className="footerMenuCard">
              <div className="footerMenuCard">
                About Us
              </div>

            </NavLink>

            <NavLink to="/Work_with_us" className="footerMenuCard">
              <div className="footerMenuCard">
                Work with us
              </div>

            </NavLink>

          </div>
        </div>
        <div className="footerBox2">
          All Rights Reserved
        </div>
      </footer>



      {/* Footer (END) */}

      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
