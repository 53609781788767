import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {apiUrl} from '../../utils/config';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash"; 
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

const cookies = new Cookies();




const LoginPage = () => {
    const [Email,setEmail] = useState('');
    const [Pass,setPass] = useState('');
    const [_2fa,set_2fa] = useState('');


    const emailInput = useRef(null);
    const passInput = useRef(null);

    const modalTemp = useRef(null);


  let history = useHistory();

  const AllTextSite = useSelector(state => state.text);

  const handleShowText = (keyText) =>{

      for (let i = 0; i < AllTextSite.length; i++) {
          const textObj = AllTextSite[i];
                    
          if(textObj.key == keyText){
              
              return textObj.value
          }
          
      }
      return null
      
  }



  const handleEditWalletAddress = (index = -1) =>{
    modalTemp.current.style.opacity = 1;
    modalTemp.current.style.visibility = 'visible';

}

const handleCloseEditWallet = () =>{
    modalTemp.current.style.opacity = 0;
    modalTemp.current.style.visibility = 'hidden';

}

const handlShowPassIn = () =>{
    if(passInput.current.type == 'password'){
        passInput.current.type= 'text'
    }else{
        passInput.current.type= 'password'
    }
}


    const handleAuthReq = () =>{
        console.log(Email,Pass);

        if(_.isEmpty(Email)){
            toast.error("please complete empty inputs" )
            emailInput.current.style.border='1px solid red'
        }
        else if(_.isEmpty(Pass)){
            toast.error("please complete empty inputs" )
            passInput.current.style.border='1px solid red'
        }else{
            passInput.current.type= 'password';
            axios({
                url:apiUrl + '/auth/login',
                method:'post',
                headers:{
                    "Access-Control-Expose-Headers": "Access-Token"
                },
                data:{email : Email.toString().toLowerCase(),password : Pass}
            }).then((res) =>{
                toast.success(`${res.data.message}`);
                handleEditWalletAddress();
                // document.cookie = `token=${res.headers['x-auth-token']}`;
                // window.location.href = '/'
                // history.push('/')
    
                // res.json();
                // console.log(res);
                // console.log(res.headers);
            }).catch((error) => {
                if(error.response.status === 403){
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                }else{
                    toast.error(`${error.response.data}`)
                }
            })
        }

    }

    const handleVerficationEmail = () =>{

        if(_.isEmpty(_2fa)){
            toast.error('please enter Your 2FA code')
        }else{
            axios({
                url:apiUrl + '/auth/verify/' + _2fa,
                method:'get',
            }).then((res) =>{
                console.log(res);
                toast.success('Login Successfully!')
                document.cookie = `token=${res.headers['x-auth-token']}`;

                window.location.href = "/";
            }).catch((error) => {
                if(error.response.status === 403){
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                }else{
                    toast.error(`${error.response.data.message}`)
                }
            })
        }
    }

    const loginWithCounos = () =>{
        
        const randNumber = Math.floor(Math.random() * 100000) + 10000;
        cookies.set('randomNumber',randNumber);

        axios({
            url:apiUrl + '/auth/conous/'+randNumber,
            method:'get',
        }).then((res) =>{
            window.location.href = res.data;
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    const loginWithGoogle = () =>{
        
        const randNumber = Math.floor(Math.random() * 100000) + 10000;
        cookies.set('randomNumber',randNumber);

        axios({
            url:apiUrl + '/auth/google/',
            method:'get',
        }).then((res) =>{
            window.location.href = res.data;
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }



    return(
        <>

            <Helmet>
                <title>SIGN IN</title>
            </Helmet>
                   
            <div className="siteModalmain" ref={modalTemp}>
                <div className="siteModalBox">

                        <div className="modalClosebtn" onClick={handleCloseEditWallet}>
                            &#10006;
                        </div>

                        <div className="modalImage">
                            <div className="modalImageTitle"> 2FA Login </div>
                        </div>


                        <div className="authInputBox modalInputBox _2FAInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="text" id="authPass" className="inputSite" placeholder={`Your 2FA Login`}
                                onChange={e => set_2fa(e.target.value)}
                            />
                            <span>
                            <div class="tooltip1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                    </svg>
                                    <span class="tooltiptext1">
                                        {handleShowText("login_text")}
                                    </span>
                                </div>
                            </span>
                        </div>

                        <div className="btnTemp1 customRegisterbtn" onClick={() => handleVerficationEmail()}>
                            Submit
                            {/* <img src="/assets/image/arrow4.svg" alt="" /> */}
                        </div>
                


                </div>
            </div>

            <div className="authMainBox">

                <div className="authTitleAndDesc">
                    <div className="authTitle">SIGN IN</div>
                    <div className="authDesc"> the participle in the lottery , first log in to your account </div>
                </div>


                <div className="authInputBox">
                    {/* <label htmlFor="authEmail">Email</label> */}
                    <input type="email" id="authEmail" className="inputSite" placeholder="Email"
                    onChange={e => setEmail(e.target.value)}
                    ref={emailInput}
                    />
                </div>

                <div className="authInputBox">
                    {/* <label htmlFor="authPass">Password</label> */}
                    <div className="passInWithShowPass">
                        <div className="showPassIcon" onClick={handlShowPassIn}>
                            <img src="/assets/image/eye.svg" alt="" />
                        </div>

                        <input type="password" id="authPass" className="inputSite" placeholder="Password"
                    onChange={e => setPass(e.target.value)}
                    ref={passInput}

                        />
                    </div>
                </div>

                <div className="authBox2">
                    {/* <div className="authInputBox authRememberMe">
                        <input type="checkbox" id="authRemember" className="inputSite" placeholder="Password"
                    onChange={e => setPass(e.target.value)}
                        />
                        <label htmlFor="authRemember">Remember Me</label>
                    </div> */}
                    <NavLink className="authBox2Link" to="/forgetPassword">
                        Forget Password?
                    </NavLink>
                </div>

                <div className="btnTemp1 signInBtn" onClick={handleAuthReq}> LOGIN TO YOUR ACCOUNT </div>


            </div>

            <div className="connectWithAnotherWay">
                <div className="CWAW-header">

                    <hr />
                    <div className="CWAW-headerText">Or Connect With</div>
                    <hr />

                </div>
                <div className="CWAW-body">
                    <div className="btnTemp LoginWithOther" onClick={loginWithGoogle}>
                        <img src="/assets/image/googleLogo.png" alt="" />
                        <div>Sign in with Google</div>
                    </div>
                    <div className="btnTemp LoginWithOther" onClick={loginWithCounos}>
                        <img src="/assets/image/counos.png" alt="" />
                        <div>Sign in with Counos</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;
