// import ClientOAuth2 from "client-oauth2";
import Cookies from "universal-cookie";

const cookies = new Cookies();


const randNumber = cookies.get('randomNumber');


// const githubAuthGoogle = new ClientOAuth2({
//     clientId: "618759728460-jts7qi04ahdaddcjvc7cgdfi8sbtto14.apps.googleusercontent.com",
//     clientSecret: "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",
//     accessTokenUri: 'https://oauth2.googleapis.com/token',
//     authorizationUri: 'https://accounts.google.com/o/oauth2/auth',
//     redirectUri: 'https://xbitlotto.com/loginWithGoogle',
//     scopes: ['openid', 'profile', 'email'],
// });

const githubAuthGoogle = () => {}

export default githubAuthGoogle