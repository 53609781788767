import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// import { TrezorConnector } from "@web3-react/trezor-connector";

import {
    ConnectorNames,
    testNetworkChainId,
    testNetworkRPC,
    mainNetworkChainId
} from "./constants";

// const appStage = process.env.NODE_ENV;
const appStage = process.env.REACT_APP_APP_STAGE;

console.log(appStage, "appStage")

// const POLLING_INTERVAL = 12000;

const testRpcUrls = {
    [testNetworkChainId.sepolia]: testNetworkRPC.sepolia,
};

const mainRpcUrls = {
    [mainNetworkChainId.ethereum]: testNetworkRPC.binance,
};

const testSupportedChainId = [
    testNetworkChainId.sepolia,
];

const mainSupportedChainId = [
    mainNetworkChainId.ethereum,
];

const RPC_URLS = appStage === "development" ? testRpcUrls : mainRpcUrls;

const SupportedChainIdsApp = appStage === "development" ? testSupportedChainId : mainSupportedChainId;

export const Metamask = new InjectedConnector({
    supportedChainIds: SupportedChainIdsApp,
});

export const WalletConnect = new WalletConnectConnector({
    rpc: RPC_URLS,
    // supportedChainIds: SupportedChainIdsApp,
    supportedChainIds: [11155111],
    bridge: "https://bridge.walletconnect.org",
    qrcode: true
});

function getTrustWalletFromWindow() {
    const isTrustWallet = (ethereum) => {
        // Identify if Trust Wallet injected provider is present.
        const trustWallet = !!ethereum.isTrust;

        return trustWallet;
    };

    const injectedProviderExist =
        typeof window !== "undefined" && typeof window.ethereum !== "undefined";

    // No injected providers exist.
    if (!injectedProviderExist) {
        return null;
    }

    // Trust Wallet was injected into window.ethereum.
    if (isTrustWallet(window.ethereum)) {
        return window.ethereum;
    }

    // Trust Wallet provider might be replaced by another
    // injected provider, check the providers array.
    if (window.ethereum?.providers) {
        // ethereum.providers array is a non-standard way to
        // preserve multiple injected providers. Eventually, EIP-5749
        // will become a living standard and we will have to update this.
        return window.ethereum.providers.find(isTrustWallet) ?? null;
    }

    // Trust Wallet injected provider is available in the global scope.
    // There are cases that some cases injected providers can replace window.ethereum
    // without updating the ethereum.providers array. To prevent issues where
    // the TW connector does not recognize the provider when TW extension is installed,
    // we begin our checks by relying on TW's global object.
    return window["trustwallet"] ?? null;
}

async function listenForTrustWalletInitialized(
    { timeout } = { timeout: 3000 }
  ) {
    return new Promise((resolve) => {
      const handleInitialization = () => {
        resolve(getTrustWalletFromWindow());
      };
  
      window.addEventListener("trustwallet#initialized", handleInitialization, {
        once: true,
      });
  
      setTimeout(() => {
        window.removeEventListener(
          "trustwallet#initialized",
          handleInitialization,
          { once: true }
        );
        resolve(null);
      }, timeout);
    });
  }

export async function getTrustWalletInjectedProvider(
    { timeout } = { timeout: 3000 }
) {
    const provider1 = getTrustWalletFromWindow();

    if (provider1) {
        return provider1;
    }

    return listenForTrustWalletInitialized({ timeout });
}

// const walletLink = new WalletLinkConnector({
//     url: RPC_URLS[0],
//     appName: "web3-react example",
//     supportedChainIds: appStage==="development" ? testSupportedChainId : mainSupportedChainId,
// });

// const trezor = new TrezorConnector({
//     chainId: testNetworkChainId.binance,
//     url: testNetworkRPC.binance,
//     pollingInterval: POLLING_INTERVAL,
//     manifestEmail: "dummy@abc.xyz",
//     manifestAppUrl: "http://localhost:1234"
// });

export const connectorsByName = [
    {
        name: ConnectorNames.Metamask,
        connector: Metamask
    },
    {
        name: ConnectorNames.WalletConnect,
        connector: WalletConnect
    },
    // {
    //     name: ConnectorNames.WalletLink,
    //     connector: walletLink
    // },
    // {
    //     name: ConnectorNames.Trezor,
    //     connector: trezor
    // }
];
