import axios from 'axios';
import _, { method } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { apiUrl } from '../../utils/config';


const SupportPage = () => {


    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);

    const [ContactName, setContactName] = useState('');
    const [ContactEmail, setContactEmail] = useState('');
    const [ContactMessage, setContactMessage] = useState('');


    const handleShowText = (keyText) => {

        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];

            if (textObj.key == keyText) {

                return textObj.value
            }

        }
        return null

    }

    const handleShowImage = (keyImage) => {
        for (let i = 0; i < AllImageSite.length; i++) {
            const imageObj = AllImageSite[i];

            if (imageObj.key == keyImage) {

                return imageObj.value
            }

        }
        return null
    }

    const handleSubmitContactUs = () => {
        if (_.isEmpty(ContactName)) {
            toast.error("please complete Your Name")

        } else if (_.isEmpty(ContactEmail)) {
            toast.error("please complete Your Email")

        } else if (_.isEmpty(ContactMessage)) {
            toast.error("please complete Your Message")
        } else {
            axios({
                url: `${apiUrl}/support`,
                method: 'POST',
                data: {
                    name: ContactName,
                    email: ContactEmail,
                    description: ContactMessage,
                }
            }).then((res) => {
                toast.success("Your Message sent!");
                console.log(res.data);
            }).catch((err) => {
                toast.error("Error!");
                console.log(err);
            })
        }
    }



    return (
        <>
            <Helmet>
                <title> Support </title>
            </Helmet>
            <div className="otherPageBaner mb_2">
                <img src={`/assets/image/supportImage.jpg`} alt="" />
            </div>

            <div className="otherPageText mb_2">
                <span><div style="text-align: center;">If you have any questions or inquiries regarding Xbit International Lottery, please feel free to contact us.</div>
                    <p style="text-align: center;"><span style="font-weight: bold; font-size: 18px; color: rgb(204, 51, 0);"><span style="text-align: right;">Support Team Email :&nbsp;<span style="text-align: right;">info@xbitcc.com</span></span><br /></span></p>
                    <p style="text-align: center;"><span style="text-align: right;">We value your concerns and opinions.</span><br /></p></span>
            </div>


            {/* <div className="otherPageText centerText mb_2">
                {handleShowText("support_text1")}             
            </div>
            <div className="otherPageText centerText mb_2">
            {handleShowText("support_text2")} 
            </div>
            <div className="otherPageText centerText mb_2">
                <div>{handleShowText("support_text3")} </div>
                <div>
                    <a href={`mailto:${handleShowText("support_email")}`}>{handleShowText("support_email")} </a>
                </div>
            </div>
            <div className="otherPageText centerText mb_2">
            {handleShowText("support_text4")} 
            </div> */}


            <div className="contactUsMainForm">
                <div className="CUMF-header">
                    <div className="CUMF-title"> Contact Us </div>
                    <div className="CUMF-headerText">
                        Our support team is available to answer your questions 24/7
                    </div>
                </div>
                <div className="CUMF-body">
                    <div className="CUMF-bodyInput">
                        <label for="CUMF-name"> Name </label>
                        <input type="text" id="CUMF-name" className='inputSite' placeholder='Your Name...'
                            onChange={(e) => setContactName(e.target)}
                        />
                    </div>
                    <div className="CUMF-bodyInput">
                        <label for="CUMF-email"> Email </label>
                        <input type="email" id="CUMF-email" className='inputSite' placeholder='Your Email...'
                            onChange={(e) => setContactEmail(e.target)}
                        />
                    </div>
                    <div className="CUMF-bodyInput">
                        <label for="CUMF-message"> Message </label>
                        <textarea id="CUMF-message" className='siteTextarea' placeholder='Your Message...'
                            onChange={(e) => setContactMessage(e.target)}
                        ></textarea>
                    </div>

                    <div className="btnTemp1 CUMF-btn" onClick={handleSubmitContactUs}>
                        Submit
                    </div>

                </div>
            </div>



        </>
    );
}

export default SupportPage; 