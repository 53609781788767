import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


 const PrivacyAndPolicyPage = () => {



    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);


    const handleShowText = (keyText) =>{
  
        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];
                      
            if(textObj.key == keyText){
                
                return textObj.value
            }
            
        }
        return ""
        
    }
    
  const handleShowImage = (keyImage) =>{
    for (let i = 0; i < AllImageSite.length; i++) {
        const imageObj = AllImageSite[i];
                  
        if(imageObj.key == keyImage){
            
            return imageObj.value
        }
        
    }
    return null
}


     return ( 
        <>
            <Helmet>
                <title> Privacy and policy </title>
            </Helmet>
            <div className="otherPageText">
                <p style={{"text-align":"center"}}>
                    At Xbit Lottery, our ultimate aim is to safeguard the privacy of our website visitors. As such, 
                    in the following document you can see how your data will be treated when you use our website.
                </p>

                <p style={{"text-align":"center","marginTop":"20px"}}>
                    By using our website and agreeing to this policy, you consent to our use of cookies in 
                    accordance with the terms of this policy
                </p>
                
                <img src="/assets/image/privacyImage.jpg" alt="" style={{"marginTop":"30px"}} />

                <div className="simpleTitle leftTitle">
                    What kind of data do we collect?
                </div>

                <p>
                    It needs to be mentioned that we might collect, store and use the following types of personal data:
                </p>

                <ul style={{marginTop:"20px"}}>
                    <li>
                        Identity Data including first name and last name.
                    </li>
                    <li>
                    	Contact Data meaning your email and postal address.
                    </li>
                    <li>
                        Entry Data means details about entries you have made on our website including the 
                        numbers and draws you have entered.
                    </li>
                    <li>
                        Technical Data means details about the device(s) you use to access our website 
                        including your IP address, browser type and version, location, operating system and 
                        referral source.
                    </li>
                    <li>
                        Profile Data includes your login data plus any other optional information that you 
                        choose to give to us in your profile such as telephone number, preferences, feedback 
                        and survey responses.
                    </li>
                    <li>
                        Usage Data includes information about how you use our website, products and 
                        services. This includes your navigation through the site like how long you might 
                        spend on one of our webpages and which page you look at next, page response times 
                        and page interaction information such as site searches.
                    </li>
                    <li>
                        Marketing and Communications Data includes your preferences in receiving 
                        marketing from us, third-parties and your communication preferences.
                    </li>
                </ul>

                <div className="simpleTitle leftTitle">
                    How Are Such Data Collected?
                </div>

                <p>Personal Data is only collected when you use our website or create an account on the site. </p>
                <p>
                    Some data may also be collected if you email us for any reason, but only that data which is 
                    required to answer your questions and inquiries.
                </p>

                <div className="simpleTitle leftTitle">
                   How Is Your Personal Data Used?
                </div>

                <p>
                    Personal Data submitted to us via this website will be used for the purposes specified in this 
                    privacy policy or in relevant parts of the website.
                </p>

                <p>
                    We may use your personal data to:
                </p>

                <ul style={{marginTop:"20px"}}>
                    <li>
                        Administer the website.
                    </li>
                    <li>
                        Improve your browsing experience by personalizing the website to reflect your 
                        specific areas of interest.
                    </li>
                    <li>
                    Enable your use of the services available on the website.
                    </li>
                    <li>
                        Supply to you the services purchased via the website.
                    </li>
                    <li>
                        Send you general or marketing communications.
                    </li>
                    <li>
                        Send you information or email notifications which you have specifically requested.
                    </li>
                    <li>
                        Send you any newsletters and/or marketing communications relating to our business 
                        or the businesses of carefully-selected third parties which we think may be of interest 
                        to you and where you have specifically agreed to receiving this information by email. 
                        You can opt out of this by contacting us via Contact page.
                    </li>
                    <li>
                        Send you invitations to enter any promotions or competitions we may from time to 
                        time offer our visitors.
                    </li>

                    <li>
                        Deal with enquiries and complaints made by you relating to the website.
                    </li>
                    <li>
                        Keep the website secure and prevent fraud.
                    </li>
                    <li>
                        Verify compliance with International Laws and the terms and conditions governing 
                        the use of the website.
                    </li>
                </ul>

                <p>
                    We will not provide your personal data to any third parties for the purpose of direct 
                    marketing if you have unsubscribed from the service. Your privacy is very important to us.
                </p>

                <div className="simpleTitle leftTitle">
                    Disclosures
                </div>

                <p>
                    We may disclose your personal data to any of our employees, officers, agents, suppliers or 
                    subcontractors, or to any member of our group of companies (this means our ultimate holding 
                    company and all its subsidiaries) insofar as it would be deemed necessary within the bounds of 
                    herein privacy policy.
                </p>

                <p>
                    In addition, we may disclose your personal data:
                </p>

                <ul style={{marginTop:"20px"}}>
                    <li>
                        To the extent that we are required to do so by law.
                    </li>

                    <li>
                        In connection with any ongoing or prospective legal proceedings.
                    </li>
                    <li>
                        In order to establish, exercise or defend our legal rights (including providing 
                        information to others for the purposes of fraud prevention.
                    </li>
                    <li>
                        To the purchaser (or prospective purchaser) of any business or asset that we are (or 
                        are contemplating) selling.
                    </li>
                    <li>
                        To any person who we reasonably believe may apply to a court or other competent 
                        authority for disclosure of that personal data where, in our reasonable opinion, such 
                        court or authority would be reasonably likely to order disclosure of that personal data.
                    </li>
                </ul>

                <p>
                Except as provided in this privacy policy, we will not provide your information to third parties.
                </p>

                <div className="simpleTitle leftTitle">
                    International Data Transfers
                </div>

                <p>
                    Information that is collected by us may be stored and processed and transferred between any 
                    of the countries in which we operate in order to enable us to use the information in accordance 
                    with this privacy policy. Additionally, information which you provide may be transferred to 
                    other countries.
                </p>

                <p>
                    Furthermore, personal data that you submit for publication on the website may subsequently 
                    be available, via the internet, around the world. We cannot prevent the use or misuse of such 
                    information by others.
                </p>

                <div className="simpleTitle leftTitle">
                    Security of Your Personal Data
                </div>

                <p>
                    We consider it our absolute responsibility to protect your data secure in order to create a safe 
                    space for you to take part in our international lottery.
                </p>

                <p>
                    In doing so, we will take any technical, organization or otherwise action necessary.
                </p>

                <p>
                We will store all the personal data you provide on our secure servers.
                </p>

                <p>
                    All electronic transactions entered into via the website will be protected by state of the art encryption technology.
                </p>

                <p>
                    You acknowledge that the transmission of information over the internet is potentially 
                    insecure and we cannot guarantee the security of data sent over the internet.
                </p>

                <p>
                    You are responsible for keeping your password and other login details confidential. While you 
                    will require your password to log in to your account, you will never be asked to disclose your 
                    password to a representative or employee of the company.
                </p>

                <p>
                    We will cooperate with all law enforcement requests insofar as they are from official 
                    international organizations.
                </p>

                <div className="simpleTitle leftTitle">
                    Third Party Websites
                </div>

                <p>
                    The website contains links to other websites. We are not responsible for the privacy policies 
                    or data practices of third party websites including Facebook, Twitter and Google.
                </p>

                <div className="simpleTitle leftTitle">
                    Data Retention
                </div>

                <p>
                    We will retain your data for as long as necessary to fulfil the purposes for which it was collected 
                    including any legal, reporting, accounting or insurance requirements.
                </p>

                <p>
                    So long as it is legal for us to do so, you may request the deletion of your data at any time.
                </p>

                <p>
                    Occasionally we may keep data for a longer period purely for statistical analysis. Such data is 
                    anonymized and cannot be associated with you or your activities.
                </p>

                <div className="simpleTitle leftTitle">
                    Contact
                </div>

                <p>
                    If you have any questions about this privacy policy or our treatment of your personal data, or 
                    any other concern, question, or inquiry, please feel free to contact us via the <span><a href="https://www.xbitlotto.com/support" target="_blank" rel="noopener noreferrer" style={{textDecoration : "underline"}}>Contact page</a></span>. It is 
                    our utmost pleasure to answer your any question
                </p>

                {/* <div>
                    At Xbit Lottery, our ultimate aim is to safeguard the privacy of our website visitors. As such, in the following document you can see how your data will be treated when you use our website.
                </div>


                <div className="simpleTitle leftTitle">
                    What kind of data do we collect?
                </div>

                <div>
                    It needs to be mentioned that we might collect, store and use the following types of personal data:
                </div>

                <ul>
                    <li>
                        Identity Data including first name and last name.
                    </li>
                    <li>
                    	Contact Data meaning your email and postal address.
                    </li>
                    <li>
                    	Entry Data means details about entries you have made on our website including the numbers and draws you have entered.
                    </li>
                    <li>
                    	Technical Data means details about the device(s) you use to access our website including your IP address, browser type and version, location, operating system and referral source.
                    </li>
                    <li>
                    	Profile Data includes your login data plus any other optional information that you choose to give to us in your profile such as telephone number, preferences, feedback and survey responses.
                    </li>
                    <li>
                    	Usage Data includes information about how you use our website, products and services. This includes your navigation through the site like how long you might spend on one of our webpages and which page you look at next, page response times and page interaction information such as site searches.
                    </li>
                    <li>
                    	Marketing and Communications Data includes your preferences in receiving marketing from us, third-parties and your communication preferences.
                    </li>
                </ul>

                <div className="simpleTitle leftTitle">
                    How Are Such Data Collected?
                </div>

                <div>
                    Personal Data submitted to us via this website will be used for the purposes specified in this privacy policy or in relevant parts of the website.
                </div>

                <div>
                    We may use your personal data to:
                </div>

                <ul>
                    <li>
                    	Administer the website.
                    </li>
                    <li>
                    	Improve your browsing experience by personalizing the website to reflect your specific areas of interest.
                    </li>
                    <li>
                    	Enable your use of the services available on the website.
                    </li>
                    <li>
                    	Supply to you the services purchased via the website.
                    </li>
                    <li>
                    	Send you general or marketing communications.
                    </li>
                    <li>
                    	Send you information or email notifications which you have specifically requested.
                    </li>
                    <li>
                    	Send you any newsletters and/or marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you and where you have specifically agreed to receiving this information by email. You can opt out of this by contacting us via Contact page.
                    </li>
                    <li>
                    	Send you invitations to enter any promotions or competitions we may from time to time offer our visitors.
                    </li>
                    <li>
                    	Deal with enquiries and complaints made by you relating to the website.
                    </li>
                    <li>
                    	Keep the website secure and prevent fraud.
                    </li>
                    <li>
                    	Verify compliance with International Laws and the terms and conditions governing the use of the website.
                    </li>
                </ul>

                <div>
                    We will not provide your personal data to any third parties for the purpose of direct marketing if you have unsubscribed from the service. Your privacy is very important to us.
                </div>

                <div className="simpleTitle leftTitle">
                    Disclosures
                </div>

                <div>
                We may disclose your personal data to any of our employees, officers, agents, suppliers or subcontractors as far as it would be deemed necessary within the bounds of herein privacy policy.
                </div>

                <div>
                    In addition, we may disclose your personal data:
                </div>

                <ul>
                    <li>
                    	To the extent that we are required to do so by law.
                    </li>
                    <li>
                    	In connection with any ongoing or prospective legal proceedings.
                    </li>
                    <li>
                    	In order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention.
                    </li>
                    <li>
                    	To the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling.
                    </li>
                    <li>
                    	To any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal data where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal data.
                    </li>
                </ul>

                <div>
                    Except as provided in this privacy policy, we will not provide your information to third parties.
                </div>

                <div className="simpleTitle leftTitle">
                    Security of Your Personal Data
                </div>

                <div>
                    We consider it our absolute responsibility to protect your data secure in order to create a safe space for you to take part in our international lottery.
                </div>

                <div>
                    In doing so, we will take any technical, organization or otherwise action necessary.
                </div>

                <div>
                    We will store all the personal data you provide on our secure servers.
                </div>

                <div>
                    All electronic transactions entered into via the website will be protected by state of the art encryption technology.
                </div>

                <div>
                    You acknowledge that the transmission of information over the internet is potentially insecure and we cannot guarantee the security of data sent over the internet.
                </div>

                <div>
                    You are responsible for keeping your password and other login details confidential. While you will require your password to log in to your account, you will never be asked to disclose your password to a representative or employee of the company.
                </div>

                <div>
                    We will cooperate with all law enforcement requests insofar as they are from official international organizations.
                </div>

                <div className="simpleTitle leftTitle">
                    Third Party Websites
                </div>

                <div>
                    The website contains links to other websites. We are not responsible for the privacy policies or data practices of third party websites including Facebook, Twitter and Google.
                </div>

                <div className="simpleTitle leftTitle">
                    Data Retention
                </div>

                <div>
                    We will retain your data for as long as necessary to fulfil the purposes for which it was collected including any legal, reporting, accounting or insurance requirements.
                </div>

                <div>
                    So long as it is legal for us to do so, you may request the deletion of your data at any time.
                </div>

                <div>
                    Occasionally we may keep data for a longer period purely for statistical analysis. Such data is anonymized and cannot be associated with you or your activities.
                </div>

                <div className="simpleTitle leftTitle">
                    Contact
                </div>

                <div>
                    If you have any questions about this privacy policy or our treatment of your personal data, or any other concern, question, or inquiry, please feel free to contact us via the Contact page. It is our utmost pleasure to answer your any question.
                </div> */}








            </div> 
        </>
      );
 }
  
 export default PrivacyAndPolicyPage;