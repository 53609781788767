import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


const DocumentPage = () => {

    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);


    const handleShowText = (keyText) => {

        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];

            if (textObj.key == keyText) {

                return textObj.value
            }

        }
        return null

    }
    const handleShowImage = (keyImage) => {
        for (let i = 0; i < AllImageSite.length; i++) {
            const imageObj = AllImageSite[i];

            if (imageObj.key == keyImage) {

                return imageObj.value
            }

        }
        return null
    }



    return (
        <>
            <Helmet>
                <title> Work With Us </title>
            </Helmet>
            <div className="otherPageBaner">
                <img src={`/assets/image/documentImage.jpg`} alt="" />
            </div>
            <div className="otherPageText">
                <div className="simpleTitle leftTitle">
                    Claiming Xbit lotto prizes
                </div>

                <div className="documentText">
                    Xbit Lottery welcomes various forms of collaborations. It can stay at that level for four draws without a winner before the full amount is rolled down and shared In this regard, we offer APIs of our lottery platform that can be implemented by other platforms and companies. In this way, you can benefit from the lottery mechanism of Xbit on your own website and hold a lottery. To do this, you can call the number in this page and also download the document provided, showing a tutorial on how to use the above mentioned APIs.On the other hand, you can use our APIs to hold Xbit Lottery on your website on our behalf. In this way, you will receive a percentage of benefits from holding the lottery.

                </div>
                {/* <div className="documentText">
                    In this regard, we offer APIs of our lottery platform that can be implemented by other platforms and companies.
                </div>
                <div className="documentText">
                    In this way, you can benefit from the lottery mechanism of Xbit on your own website and hold a lottery. To do this, you can call the number in this page and also download the document provided, showing a tutorial on how to use the above mentioned APIs.
                </div>
                <div className="documentText mb_2">
                    On the other hand, you can use our APIs to hold Xbit Lottery on your website on our behalf. In this way, you will receive a percentage of benefits from holding the lottery.
                </div> */}

                {/* <div className="documentText">
                    Api source available at <span><a href="https://gitlab.com/naeimsafaee1412/x-bit-lottery">gitlab</a></span> branch master
                </div>

                <div className="documentText">
                    Site source available at <span><a href="https://gitlab.com/naeimsafaee1412/x-bit-lottery-react-js">gitlab</a></span> branch master
                </div>
                 */}
                {/* <div className="documentText centerText mb_2">
                {handleShowText("document_text1")} 
                    <div>
                        <a href="tel:"></a>
                    </div>
                </div>  
                <div className="documentText centerText mb_2">
                {handleShowText("document_text2")} 
                </div>   */}
                <div className="documentText">
                    Postman available at this <span><a href="/Lottery.postman_collection.json" target="_blank" download>link</a></span>
                </div>

                {/* <div className="documentText">
                    If you have any problems or need help, just open a new issue in the repository issues  section.
                </div> */}



            </div>
        </>
    );
}

export default DocumentPage;