import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { reducers } from './../reducers/index';
import { updateLotteryCurrentIdAction } from "../actions/app";

export const store = createStore(reducers , compose(applyMiddleware(thunk),

// window.__REDUX_DEVTOOLS_EXTENSION__ &&
// window.__REDUX_DEVTOOLS_EXTENSION__()

))


// initialize
const dispatchSeries = async () => {
    try {
      // Dispatch actions in the desired order, without waiting for each to complete
      const promises = [
        store.dispatch(updateLotteryCurrentIdAction())
      ];
  
      await Promise.all(promises);
  
  
    } catch (error) {
  
      console.error('An error occurred in dispatchSeries:', error);
    }
  };

dispatchSeries();

// if(window.location.pathname === "/login"){
    
// }else{
// }

// store.dispatch(getAllText());
// store.dispatch(getAllImage());

// subscribe
// store.subscribe(() => console.log(store.getState()))