export const lastUserMockData = [
    {
        player_address: "0xF29Ff96aaEa6C9A1fBa851f74737f3c069d4f1a9",
        numbers: [
            [1,2,3,5,8],
            [21,32,45,26,11]
        ]
    },
    {
        player_address: "0xeA7e42C7C9262e6831bfa7Be71d67aa5523E27BD",
        numbers: [
            [11,12,25,26,42],
            // [9,33,21,29,7]
        ]
    },
    {
        player_address: "0xC4bFccB1668d6E464F33a76baDD8C8D7D341e04A",
        numbers: [
            [21,32,45,26,11],
            [6,15,22,39,17]
        ]
    },
    {
        player_address: "0xd1ccB15E30a9Fd5d96a731064205BC12D3183869",
        numbers: [
            [9,33,21,29,7]
        ]
    },
]