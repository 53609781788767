import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';


import {apiUrl} from '../../utils/config';
import { useSelector } from 'react-redux';


const SettingsPage = () => { 

    const appDetail = useSelector(state => state.app);

    const [Coins,setCoins] = useState([]);

    const [Changed,setChanged] =useState(false);

    const [OldUserData,setOldUserData]=useState([]);


    // const [Pass, setPass] = useState('');
    // const [RePass, setRePass] = useState('');
    const [Firstname, setFirstname] = useState('');
    const [Surname, setSurname] = useState('');
    const [Nickname, setNickname] = useState('');
    const [Postcode, setPostcode] = useState();
    const [UserLocation, setUserLocation] = useState('');
    const [Street, setStreet] = useState('');
    const [UserNumber, setUserNumber] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');

    const [DepositLimit,setDepositLimit] = useState('');

    const [EditNewWallet, setEditNewWallet] = useState({});
    let EditNewWalletIndex = -1 ; 
    let coins1 = [];

    const [wallet2faModal,setwallet2faModal] = useState(false);
    const [_2fa,set_2fa] = useState('');
    const [walletChanged, setwalletChanged] = useState(false);
    const [ChangeWalletCoin, setChangeWalletCoin] = useState([]);

    const [UserWallet, setUserWallet] = useState([])
    const modalTemp = useRef(null);
    const modalTemp2FA = useRef(null);
    
    const [Pass_2fa,setPass_2fa] = useState('');
    const [PassValidate, setPassValidate] = useState({});
    const [Pass,setPass] = useState('');
    const [RePass,setRePass] = useState('');

    const modalTempPass = useRef(null);
    const passInput = useRef(null);
    
    const modalTempPhoneNumber = useRef(null);

    
    let token = "";
    const location = useLocation();
    let history = useHistory();
    const handleCheckUser = (typeCall) => {
      const allCookie = document.cookie;
      // let token = "";
      if (allCookie.search("token") > -1) {
        token = document.cookie
        .split('; ')
        .find(row => row.startsWith('token='))
        .split('=')[1];
            return true;
        }
        if(typeCall !== "check"){
          history.push('/login')
        }
        return false;
    }

    
    const handleGetCoins = ()=>{

        axios({
            url:apiUrl + "/coin",
            headers: {
                'x-auth-token': token,
            },
        }).then( (response) => {
            coins1 = response.data.data ;
            setCoins(coins1);
            // EditNewWallet
            // for (let i = 0; i < coins1.length; i++) {
            //     const element = coins1[i];
                
            // }

            console.log(response.data.data);
            setWalletCoinsArray(); 

            // console.log(moment(result).isBefore(moment()));
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    const handleEditWalletAddress = (index = -1) =>{
        if(index !== -1){
            setEditNewWallet(UserWallet[index]);
        }
        modalTemp.current.style.opacity = 1;
        modalTemp.current.style.visibility = 'visible';

    }

    const handleCloseEditWallet = () =>{
        setEditNewWallet({});
        setwallet2faModal(false);
        modalTemp.current.style.opacity = 0;
        modalTemp.current.style.visibility = 'hidden';

    }

    const handleShowChangePass = (index = -1) =>{
        if(index !== -1){
            modalTempPhoneNumber.current.style.opacity = 1;
            modalTempPhoneNumber.current.style.visibility = 'visible';
        }else{
            modalTempPass.current.style.opacity = 1;
            modalTempPass.current.style.visibility = 'visible';
        }

    }

    const handleCloseChangePass = (index = -1) =>{
        // setEditNewWallet({});
        // setwallet2faModal(false);
        if(index !== -1){
            modalTempPhoneNumber.current.style.opacity = 0;
            modalTempPhoneNumber.current.style.visibility = 'hidden';
        }else{
            modalTempPass.current.style.opacity = 0;
            modalTempPass.current.style.visibility = 'hidden';
        }

    }

    const setWalletCoinsArray = () => {

        console.log("object1234") 
        console.log(Coins)

        let walletArray = [];
        for (let index = 0; index < coins1.length; index++) {
            const element = coins1[index];
            // let walletObj = {'coin_id':element._id,'address' : element.client_address} 
            if(element.symbol == 'xbt'){
                walletArray.push(element)
            }
            // if(element.client_address){
            // }
        }
        setUserWallet(walletArray);
    }

    const handleChangeAddressWallet = (wallet) =>{


        let UserWalletCopy = [...UserWallet];
        let ChangeWalletCoinCopy = [...ChangeWalletCoin];

        for (let i = 0; i < UserWalletCopy.length; i++) {
            const element = UserWalletCopy[i];
            if(element._id === wallet._id){
                console.log(element)
                element.client_address = wallet.client_address;
                ChangeWalletCoinCopy.push(element._id)
            }
        }
        console.log(UserWalletCopy);
        setChangeWalletCoin(ChangeWalletCoinCopy);
        setUserWallet(UserWalletCopy);
        setChanged(true);
        setwalletChanged(true);
        handleCloseEditWallet();
        // console.log(wallet.client_address);
    }

    const handleGetUserInfo = () =>{
        var userInfoVar = {};
        axios({
            url:apiUrl + "/client",
            method:'get',
            headers: {
                'x-auth-token': token,
            },
        }).then( (response) => {
            console.log(response.data)
            userInfoVar = response.data.data;
            setOldUserData(response.data.data)

            setFirstname(userInfoVar.firstname);
            setSurname(userInfoVar.surname);
            setNickname(userInfoVar.nickname);
            setPostcode(userInfoVar.post_code);
            setUserLocation(userInfoVar.location);
            setStreet(userInfoVar.street);
            setUserNumber(userInfoVar.number);
            setPhoneNumber(userInfoVar.phone_number);
            setDepositLimit(userInfoVar.deposit_limit);
        }).catch((error) => {
            console.log(error)
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response}`)
            }
        })
        console.log(userInfoVar)

        console.log(OldUserData)

        

    }

    const handleRestoreChangedValue = () =>{
        setChanged(false);

        setFirstname(OldUserData.firstname);
        setSurname(OldUserData.surname);
        setNickname(OldUserData.nickname);
        setPostcode(OldUserData.post_code);
        setUserLocation(OldUserData.location);
        setStreet(OldUserData.street);
        setUserNumber(OldUserData.number);
        if(OldUserData.phone_number){
            setPhoneNumber(OldUserData.phone_number);
        }else{
            setPhoneNumber('');
        }
        if(OldUserData.deposit_limit){
            setPhoneNumber(OldUserData.deposit_limit);
        }else{
            setPhoneNumber('');
        }
    }

    const handleSubmitWallet = () =>{
        let walletArraySubmit = [];
        console.log(ChangeWalletCoin);

        for (let i = 0; i < UserWallet.length; i++) {
            const element = UserWallet[i];
            if(ChangeWalletCoin.indexOf(element._id) > -1){
                console.log("objectWallet")
                let walletObj = {'coin_id':element._id,'address' : element.client_address} 
                walletArraySubmit.push(walletObj);
            }
        }



        axios({
            url:apiUrl + "/wallet",
            method:'post',
            headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
            },
            data:JSON.stringify(walletArraySubmit)
        }).then( (response) => {
            console.log(response);
            toast.success(`${response.data.message}`);
            setwallet2faModal(true);
            document.getElementsByClassName('clearInput')[0].value = '';
            handleEditWalletAddress();
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    const verifyWallet2fa = () =>{
        handleCheckUser('check'); 

        axios({
            url:apiUrl + "/wallet/"+_2fa,
            method:'get',
            headers: {
                'x-auth-token': token,
            },
        }).then( (response) => {
            console.log(response);
            toast.success(`${response.data.message}`);
            handleCloseEditWallet();
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    const handleSubmitInfo = async() =>{
        handleCheckUser('check'); 



        if(walletChanged){
            await handleSubmitWallet();
        }


        axios({
            url:apiUrl + "/client",
            method:'put',
            headers: {
                'x-auth-token': token,
            },
            data:{firstname:Firstname,surname:Surname,nickname:Nickname,post_code:Postcode,location:UserLocation,street:Street,number:UserNumber,phone_number:PhoneNumber,deposit_limit:DepositLimit}
        }).then( (response) => {
            console.log(response);
            toast.success(`${response.data.message}`);
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data}`)
            }
        })
    }

    const handleCheckPass = () =>{
        // var validation = passwordStrength(Pass);
        // console.log(validation)
        setPassValidate({id:2 , value : "strong"});
        console.log(PassValidate.id)
    }

    const handleCreateChangePass = () =>{
        handleCheckUser('check'); 
        axios({
            url:apiUrl + "/client/change_password",
            method:'post',
            headers: {
                'x-auth-token': token,
            },
        }).then( (response) => {
            console.log(response);
            toast.success(`${response.data.message}`);
            handleShowChangePass();
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    const handleConfirmChangepass = () =>{
        handleCheckUser('check'); 

        axios({
            url:apiUrl + "/client/change_password/" + Pass_2fa,
            method:'put',
            headers: {
                'x-auth-token': token,
            },
            data:{password:Pass}
        }).then( (response) => {
            console.log(response);
            toast.success(`${response.data.message}`);
            handleCloseChangePass();
        }).catch((error) => {
            if(error.response.status === 403){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }else{
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    useEffect(()=>{

        // handleCheckUser('check'); 
        // handleGetCoins();
        // handleGetUserInfo();

    },[]);

    useEffect(() =>{handleCheckPass()},[Pass]);

    return (
        <>

            <Helmet>
                <title>Settings</title>
            </Helmet>

            <div className="siteModalmain" ref={modalTemp}>
                <div className="siteModalBox">
                    {wallet2faModal ? 
                    <>
                        {/* {handleDynamicModal} */}

                        <div className="modalClosebtn" onClick={handleCloseEditWallet}>
                            &#10006;
                        </div>

                        <div className="modalImage">
                            <div className="modalImageTitle"> 2FA Wallet </div>
                        </div>


                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="text" id="authPass" className="inputSite clearInput" placeholder={`Your 2FA Wallet Address`}
                                onChange={e => set_2fa(e.target.value)}
                            />
                        </div>

                        <div className="btnTemp1 customRegisterbtn" onClick={() => verifyWallet2fa()}>
                            Submit
                            {/* <img src="/assets/image/arrow4.svg" alt="" /> */}
                        </div>
                    
                    </>
                    :
                    <>

                        {/* {handleDynamicModal} */}

                        <div className="modalClosebtn" onClick={handleCloseEditWallet}>
                            &#10006;
                        </div>

                        <div className="modalImage">
                            <img src={EditNewWallet.icon} alt="" />
                            <div className="modalImageTitle"> {EditNewWallet.symbol} </div>
                        </div>


                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="text" id="authPass" className="inputSite clearInput" placeholder={`New ${EditNewWallet.name} Wallet Address`}
                                onChange={e => EditNewWallet.client_address=e.target.value}
                            />
                        </div>

                        <div className="btnTemp1 customRegisterbtn" onClick={() => handleChangeAddressWallet(EditNewWallet)}>
                            CONTINUE
                            <img src="/assets/image/arrow4.svg" alt="" />
                        </div>
                    </>}


                </div>
            </div>

            <div className="siteModalmain" ref={modalTempPass}>
                <div className="siteModalBox">

                        <div className="modalClosebtn" onClick={() => handleCloseChangePass()}>
                            &#10006;
                        </div>

                        <div className="modalImage">
                            <div className="modalImageTitle"> Change Password </div>
                        </div>


                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <div className="registerInputTitle"> 
                                    {/* <div>Password</div> */}
                                    {PassValidate.id <= '2' ? 
                                        <>

                                                
                                            <div className="inputError">
                                                {PassValidate.value}
                                            </div>

                                        </>
                                    
                                    :<><div className="inputSuccess">{PassValidate.value}</div></>}
                                </div>
                            <input type="password" id="authPass" className="inputSite" placeholder={`New Password`}
                                onChange={e => setPass(e.target.value)}
                                ref={passInput}
                            />
                        </div>

                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="password" id="authPass" className="inputSite" placeholder={`Repeat New Password`}
                                onChange={e => setRePass(e.target.value)}
                            />
                        </div>

                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="text" id="authPass" className="inputSite" placeholder={`Your 2FA Change Password`}
                                onChange={e => setPass_2fa(e.target.value)}
                            />
                        </div>

                        <div className="btnTemp1 customRegisterbtn" onClick={() => handleConfirmChangepass()}>
                            Submit
                            {/* <img src="/assets/image/arrow4.svg" alt="" /> */}
                        </div>
                


                </div>
            </div>


            <div className="siteModalmain" ref={modalTempPhoneNumber}>
                <div className="siteModalBox">

                        <div className="modalClosebtn" onClick={() => handleCloseChangePass(1)}>
                            &#10006;
                        </div>

                        <div className="modalImage">
                            <div className="modalImageTitle"> Phone Number </div>
                        </div>

                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="text" id="authPass" className="inputSite" placeholder={`Your Phone Number`}
                                onChange={e => {setPhoneNumber(e.target.value); setChanged(true)}}
                            />
                        </div>

                        <div className="btnTemp1 customRegisterbtn" onClick={() => handleCloseChangePass(1)}>
                            Submit
                            {/* <img src="/assets/image/arrow4.svg" alt="" /> */}
                        </div>
                


                </div>
            </div>


            {Changed? 
            
                <div className="settingsmainButton">
                    <div className="btnTemp" onClick={handleRestoreChangedValue}> Restore </div>
                    <div className="btnTemp1" onClick={handleSubmitInfo}> Save Changes </div>
                </div>
            
            :null}


            {/* <div className="firstSettingMainBox">

                    <div className="addressWalletCard">
                        <div className="AWC-image">
                            <div>Password</div>
                        </div>
                        <div className="AWC-address" onClick={() => handleCreateChangePass()}>
                            ************
                        </div>
                        <div className="AWC-edit">
                            <img src="/assets/image/edit.png" alt="" onClick={() => handleCreateChangePass()} />
                        </div>
                    </div>

                     <div className="addressWalletCard">
                        <div className="AWC-image">
                            <div>Phone Number</div>
                        </div>
                        <div className="AWC-address" onClick={() => handleShowChangePass(1)}>
                            {PhoneNumber}
                        </div>
                        <div className="AWC-edit">
                            <img src="/assets/image/edit.png" alt="" onClick={() => handleShowChangePass(1)} />
                        </div>
                    </div>
            </div> */}


            <div className="addressWalletMainBox">
                <div className="simpleTitle">Your Address Wallet </div>
                <div className="addressWalletBox">

                    <div className="addressWalletCard">
                        <div className="AWC-image">
                        <img src={appDetail.siteCoin.icon} alt="" />
                            <div>{appDetail.siteCoin.coin}</div>
                        </div>
                        {/* <div className="AWC-address" onClick={() => handleEditWalletAddress(index)}> */}
                        <div className="AWC-address">
                            {appDetail.wallet.address}
                        </div>
                        {/* <div className="AWC-edit">
                            <img src="/assets/image/edit.png" alt="" onClick={() => handleEditWalletAddress(index)} />
                        </div> */}
                    </div>
                    
                </div>


                <div className="settingProfileMainBox">
                    <div className="simpleTitle">PROFILE</div>
                    <div className="settingProfileBox">

                        <div className="authInputBox">
                            <label htmlFor="authPass">First Name</label>
                            <input type="text" id="authPass" className="inputSite" placeholder="First Name"
                                value={Firstname}
                                onChange={e => {setFirstname(e.target.value) ; setChanged(true)}}
                            />
                        </div>

                        <div className="authInputBox">
                            <label htmlFor="authPass">Surname</label>
                            <input type="text" id="authPass" className="inputSite" placeholder="Surname"
                                        value={Surname}
                                onChange={e => {setSurname(e.target.value) ; setChanged(true)}}
                            />
                        </div>

                        <div className="authInputBox">
                            <label htmlFor="authPass">Nick Name</label>
                            <input type="text" id="authPass" className="inputSite" placeholder="Nick Name"
                                        value={Nickname}
                                onChange={e => {setNickname(e.target.value) ; setChanged(true)}}
                            />
                        </div>

                        {/* <div className="authInputBox">
                            <label htmlFor="authPass">Street</label>
                            <input type="text" id="authPass" className="inputSite" placeholder="Street"
                                        value={Street}
                                onChange={e => {setStreet(e.target.value) ; setChanged(true)}}
                            />
                        </div>

                        <div className="authInputBox settingProInput1">
                            <div>

                                <div className="authInputBox">
                                    <label htmlFor="authPass">Number</label>
                                    <input type="text" id="authPass" className="inputSite" placeholder="Number"
                                        value={UserNumber}
                                        onChange={e => {setUserNumber(e.target.value) ; setChanged(true)}}
                                    />
                                </div>
                            </div>
                            <div>

                                <div className="authInputBox">
                                    <label htmlFor="authPass">Post Code</label>
                                    <input type="text" id="authPass" className="inputSite" placeholder="Post Code"
                                        value={Postcode}
                                        onChange={e => {setPostcode(e.target.value) ; setChanged(true)}}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="authInputBox">
                            <label htmlFor="authPass">Location</label>
                            <input type="text" id="authPass" className="inputSite" placeholder="Location"
                                        value={UserLocation}
                                onChange={e => {setUserLocation(e.target.value) ; setChanged(true)}}
                            />
                        </div> */}

                    </div>
                </div>

                
                {/* <div className="settingBox2">
                    <div className="simpleTitle">SETTING</div>
                    
                    <div className="settingBox2Body">
                        <div className="SB2B-depositLimitMainBox">
                            <div className="SB2B-depositLimitLeft">
                                <div className="SB2B-depositLimitLeftTitle">Deposit limit</div>
                                <div className="SB2B-depositLimitLeftText">
                                    Set limit to your monthly deposit amount
                                </div>
                            </div>
                            <div className="SB2B-depositLimitInput">
                                <div className="paymentAmountIInput">
                                    <label for="depositAmount">XBit</label>
                                    <input type="text" id="depositAmount" className="inputSite" placeholder="Amount"
                                    onChange={e => {setDepositLimit(e.target.value); setChanged(true)}} value={DepositLimit} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}

            </div>



        </>
    );
}

export default SettingsPage;