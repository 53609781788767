import { getLotteryCurrentId } from "../utils/contractWeb3";

export const activeAndSetWallet = (walletData) => {
    return async dispatch => {
        await dispatch({ type: "connectWalletSite", payload: walletData});
    }
}

export const disconnectWallet = () => {
    return async dispatch => {
        await dispatch({ type: "disconnectWallet", payload: {}});
    }
}

export const updateWalletBalance = (walletBalance) => {
    return async dispatch => {
        await dispatch({ type: "updateWalletBalance", payload: walletBalance});
    }
}

export const updateLotteryCurrentIdAction = (walletBalance) => {
    return async dispatch => {
        const res = await getLotteryCurrentId();
        await dispatch({ type: "SET_LOTTERY_ID", payload: res});
    }
}