import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.headers.post["Content-Type"] = "application/json";

// const token = localStorage.getItem("token");
const allCookie = document.cookie;
// let token = "";

if(allCookie.search("token") > -1){

  // token = document.cookie
  //  .split('; ')
  //  .find(row => row.startsWith('token='))
  //  .split('=')[1];
}
// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmY4YTcyMmNkZWJmNmE3NmZkMzA2MjEiLCJpYXQiOjE2MTA0ODI2ODB9.tl7ih5KrBXxNkmXANB1RjUh8dXvoHK20wkoCNl1vVp8";
// axios.defaults.headers.post["x-auth-token"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmExODEzYzc0MzFkNzAwYzYxZjhkMzgiLCJpYXQiOjE2MDQ0MjAyOTV9.8VD-eL5cgNOiiWxQKdaSWtTnrKkVI-bqNQIwgLR5Tr4";
// axios.defaults.headers.get["x-auth-token"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmExODEzYzc0MzFkNzAwYzYxZjhkMzgiLCJpYXQiOjE2MDQ0MjAyOTV9.8VD-eL5cgNOiiWxQKdaSWtTnrKkVI-bqNQIwgLR5Tr4";

// if(token) axios.defaults.headers.common["x-auth-token"] =token;

axios.interceptors.response.use(null, error => {
    const expectedErrors =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (!expectedErrors) {
        console.log(error);
        toast.error(" Server Has an error!! ", {
            position: "top-center",
            closeOnClick: true
        });
        localStorage.removeItem("token");
        // window.location.href="/login";
    }

    return Promise.reject(error);
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};
