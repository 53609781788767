import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";
import { apiUrl } from '../../utils/config';
import { useHistory } from "react-router";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { Helmet } from "react-helmet";
import { checkWinningPrize, getLotteryNumbers, getLotteryPlayerTicket, getUserClaimPrize, getUserTickets } from "../../utils/contractWeb3";
import { useSelector } from "react-redux";
import { convertWeiToEth } from "../../utils";
import { toast } from "react-toastify";
import CustomModal from "../../components/customModal";
import { LoadingData } from "../../components/loading";


const MyTicket = () => {

    const appDetail = useSelector(state => state.app);



    const [TicketsRow, setTicketsRow] = useState([]);
    const [TicketsRowUp, setTicketsRowUp] = useState([]);
    const [TicketsRowWin, setTicketsRowWin] = useState([]);
    const [TicketsRowLoss, setTicketsRowLoss] = useState([]);

    const [CurrentPage, setCurrentPage] = useState(1);
    const [IndexOfLastTodo, setIndexOfLastTodo] = useState();
    const [IndexOfFirstTodo, setIndexOfFirstTodo] = useState()

    const [PageTicketRow, setPageTicketRow] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setitemsPerPage] = useState(0);
    const [SelectedTabs, setSelectedTabs] = useState(0);
    const [UserTicketDetail, setUserTicketDetail] = useState([])
    const [LotteryNumberArr, setLotteryNumberArr] = useState([0, 0, 0, 0, 0])
    const [ShowWinningModal, setShowWinningModal] = useState({
        show: false,
        loading: false,
        data: {},
    })

    const [LotteriesArr, setLotteriesArr] = useState([]);
    const [LotteryTabIndex, setLotteryTabIndex] = useState("0");

    const [LoadingDataTicket, setLoadingDataTicket] = useState(true)

    const SelectOptions = [
        { value: 'ALL', label: 'ALL' },
        { value: 'UPCOMING', label: 'UPCOMING' },
        { value: 'WIN', label: 'WIN' },
        { value: 'LOSS', label: 'LOSS' },
    ];

    const defaultOption = SelectOptions[0];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: '#111',
            color: state.isSelected ? '#fff' : '#111',
            backgroundColor: state.isSelected ? '#b92f28' : '',
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 160,
            display: 'flex',
            borderRadius: '30px',
            backgroundColor: 'rgba(0,0,0,.2)',
            color: "#fff"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#fff'
        })
    }

    const handleSelectTabInLottery = (lotteryId, index) => {
        console.log({
            lotteryId, index
        })
        handleTicketRow(lotteryId);
        setLotteryTabIndex(index)
    }

    useEffect(() => {
        setItemOffset(0);
    }, [SelectedTabs]);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading Tickets from ${itemOffset} to ${endOffset}`);
        if (SelectedTabs === 0) {
            setPageTicketRow(TicketsRow.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(TicketsRow.length / itemsPerPage));
        }
        else if (SelectedTabs === 1) {
            setPageTicketRow(TicketsRowUp.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(TicketsRowUp.length / itemsPerPage));
        }
        else if (SelectedTabs === 2) {
            console.log("win");
            setPageTicketRow(TicketsRowWin.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(TicketsRowWin.length / itemsPerPage));
        }
        else if (SelectedTabs === 3) {
            setPageTicketRow(TicketsRowLoss.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(TicketsRowLoss.length / itemsPerPage));
        }
    }, [itemOffset, itemsPerPage, SelectedTabs]);

    const handlePageClick = (event, tabNo) => {
        let newOffset = 0;
        if (SelectedTabs === tabNo) {
            newOffset = (event.selected * itemsPerPage) % TicketsRow.length;
        }
        else if (SelectedTabs === tabNo) {
            newOffset = (event.selected * itemsPerPage) % TicketsRowUp.length;
        }
        else if (SelectedTabs === tabNo) {
            newOffset = (event.selected * itemsPerPage) % TicketsRowWin.length;
        }
        else if (SelectedTabs === tabNo) {
            newOffset = (event.selected * itemsPerPage) % TicketsRowLoss.length;
        }
        // console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        // setSelectedTabs(tabNo);
        setItemOffset(newOffset);
    };





    let token = "";
    let history = useHistory();
    const handleCheckUser = (typeCall) => {
        const allCookie = document.cookie;
        // let token = "";
        if (allCookie.search("token") > -1) {
            token = document.cookie
                .split('; ')
                .find(row => row.startsWith('token='))
                .split('=')[1];
            return true;
        }
        if (typeCall !== "check") {
            history.push('/login')
        }
        return false;
    }

    const handleClaimPrize = (index, customId) => {

        console.log({
            wallet: appDetail.wallet.address,
            index,
            id: customId || appDetail.lottery.currentId
        });

        getUserClaimPrize(appDetail.wallet.address, index, customId || appDetail.lottery.currentId).then(res => {
            console.log(res);
            toast.success("Congratulations!")
        }).catch((err) => {
            console.log({
                status: 'error',
                message: err
            })
        })
    }

    const handleGetAllLotteries = () => {
        const helpArr = [];
        for (let i = appDetail.lottery.currentId; i > 0; i--) {
            helpArr.push(i)
        }
        setLotteriesArr(helpArr)
        console.log((helpArr.length).toString())
        setLotteryTabIndex((helpArr.length).toString())
    }

    const handleTicketRow = async (customId) => {
        // console.log("token")
        var allTicket = [];
        const helpArr = [];

        setLoadingDataTicket(true);
        getLotteryPlayerTicket(appDetail.wallet.address, customId || appDetail.lottery.currentId).then(async (res) => {
            // console.log(res)
            var tickets = res;
            var upcoming = [];
            var win = [];
            var loss = [];
            const promises = [handleGetLotteryNumbers(customId)];

            for (let i = 0; i < tickets.length; i++) {
                const ticket = tickets[i];
                // console.log(i)
                allTicket.push(ticket);
                // console.log(appDetail.wallet)
                const promise = await checkWinningPrize(appDetail.wallet.address, i, customId || appDetail.lottery.currentId)
                    .then((resTicket) => {
                        // 1 = match count with lottery num 
                        // 2 = user(ticket) count with lottery num
                        // 3 = claimable 
                        // 4 = claim prize (bool)
                        helpArr.push(resTicket);
                    })
                    .catch((err) => {
                        console.log({ LottoValue: "Error", err });
                    });
                promises.push(promise);

                // if(ticket.lottery_id !== null){
                //     if(!ticket.lottery_id.has_ended){
                //         upcoming.push(ticket)
                //     }
                //     if(ticket.prize > 0){
                //         win.push(ticket);
                //     }
                //     if(ticket.prize == 0){
                //         loss.push(ticket);
                //     }
                // }
            }

            Promise.all(promises)
                .then(() => {
                    console.log({allTicket , helpArr})
                    setUserTicketDetail(helpArr);
                    setLoadingDataTicket(false);
                })
                .catch((err) => {
                    console.log({ LottoValue: "Error", err });
                    // setLoadingDataTicket(false);
                });
            // console.log(res.data.data);
            setTicketsRow(allTicket);
            setPageTicketRow(allTicket);
            setTicketsRowUp(upcoming);
            setTicketsRowWin(win);
            setTicketsRowLoss(loss);
            // console.log(win.length)
            setitemsPerPage(10);
        }).catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }
        });
    }

    const resChangeTabs = (e) => {
        var index = SelectOptions.indexOf(e);
        document.getElementsByClassName("HPN-sortByTabsCard")[index].click();
        // document.getElementsByClassName("HPN-sortByTabsCard")[e.target.selectedIndex].click();
    }

    const handleGetLotteryNumbers = (customId) => {
        const promises = [];
        const helpArr = [];
        getLotteryNumbers(customId || appDetail.lottery.currentId)
            .then((res) => {
                helpArr.push(res);
                setLotteryNumberArr(res)
            })
            .catch((err) => {
                console.log({ LottoValue: "Error", err });
            });

        // getLotteryNumbers().then(res => {
        //     console.log(res)
        // }).catch(err => {
        //     console.log(err)

        // })
    }

    const handleCloseModal = () => {
        setShowWinningModal({
            show: false,
            loading: false,
            data: {},

        })
    }

    const handleGotoReceiptInRow = (ticket) => {
        console.log(ticket);
        history.push('/receipt', [{
            aboutProps: {
                ticketReceipt: ticket
            }
        }]
        )
    }

    useEffect(() => {
        // handleCheckUser("check");
        if (appDetail.lottery.currentId !== -1) {
            handleGetLotteryNumbers();
            if (appDetail.wallet.active) {
                handleGetAllLotteries();
                handleTicketRow();
            }
        }
    }, [appDetail])



    return (
        <>
            <Helmet>
                <title>My Tickets</title>
            </Helmet>

            <CustomModal
                show={ShowWinningModal.show}
                toggleModal={handleCloseModal}
            >

                <div className="congratulationsModalBox">
                    <div className="congratulationsTitle">
                        Congratulations
                    </div>
                    <div className="congratulationsText">
                        You Won 1 ETH
                    </div>
                    <div className="btnTemp1 mt-3" onClick={handleCloseModal}>
                        Close
                    </div>
                </div>

            </CustomModal>

            <div className="walletBalance">
                <div className="walletBalanceTitle">Wallet Balance</div>
                <div className="walletBalanceVal">
                    <div>{appDetail.wallet.balance}</div>
                    <div>{appDetail.siteCoin.coin}</div>
                </div>
            </div>

            <Tabs
                defaultIndex={LotteryTabIndex}
                onSelect={(index, lastIndex) => handleSelectTabInLottery(LotteriesArr[index], index)}
            >
                <TabList>
                    {LotteriesArr.map((lottery, index) =>
                        <>
                            <Tab
                                key={index}
                            // isSelected={index === index} // Highlight the selected tab
                            >{`lottery ${lottery}`}</Tab>
                        </>
                    )}
                </TabList>

                {LotteriesArr.map((lottery, index) =>
                    <>
                        <TabPanel>
                            {LoadingDataTicket ?
                                <>
                                    <div className="d-flex justify-content-center mt-3">
                                        <LoadingData />
                                    </div>
                                </>
                                :
                                <>
                                    <table className="siteTable myTicketTable">
                                        <tr>
                                            {/* <th>Lottery Date</th> */}
                                            <th>Your Ticket</th>
                                            <th>Result</th>
                                            {/* <th>Action</th> */}
                                            <th>Prize</th>
                                            <th>Claim</th>
                                        </tr>
                                        <tbody>

                                            {PageTicketRow.map((ticket, index) =>
                                                <tr
                                                    className="siteTableTr"
                                                // onClick={() => handleGotoReceiptInRow(ticket)}
                                                >
                                                    {/* <td>
                                                    {(() =>{
                                                        let endLottery = ticket.lottery_id.end_date;

                                                        var result = new Date(endLottery);
                                                        var newDate = moment(result).format("YYYY-MM-DD");

                                                        return newDate
                                                    })()}
                                                </td> */}
                                                    <td>
                                                        {/* <NavLink to={{
                                                            pathname: "/receipt",
                                                            aboutProps: {
                                                                ticketReceipt: ticket
                                                            }
                                                        }}> */}

                                                        <div className="ticketTemp">
                                                            <div className="ticketTemp5No">
                                                                {/* ${ticket.lottery_id.numbers.indexOf(Number) >-1  ? "active" : ""} */}
                                                                {ticket.map((Number, i) =>
                                                                    <div className={`ticketTemp5NoCard `}>{Number}</div>
                                                                )}

                                                            </div>
                                                            {/* <div className="ticketTemp2No">
                                                                {ticket.jokers.map((Number,i) =>
                                                                    <div className={`ticketTemp2NoCard ${ticket.lottery_id.jokers.indexOf(Number) >-1 ? "active" : ""}`}>{Number}</div>
                                                                )}

                                                            </div> */}
                                                        </div>

                                                        {/* </NavLink> */}

                                                    </td>
                                                    <td>
                                                        <div className="ticketTemp ticketTemp1">
                                                            <div className="ticketTemp ticketTemp1-5No">
                                                                {Array.from(Array(5), (e, i) => {
                                                                    return <div className="ticketTemp1-5NoCard">{LotteryNumberArr[i]}</div>
                                                                })}


                                                            </div>
                                                            {/* <div className="ticketTemp ticketTemp1-2No">
                                                                {Array.from(Array(2), (e, i) => {
                                                                    return <div className="ticketTemp1-2NoCard">{ticket.lottery_id.jokers[i]}</div>
                                                                })}
                                                            </div> */}
                                                        </div>
                                                    </td>
                                                    {/*
                                                        <td>
                                                            {ticket.lottery_id.prize>0?
                                                                <div className="addToWalletTicket">
                                                                    Add to Wallet
                                                                </div>
                                                                :
                                                                null
                                                            }

                                                        </td> */}
                                                    <td>
                                                        {convertWeiToEth(UserTicketDetail?.[index]?.["2"] || 0)}

                                                        {/* {!ticket.lottery_id.has_ended ? <span>Hold On</span> :
                                                        <>
                                                            {ticket.prize == 0 || ticket.prize > 0 ?
                                                                <>
                                                                    <span className="myTicketPrizeNo">{ticket.prize.toFixed(2)}</span>
                                                                    <span>XBit</span>
                                                                </>
                                                                :null}
                                                        </>
                                                    } */}

                                                    </td>
                                                    <td>
                                                        <div className={
                                                            "btnTemp1" +
                                                            ((UserTicketDetail?.[index]?.["2"] > 0 && !UserTicketDetail?.[index]?.["3"]) ? "" : " disable")
                                                        }
                                                            onClick={() => (UserTicketDetail?.[index]?.["2"] > 0 && !UserTicketDetail?.[index]?.["3"]) ? handleClaimPrize(index, lottery) : null}
                                                        >
                                                            Claim
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </>
                            }
                        </TabPanel>
                    </>
                )}
            </Tabs>


            {(PageTicketRow.length == 0 && !LoadingDataTicket) ?
                <div className="emptyContent">There Is No Ticket</div>

                : null}

            {LoadingDataTicket ?
                <>
                    {/* <div className="d-flex justify-content-center mt-3">
                        <LoadingData />
                    </div> */}
                </>
            :
                <>
                    {PageTicketRow.map((ticket, index) =>
                        <NavLink to={{
                            pathname: "/receipt",
                            aboutProps: {
                                ticketReceipt: ticket
                            }
                        }}>
                            <div className="resMyticketCard">
                                <div className="RMTC-detail">
                                    {/* <div className="RMTC-detailPrize">
                                        {!ticket.lottery_id.has_ended ? <span>Hold On</span> :
                                            <>
                                                {ticket.prize == 0 || ticket.prize > 0 ?
                                                    <>
                                                        <span className="myTicketPrizeNo">{ticket.prize.toFixed(2)}</span>
                                                        <span>XBit</span>
                                                    </>
                                                    :null}
                                            </>
                                        }
                                    </div> */}

                                    {/* <div className="RMTC-detailTime">
                                        {(() =>{
                                            let endLottery = ticket.lottery_id.end_date;

                                            var result = new Date(endLottery);
                                            var newDate = moment(result).format("YYYY-MM-DD");

                                            return newDate
                                        })()}</div> */}

                                </div>
                                <div className="RMTC-ticket">
                                    <div className="RMTC-myTicket">
                                        <div className="ticketTemp">
                                            <div className="ticketTemp5No">
                                                {/* ${ticket.lottery_id.numbers.indexOf(Number) >-1 ? "active" : ""} */}
                                                {ticket.map((Number, i) =>
                                                    <div className={`ticketTemp5NoCard `}>{Number}</div>
                                                )}

                                            </div>
                                            {/* <div className="ticketTemp2No">
                                                {ticket.jokers.map((Number,i) =>
                                                    <div className={`ticketTemp2NoCard ${ticket.lottery_id.jokers.indexOf(Number) >-1 ? "active" : ""}`}>{Number}</div>
                                                )}

                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="RMTC-resultTicket">
                                        <div className="ticketTemp ticketTemp1">
                                            <div className="ticketTemp ticketTemp1-5No">
                                                {Array.from(Array(5), (e, i) => {
                                                    return <div className="ticketTemp1-5NoCard">{ticket.lottery_id.numbers[i]}</div>
                                                })}


                                            </div>
                                            <div className="ticketTemp ticketTemp1-2No">
                                                {Array.from(Array(2), (e, i) => {
                                                    return <div className="ticketTemp1-2NoCard">{ticket.lottery_id.jokers[i]}</div>
                                                })}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </NavLink>
                    )}
                </>
            }


            {!LoadingDataTicket &&
                <>
                    {/* <ReactPaginate
                        pageClassName="sitePaginationCard"
                        containerClassName="sitePagination"
                        activeClassName="active"
                        breakLabel="..."
                        nextLabel=""
                        onPageChange={(event) => handlePageClick(event, 0)}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel=""
                        renderOnZeroPageCount={null}

                    /> */}
                </>
            }



        </>
    );
};

export default MyTicket;
