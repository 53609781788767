import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {apiUrl} from '../../utils/config';
import { toast } from "react-toastify";
import { useHistory, useLocation } from 'react-router';
import Cookies from "universal-cookie";

// import {githubAuth} from '../../utils/loginwithCounos';
import { NavLink } from 'react-router-dom';
import githubAuth from '../../utils/loginwithCounos';
const cookies = new Cookies();

const LoginWithOtherWayPage = () => {

    const [ErrorMode,setErrorMode] = useState(false);

    const history = useHistory();

    const loca = useLocation();

    // const callbackVal = loca.search;
    const callbackVal = window.location.href;

    console.log();

    const sendTokenCallBack = (accessToken) =>{

        axios({
            url:apiUrl + '/auth/callback',
            method:'post',
            data:{token:accessToken}
        }).then((res) =>{
            toast.success('Login Successfully!')
            document.cookie = `token=${res.headers['x-auth-token']}`;

            cookies.remove("randomNumber");
            if(cookies.get('t')){
                history.push('/buyTicket');
            }else{
                history.push('/');
            }
            // window.location.href = "/";
            console.log(res.data)
        }).catch((error) => {

            setErrorMode(true)
            // if(error.response.status === 403){
            //     document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            //     Window.location.href = '/login'
            // }else{
            //     toast.error(`${error.response.data.message}`)
            // }
        })
    }




    useEffect(() => {

        if(loca.search.indexOf('error')>-1){
            toast.error("Error From Counos");
            setErrorMode(true);
            history.push('/');
        }

        githubAuth.code.getToken(callbackVal)
            .then(function (user) {
                console.log(user) //=> { accessToken: '...', tokenType: 'bearer', ... }
                console.log("bbbb");

                // Refresh the current users access token.
                // user.refresh().then(function (updatedUser) {
                //     console.log(updatedUser !== user) //=> true
                //     console.log(updatedUser.accessToken)
                // })
                // Sign API requests on behalf of the current user.
                console.log("adssad");
                user.sign({
                    method: 'get',
                    url: 'https://counos.com/connect/authorize'
                });
                sendTokenCallBack(user.accessToken);
                console.log(user)
                // We should store the token into a database.
                // return res.send(user.accessToken)
            }).catch(function (err) {
            console.log(err);
        })
        // axios({
        //     url:apiUrl + '/auth/login?code=' + callbackVal,
        //     method:'get',
        // }).then((res) =>{
        //     console.log(res.data)
        // }).catch((error) => {
        //     if(error.response.status === 403){
        //         document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //         Window.location.href = '/login'
        //     }else{
        //         toast.error(`${error.response.data.message}`)
        //     }
        // })
    },[])

    return ( 
        <>

            {ErrorMode ? 

                <>

                    <div className='simpleTitle'>
                        Make sure you have registered with the same email address you used for Counos.
                        If you haven’t done so, please register with the same email.           
                    </div>

                    <div className='simpleTitle'>
                        This feature is to increase the speed and ease with which you can login after registration.
                    </div>

                    <NavLink to={'/login'}>
                        <div className="btnTemp1 otherPageBtn"> Go To Login</div>
                    </NavLink>

                </>
            
            
            :
                <div className='simpleTitle'>
                    Please Wait To Login ...                
                </div>
            }

        </>
     );
}
 
export default LoginWithOtherWayPage;