import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import $ from "jquery";
import { apiUrl } from "../../utils/config";
import ConnectWallet from "../connectWallet";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import { updateActiveBalance } from "../../utils/contractWeb3";


const HeaderSite = () => {

    const userWallet = useSelector(store => store.app.wallet);
    const appDetail = useSelector(store => store.app);

    const cookies = new Cookies();

    let token = "";
    // const location = useLocation();
    let history = useHistory();

    const sideNavBox = useRef(null);
    const modalTemp = useRef(null);
    const headerBox = useRef(null);

    const [UserInfo, setUserInfo] = useState({});
    const [UserSymbol, setUserSymbol] = useState('');
    const [UserWalletVal, setUserWalletVal] = useState(0);

    const [ShowDisconnectWalletModal, setShowDisconnectWalletModal] = useState(false);
    const [ConnectWalletModal, setConnectWalletModal] = useState(false)

    const { active, account } = useWeb3React();


    const handleCheckUser = (typeCall) => {
        const allCookie = document.cookie;
        // let token = "";
        if (allCookie.search("token") > -1) {
            token = document.cookie
                .split('; ')
                .find(row => row.startsWith('token='))
                .split('=')[1];
            return true;
        }
        if (typeCall !== "check") {
            history.push('/login')
        }
        return false;
    }


    const handleOpenSideNav = (index = -1) => {

        if(sideNavBox.current){

            if (window.innerWidth < 480) {
                sideNavBox.current.style.width = '300px';
            } else {
                sideNavBox.current.style.width = '350px';
            }
            console.log("object");
            // headerBox.current.style.filter = 'blur(5px)';
            // MainContainerBox.current.style.filter = 'blur(5px)';
            // footerBox.current.style.filter = 'blur(5px)';
            sideNavBox.current.style.visibility = 'visible';
        }


    }

    const handleCloseSideNav = () => {
        if(sideNavBox.current){
            sideNavBox.current.style.width = 0;
            // headerBox.current.style.filter = 'unset';
            // MainContainerBox.current.style.filter = 'unset';
            // footerBox.current.style.filter = 'unset';
            sideNavBox.current.style.visibility = 'hidden';
        }
    }

    const handleOpenLogout = () => {
        modalTemp.current.style.opacity = 1;
        modalTemp.current.style.visibility = 'visible';
        if (window.innerWidth < 1000) {
            handleCloseSideNav();
        }

    }

    const handleCloseLogout = () => {
        modalTemp.current.style.opacity = 0;
        modalTemp.current.style.visibility = 'hidden';

    }

    const getInfoUser = () => {
        axios({
            url: apiUrl + "/client",
            method: 'get',
            headers: {
                'x-auth-token': token,
            },
        }).then((response) => {
            var user = response.data.data;
            setUserInfo(user);
            setUserSymbol(user.firstname.charAt(0) + user.surname.charAt(0));
            setUserWalletVal(user.wallet.toFixed(2))
        }).catch((error) => {
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                window.location.href = '/login'
            }
        });
    }

    const handleLogoutUser = () => {
        cookies.remove('token');
        history.push('/');
        handleCloseSideNav();
        toast.success("Logout Successfully");
        handleCloseLogout();
    }

    useEffect(() => {
        if (handleCheckUser('check')) {

            getInfoUser()
        }
        $(document).on('click', function (e) {
            if (!(($(e.target).closest("#mySidenav").length > 0) || ($(e.target).closest(".resMenubtn").length > 0))) {
                handleCloseSideNav()
            }
        });
    }, []);

    useEffect(() => {
        if(appDetail.wallet.active){
            updateActiveBalance();
        }
    }, [appDetail.wallet.active]);

    return (
        <>

            <div id="mySidenav" class="sidenav" ref={sideNavBox}>
                {/* <a href="javascript:void(0)" class="closebtn" onClick={handleCloseSideNav}>&times;</a> */}
                <div className='mainSideBox'>

                    <div className="topOfSideBar">

                    </div>

                    <div className='sideNavBody'>

                        <NavLink to="/" exact activeClassName={"navMenuCardActive"} onClick={handleCloseSideNav}>
                            <div className="navMenuCard">
                                <img src="/assets/image/resHome.svg" alt="" />
                                Home
                            </div>
                        </NavLink>

                        <NavLink to="/myTicket" exact activeClassName={"navMenuCardActive"} onClick={handleCloseSideNav}>
                            <div className="navMenuCard">
                                <img src="/assets/image/ResTICKET.svg" alt="" />
                                My Ticket
                            </div>
                        </NavLink>

                        <NavLink to="/buyTicket" exact activeClassName={"navMenuCardActive"} onClick={handleCloseSideNav}>
                            <div className="navMenuCard">
                                <img src="/assets/image/ResPlus.svg" alt="" />
                                Buy Ticket
                            </div>
                        </NavLink>

                        <NavLink to="/white_paper" exact activeClassName={"navMenuCardActive"} onClick={handleCloseSideNav}>
                            <div className="navMenuCard">
                                <img src="/assets/image/aboutUs.svg" alt="" />
                                White paper
                            </div>
                        </NavLink>

                        {/* <NavLink to="/white_paper" className="">
                            <li>White paper</li>
                        </NavLink> */}




                    </div>

                    <div className="sideCloseBtn" onClick={handleCloseSideNav}>Close</div>

                </div>


            </div>





            <div className="header" >

                <div className="resEmptyBox"></div>

                <div className="header_items">
                    <ul>
                        {/* <NavLink to="/support" className="">
                  <li>Support</li>
                </NavLink>
                <NavLink to="/terms_of_use" className="">
                  <li>Rules</li>
                </NavLink>
                <NavLink to="/aboutUs" className="">
                  <li>About us</li>
                </NavLink> */}
                        {/* <NavLink to="/crash" className="">
                            <li>Crash Account</li>
                        </NavLink>

                        <NavLink to="/withdrawal" className="">
                            <li>Last Withdrawals</li>
                        </NavLink> */}

                        <NavLink to="/white_paper" className="">
                            <li>White paper</li>
                        </NavLink>
                        {/* <NavLink to="/Work_with_us" className="">
                  <li>Work with us</li>
                </NavLink> */}


                    </ul>
                </div>

                {/* <NavLink to="/">
          <div className="logo">
            <img src={`${handleShowImage('headerLogo')}`} alt="logo" />
            <div className="siteName">XBIT LOTTO</div>
          </div>
        </NavLink> */}

                <div className="resMenubtn" onClick={handleOpenSideNav}>
                    <img src="/assets/image/sideNavbtn.svg" alt="" />
                </div>

                <ConnectWallet
                    showConnectModal={ConnectWalletModal}
                    changeConnectModalData={setConnectWalletModal}
                    showDisconnectModal={ShowDisconnectWalletModal}
                    changeDisconnectModalData={setShowDisconnectWalletModal}
                />



            </div>

            <div className="headerLink">

                <div className="items">

                    <NavLink to="/" exact activeClassName={"navMenuCardActive"}>
                        <div className="navMenuCard">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24.965" height="24.965" viewBox="0 0 24.965 24.965">
                                <g id="dashboard" transform="translate(-22.8 -22.8)">
                                    <path id="Path_36" data-name="Path 36" d="M117.811,35.913c-.081.016-.065.1-.081.146a1.8,1.8,0,0,1-1.746,1.31c-2.6.016-5.207.016-7.794,0a1.789,1.789,0,0,1-1.827-1.843c-.016-3.622,0-7.228,0-10.85a1.806,1.806,0,0,1,1.31-1.795c.049-.016.113,0,.146-.081h8.44l.049.049a1.708,1.708,0,0,1,1.326,1,4.024,4.024,0,0,1,.178.469Zm-1.552-5.821v-5.32c0-.356-.065-.42-.4-.42h-7.5c-.34,0-.42.065-.42.4V35.38c0,.323.081.4.4.4h7.535c.323,0,.4-.081.4-.4C116.258,33.633,116.258,31.855,116.258,30.092Z" transform="translate(-70.045 0)" fill="#a5a5a5" />
                                    <path id="Path_37" data-name="Path 37" d="M117.785,132.493c-.081.049-.065.146-.1.21a1.8,1.8,0,0,1-1.682,1.245c-2.619,0-5.255.016-7.874,0a1.8,1.8,0,0,1-1.795-1.811q-.024-2.353,0-4.705a1.778,1.778,0,0,1,1.827-1.795c2.6-.016,5.207-.016,7.794,0a1.8,1.8,0,0,1,1.779,1.423c.016.032.016.065.049.065Zm-5.724-.1h3.751c.356,0,.42-.065.42-.4V127.61c0-.34-.065-.4-.42-.4h-7.486c-.356,0-.437.065-.437.42v4.366c0,.356.065.42.437.42C109.571,132.4,110.816,132.4,112.061,132.4Z" transform="translate(-70.02 -86.199)" fill="#a5a5a5" />
                                    <path id="Path_38" data-name="Path 38" d="M32.7,22.8c.065.1.178.049.259.081a1.8,1.8,0,0,1,1.277,1.746c.016,1.552.016,3.121,0,4.673A1.815,1.815,0,0,1,32.4,31.127c-2.6.016-5.207.016-7.794,0A1.79,1.79,0,0,1,22.8,29.268V24.692a1.812,1.812,0,0,1,1.455-1.843c.016,0,.032-.032.049-.049Zm-4.139,1.552H24.773c-.356,0-.42.065-.42.4v4.382c0,.34.065.42.4.42h7.5c.323,0,.4-.081.4-.4V24.74c0-.323-.081-.4-.4-.4C31.046,24.352,29.8,24.352,28.556,24.352Z" fill="#a5a5a5" />
                                    <path id="Path_39" data-name="Path 39" d="M22.8,94.409V89.04a1.833,1.833,0,0,1,1.94-1.94h7.583a1.825,1.825,0,0,1,1.924,1.924v10.7a1.833,1.833,0,0,1-1.94,1.94H24.756a1.821,1.821,0,0,1-1.94-1.956C22.8,97.95,22.8,96.171,22.8,94.409Zm1.552-.032V99.7c0,.356.065.4.4.4h7.5c.34,0,.4-.065.4-.42V89.073c0-.356-.065-.42-.42-.42H24.756c-.34,0-.42.065-.42.4C24.352,90.835,24.352,92.614,24.352,94.376Z" transform="translate(0 -53.903)" fill="#a5a5a5" />
                                </g>

                            </svg>

                            {/*<img src="assets/image/dashboard.svg" />*/}
                            HOME
                        </div>
                    </NavLink>

                    {/* <NavLink to="/withdrawal" activeClassName={"navMenuCardActive"}>
            <div className="navMenuCard">

                <svg xmlns="http://www.w3.org/2000/svg" width="24.965" height="24.965" viewBox="0 0 24.965 24.965">
                    <g id="dashboard" transform="translate(-22.8 -22.8)">
                        <path id="Path_36" data-name="Path 36" d="M117.811,35.913c-.081.016-.065.1-.081.146a1.8,1.8,0,0,1-1.746,1.31c-2.6.016-5.207.016-7.794,0a1.789,1.789,0,0,1-1.827-1.843c-.016-3.622,0-7.228,0-10.85a1.806,1.806,0,0,1,1.31-1.795c.049-.016.113,0,.146-.081h8.44l.049.049a1.708,1.708,0,0,1,1.326,1,4.024,4.024,0,0,1,.178.469Zm-1.552-5.821v-5.32c0-.356-.065-.42-.4-.42h-7.5c-.34,0-.42.065-.42.4V35.38c0,.323.081.4.4.4h7.535c.323,0,.4-.081.4-.4C116.258,33.633,116.258,31.855,116.258,30.092Z" transform="translate(-70.045 0)" fill="#a5a5a5"/>
                        <path id="Path_37" data-name="Path 37" d="M117.785,132.493c-.081.049-.065.146-.1.21a1.8,1.8,0,0,1-1.682,1.245c-2.619,0-5.255.016-7.874,0a1.8,1.8,0,0,1-1.795-1.811q-.024-2.353,0-4.705a1.778,1.778,0,0,1,1.827-1.795c2.6-.016,5.207-.016,7.794,0a1.8,1.8,0,0,1,1.779,1.423c.016.032.016.065.049.065Zm-5.724-.1h3.751c.356,0,.42-.065.42-.4V127.61c0-.34-.065-.4-.42-.4h-7.486c-.356,0-.437.065-.437.42v4.366c0,.356.065.42.437.42C109.571,132.4,110.816,132.4,112.061,132.4Z" transform="translate(-70.02 -86.199)" fill="#a5a5a5"/>
                        <path id="Path_38" data-name="Path 38" d="M32.7,22.8c.065.1.178.049.259.081a1.8,1.8,0,0,1,1.277,1.746c.016,1.552.016,3.121,0,4.673A1.815,1.815,0,0,1,32.4,31.127c-2.6.016-5.207.016-7.794,0A1.79,1.79,0,0,1,22.8,29.268V24.692a1.812,1.812,0,0,1,1.455-1.843c.016,0,.032-.032.049-.049Zm-4.139,1.552H24.773c-.356,0-.42.065-.42.4v4.382c0,.34.065.42.4.42h7.5c.323,0,.4-.081.4-.4V24.74c0-.323-.081-.4-.4-.4C31.046,24.352,29.8,24.352,28.556,24.352Z" fill="#a5a5a5"/>
                        <path id="Path_39" data-name="Path 39" d="M22.8,94.409V89.04a1.833,1.833,0,0,1,1.94-1.94h7.583a1.825,1.825,0,0,1,1.924,1.924v10.7a1.833,1.833,0,0,1-1.94,1.94H24.756a1.821,1.821,0,0,1-1.94-1.956C22.8,97.95,22.8,96.171,22.8,94.409Zm1.552-.032V99.7c0,.356.065.4.4.4h7.5c.34,0,.4-.065.4-.42V89.073c0-.356-.065-.42-.42-.42H24.756c-.34,0-.42.065-.42.4C24.352,90.835,24.352,92.614,24.352,94.376Z" transform="translate(0 -53.903)" fill="#a5a5a5"/>
                    </g>
                    
                </svg>

                <img src="assets/image/dashboard.svg" />
                WITHDRAWAL
            </div>
          </NavLink> */}

                    {/* <NavLink to="/lottery_explore" activeClassName={"navMenuCardActive"}>
            <div className="navMenuCard">

                <svg xmlns="http://www.w3.org/2000/svg" width="24.965" height="24.965" viewBox="0 0 24.965 24.965">
                    <g id="dashboard" transform="translate(-22.8 -22.8)">
                        <path id="Path_36" data-name="Path 36" d="M117.811,35.913c-.081.016-.065.1-.081.146a1.8,1.8,0,0,1-1.746,1.31c-2.6.016-5.207.016-7.794,0a1.789,1.789,0,0,1-1.827-1.843c-.016-3.622,0-7.228,0-10.85a1.806,1.806,0,0,1,1.31-1.795c.049-.016.113,0,.146-.081h8.44l.049.049a1.708,1.708,0,0,1,1.326,1,4.024,4.024,0,0,1,.178.469Zm-1.552-5.821v-5.32c0-.356-.065-.42-.4-.42h-7.5c-.34,0-.42.065-.42.4V35.38c0,.323.081.4.4.4h7.535c.323,0,.4-.081.4-.4C116.258,33.633,116.258,31.855,116.258,30.092Z" transform="translate(-70.045 0)" fill="#a5a5a5"/>
                        <path id="Path_37" data-name="Path 37" d="M117.785,132.493c-.081.049-.065.146-.1.21a1.8,1.8,0,0,1-1.682,1.245c-2.619,0-5.255.016-7.874,0a1.8,1.8,0,0,1-1.795-1.811q-.024-2.353,0-4.705a1.778,1.778,0,0,1,1.827-1.795c2.6-.016,5.207-.016,7.794,0a1.8,1.8,0,0,1,1.779,1.423c.016.032.016.065.049.065Zm-5.724-.1h3.751c.356,0,.42-.065.42-.4V127.61c0-.34-.065-.4-.42-.4h-7.486c-.356,0-.437.065-.437.42v4.366c0,.356.065.42.437.42C109.571,132.4,110.816,132.4,112.061,132.4Z" transform="translate(-70.02 -86.199)" fill="#a5a5a5"/>
                        <path id="Path_38" data-name="Path 38" d="M32.7,22.8c.065.1.178.049.259.081a1.8,1.8,0,0,1,1.277,1.746c.016,1.552.016,3.121,0,4.673A1.815,1.815,0,0,1,32.4,31.127c-2.6.016-5.207.016-7.794,0A1.79,1.79,0,0,1,22.8,29.268V24.692a1.812,1.812,0,0,1,1.455-1.843c.016,0,.032-.032.049-.049Zm-4.139,1.552H24.773c-.356,0-.42.065-.42.4v4.382c0,.34.065.42.4.42h7.5c.323,0,.4-.081.4-.4V24.74c0-.323-.081-.4-.4-.4C31.046,24.352,29.8,24.352,28.556,24.352Z" fill="#a5a5a5"/>
                        <path id="Path_39" data-name="Path 39" d="M22.8,94.409V89.04a1.833,1.833,0,0,1,1.94-1.94h7.583a1.825,1.825,0,0,1,1.924,1.924v10.7a1.833,1.833,0,0,1-1.94,1.94H24.756a1.821,1.821,0,0,1-1.94-1.956C22.8,97.95,22.8,96.171,22.8,94.409Zm1.552-.032V99.7c0,.356.065.4.4.4h7.5c.34,0,.4-.065.4-.42V89.073c0-.356-.065-.42-.42-.42H24.756c-.34,0-.42.065-.42.4C24.352,90.835,24.352,92.614,24.352,94.376Z" transform="translate(0 -53.903)" fill="#a5a5a5"/>
                    </g>
                    
                </svg>

                <img src="assets/image/dashboard.svg" />
                WINNERS
            </div>
          </NavLink> */}

                    <NavLink to="/myTicket" activeClassName={"navMenuCardActive"}>
                        <div className="navMenuCard">

                            <svg xmlns="http://www.w3.org/2000/svg" width="41.997" height="41.997" viewBox="0 0 41.997 41.997">
                                <g id="TICKET" transform="translate(20.949 -46.316) rotate(45)">
                                    <g id="Group_69" data-name="Group 69" transform="translate(32.8 32.7)">
                                        <path id="Path_40" data-name="Path 40" d="M61.638,40.8l-1.191-1.191-.6-.6a.794.794,0,0,0-.927-.221,1.867,1.867,0,0,1-2.14-.375,1.972,1.972,0,0,1-.441-2.162,1.144,1.144,0,0,0-.088-.794c-.243-.243-.485-.507-.728-.772A6.989,6.989,0,0,0,53.1,32.7H52.018c-.2.132-.4.265-.574.4a8.94,8.94,0,0,0-1.258.949c-5.362,5.34-10.767,10.723-16.041,16.063a8.941,8.941,0,0,0-.949,1.258c-.132.2-.265.4-.4.574v1.081c.066.11.132.221.2.353a3.831,3.831,0,0,0,.485.772c.441.485.927.949,1.39,1.412.2.2.4.375.6.574a.825.825,0,0,0,.949.177,2.035,2.035,0,0,1,.794-.154,1.775,1.775,0,0,1,1.28.552,1.8,1.8,0,0,1,.419,2.118.866.866,0,0,0,.243,1.037c.353.353.728.706,1.081,1.059.177.177.353.375.552.552a2.516,2.516,0,0,0,3.927.022l4.1-4.1,4.634-4.634c2.736-2.736,5.472-5.45,8.186-8.186A2.564,2.564,0,0,0,61.638,40.8ZM46.48,57.434l-.11-.066c-.11-.066-.243-.11-.353-.177a6.834,6.834,0,0,0-.993-.463h0a.979.979,0,0,0-.353.243c-.287.287-.309.53.574,1.522l.11.11-.11.11c-.177.2-.375.4-.552.6-.441.485-.9.971-1.39,1.412a.884.884,0,0,1-.574.243,1.084,1.084,0,0,1-.772-.353l-.022-.022c-.441-.419-.883-.861-1.3-1.324a.9.9,0,0,1-.2-.706A3.383,3.383,0,0,0,36.551,54.7c-.265.044-.441.022-.53-.088l-.243-.243a13.26,13.26,0,0,1-1.412-1.522,1.06,1.06,0,0,1,.11-1.1c.419-.485.9-.949,1.368-1.39.177-.177.353-.331.53-.507l.11-.11.11.088c1.147.9,1.346.883,1.655.552.309-.309.331-.53-.53-1.545l-.088-.11L48.841,37.532l8.738,8.76ZM60.734,43.313a15.33,15.33,0,0,1-1.3,1.368c-.177.154-.331.331-.485.485l-.11.11-8.892-8.914.11-.11.507-.507c.419-.441.861-.9,1.346-1.324a.98.98,0,0,1,1.39.044c.552.507.971.949,1.368,1.39a.869.869,0,0,1,.177.662,3.344,3.344,0,0,0,.949,2.935A3.385,3.385,0,0,0,58.7,40.4c.2-.022.441-.044.574.11l.243.243a11.887,11.887,0,0,1,1.346,1.434A1.169,1.169,0,0,1,60.734,43.313Z" transform="translate(-32.8 -32.7)" fill="#a5a5a5" />
                                    </g>
                                    <g id="Group_70" data-name="Group 70" transform="translate(43.761 43.839)">
                                        <path id="Path_41" data-name="Path 41" d="M90.755,87.292a1.87,1.87,0,0,0-.662-.463c-.949-.485-1.3-1.125-1.037-1.92a2.017,2.017,0,0,1,.022-.375,1.479,1.479,0,0,0-.022-.552c-.177-.53-.353-.706-.463-.772a1.15,1.15,0,0,0-.794.154,4.694,4.694,0,0,0-.706.507c-.2.154-.4.309-.6.441a1.121,1.121,0,0,1-.794.132,5.894,5.894,0,0,1-.883-.287c-.265-.088-.507-.177-.772-.243a.7.7,0,0,0-.927.883,3.408,3.408,0,0,0,.154.507,2.221,2.221,0,0,1-.441,3,.782.782,0,0,0-.221.287.653.653,0,0,0-.044.221,1.349,1.349,0,0,0-.066.706.993.993,0,0,0,.728.353,6.5,6.5,0,0,0,.927.022h.772a1.038,1.038,0,0,1,.684.353,6.249,6.249,0,0,1,.441.618,6.3,6.3,0,0,0,.552.728.719.719,0,0,0,1.324-.2,4.921,4.921,0,0,0,.287-.861c.066-.243.132-.485.221-.728a1.12,1.12,0,0,1,.574-.574c.243-.088.485-.177.728-.243a4.945,4.945,0,0,0,.861-.309,1.08,1.08,0,0,0,.552-.6C91.175,87.865,91.042,87.623,90.755,87.292Zm-2.273.485a1.574,1.574,0,0,0-1.39,1.633l-.044.618-.265-.552a1.531,1.531,0,0,0-1.964-.993l-.53.11.375-.4c.684-.75.816-1.368.419-2.03l-.154-.287.309.022a2.716,2.716,0,0,0,2.162-.375l.287-.132-.044.309a1.465,1.465,0,0,0,.9,1.743l.441.221Z" transform="translate(-82.476 -83.184)" fill="#a5a5a5" />
                                    </g>
                                    <g id="Group_71" data-name="Group 71" transform="translate(39.477 51.72)">
                                        <path id="Path_42" data-name="Path 42" d="M67.062,122.166c-.044.044-.066.11-.11.154-.088.154-.2.331-.309.375a1.052,1.052,0,0,1-.794-.044c-.993-.883-1.9-1.853-2.6-2.582a.624.624,0,0,1,.044-.927.734.734,0,0,1,.507-.243.645.645,0,0,1,.419.177c.861.816,1.677,1.655,2.6,2.6a1.113,1.113,0,0,1,.154.287A.785.785,0,0,1,67.062,122.166Z" transform="translate(-63.059 -118.9)" fill="#a5a5a5" />
                                    </g>
                                </g>
                            </svg>

                            {/*<img src="assets/image/TICKET.svg" />*/}
                            MY TICKET

                        </div>
                    </NavLink>


                    {/*<NavLink to="/wallet" activeClassName={"navMenuCardActive"}>
                        <div className="navMenuCard">

                            <svg xmlns="http://www.w3.org/2000/svg" width="26.313" height="25.25" viewBox="0 0 26.313 25.25">
                                <g id="WALLET" transform="translate(-58.1 -65.3)">
                                    <g id="Group_72" data-name="Group 72" transform="translate(58.1 65.3)">
                                        <path id="Path_43" data-name="Path 43" d="M83.408,76.451H76.49a3.353,3.353,0,0,0-.963.117,3.989,3.989,0,0,0,.876,7.881h4.145v3.007a1.108,1.108,0,0,1-1.226,1.226H63.238A3.121,3.121,0,0,1,60,85.471V70.408A3.1,3.1,0,0,1,63.208,67.2H77.453a1.067,1.067,0,0,1,1.022.876,8.2,8.2,0,0,1,.029.847v.467H63.092a.962.962,0,0,0-.876.817.927.927,0,0,0,.613.992,1.8,1.8,0,0,0,.555.058H79.292a1.113,1.113,0,0,1,1.255,1.255V73.3a.921.921,0,0,0,.934.992h0a.93.93,0,0,0,.934-1.022V72.1a2.607,2.607,0,0,0-1.576-2.423,1.037,1.037,0,0,0-.263-.117c-.058-.029-.088-.029-.146-.058l-.058-.029v-1.08A2.965,2.965,0,0,0,77.249,65.3H63.179A5.021,5.021,0,0,0,58.1,70.35V85.5a5,5,0,0,0,5.05,5.05H79.322a2.959,2.959,0,0,0,3.065-3.065V84.449h.963A.953.953,0,0,0,84.4,83.4V77.531C84.488,76.83,84.109,76.451,83.408,76.451Zm-.847,6.1h-6.1a2.29,2.29,0,0,1-1.547-.613,2.126,2.126,0,0,1,1.547-3.62h6.1Z" transform="translate(-58.1 -65.3)" fill="#a5a5a5" />
                                    </g>
                                </g>
                            </svg>

                            WALLET

                        </div>
                    </NavLink>*/}

                    {/* <NavLink to="/settings" activeClassName={"navMenuCardActive"}>
                        <div className="navMenuCard">

                            <svg xmlns="http://www.w3.org/2000/svg" width="28.222" height="28.222" viewBox="0 0 28.222 28.222">
                                <g id="settings2" transform="translate(0 0)">
                                    <path id="Path_44" data-name="Path 44" d="M140.355,133.609a6.746,6.746,0,1,0,6.746,6.746A6.754,6.754,0,0,0,140.355,133.609Zm0,11.806a5.06,5.06,0,1,1,5.06-5.06A5.066,5.066,0,0,1,140.355,145.415Zm0,0" transform="translate(-126.244 -126.244)" fill="#a5a5a5" />
                                    <path id="Path_45" data-name="Path 45" d="M27.558,10.9l-2.169-.472a11.805,11.805,0,0,0-.7-1.683l1.2-1.868a.843.843,0,0,0-.113-1.052L22.4,2.443a.843.843,0,0,0-1.052-.113l-1.868,1.2a11.805,11.805,0,0,0-1.683-.7L17.324.664A.843.843,0,0,0,16.5,0H11.722A.843.843,0,0,0,10.9.664l-.472,2.169a11.8,11.8,0,0,0-1.683.7L6.875,2.33a.843.843,0,0,0-1.052.113L2.443,5.822A.843.843,0,0,0,2.33,6.875l1.2,1.868a11.805,11.805,0,0,0-.7,1.683L.664,10.9A.843.843,0,0,0,0,11.722V16.5a.843.843,0,0,0,.664.824l2.169.472a11.8,11.8,0,0,0,.7,1.683l-1.2,1.868A.843.843,0,0,0,2.443,22.4l3.379,3.379a.843.843,0,0,0,1.052.113l1.868-1.2a11.805,11.805,0,0,0,1.683.7l.472,2.169a.843.843,0,0,0,.824.664H16.5a.843.843,0,0,0,.824-.664l.472-2.169a11.8,11.8,0,0,0,1.683-.7l1.868,1.2a.843.843,0,0,0,1.052-.113L25.779,22.4a.843.843,0,0,0,.113-1.052l-1.2-1.868a11.805,11.805,0,0,0,.7-1.683l2.169-.472a.843.843,0,0,0,.664-.824V11.722A.843.843,0,0,0,27.558,10.9Zm-1.023,4.923-1.989.432a.844.844,0,0,0-.633.6,10.118,10.118,0,0,1-.934,2.253.843.843,0,0,0,.025.871l1.1,1.713-2.418,2.418L19.974,23a.843.843,0,0,0-.87-.025,10.124,10.124,0,0,1-2.254.934.844.844,0,0,0-.6.633l-.432,1.989H12.4l-.432-1.989a.844.844,0,0,0-.6-.633,10.118,10.118,0,0,1-2.253-.934A.844.844,0,0,0,8.248,23l-1.713,1.1L4.117,21.687l1.1-1.713a.843.843,0,0,0,.025-.871,10.129,10.129,0,0,1-.934-2.253.843.843,0,0,0-.633-.6l-1.989-.432V12.4l1.989-.432a.844.844,0,0,0,.633-.6,10.118,10.118,0,0,1,.934-2.253.843.843,0,0,0-.025-.871l-1.1-1.713L6.534,4.117l1.713,1.1a.843.843,0,0,0,.87.025,10.124,10.124,0,0,1,2.254-.934.844.844,0,0,0,.6-.633L12.4,1.686h3.419l.432,1.989a.844.844,0,0,0,.6.633,10.118,10.118,0,0,1,2.253.934.844.844,0,0,0,.871-.025l1.713-1.1,2.418,2.418L23,8.248a.843.843,0,0,0-.025.871,10.119,10.119,0,0,1,.934,2.253.843.843,0,0,0,.633.6l1.989.432Zm0,0" fill="#a5a5a5" />
                                </g>
                            </svg>

                            SETTINGS

                        </div>
                    </NavLink> */}

                    <NavLink to="buyTicket" activeClassName={"navMenuCardActive"}>
                        <div className="navMenuCard">
                            <svg id="plus" xmlns="http://www.w3.org/2000/svg" width="19.577" height="19.56" viewBox="0 0 19.577 19.56">
                                <path id="Path_193" data-name="Path 193" d="M35.115,45.558a3.735,3.735,0,0,1,.137-.441,1.665,1.665,0,0,1,1.626-1.049h6.307V37.747a1.718,1.718,0,1,1,3.435-.015v6.322h6.307a1.718,1.718,0,1,1,.03,3.435H46.6v6.17a1.731,1.731,0,0,1-1.459,1.885c-.015,0-.015.015-.03.015H44.66c-.015,0-.015-.015-.03-.015a1.731,1.731,0,0,1-1.459-1.885v-6.17H36.863a1.716,1.716,0,0,1-1.733-1.383A.282.282,0,0,1,35.1,46,4.126,4.126,0,0,0,35.115,45.558Z" transform="translate(-35.1 -35.999)" fill="#a5a5a5" />
                            </svg>

                            BUY TICKET

                        </div>

                    </NavLink>

                </div>
            </div>
        </>
    );
}

export default HeaderSite;