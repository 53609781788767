import { ModalContainer, ModalContent } from "./style";

const CustomModal = ({
  show, 
  toggleModal, 
  styles ,
  children
}) => {

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  return (
    <ModalContainer
      show={show}
      onMouseDown={handleClickOutside}
      style={{ ...styles }}
    >
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  );
};

export default CustomModal;
