import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


const CookiePolicyPage = () => {


    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);


    const handleShowText = (keyText) => {

        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];

            if (textObj.key == keyText) {

                return textObj.value
            }

        }
        return null

    }


    const handleShowImage = (keyImage) => {
        for (let i = 0; i < AllImageSite.length; i++) {
            const imageObj = AllImageSite[i];

            if (imageObj.key == keyImage) {

                return imageObj.value
            }

        }
        return null
    }


    return (
        <>
            <Helmet>
                <title> Cookie Policy </title>
            </Helmet>
            <div className="otherPageBaner mb_2">
                <img src={`${handleShowImage('cookiePolicyImage')}`} alt="" />
            </div>
            <div className="otherPageText">
                <div className="simpleTitle leftTitle">
                    cookiePolicy title
                </div>
                <span><p>
                </p>



                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">Xbit Lotto uses a technology which is known as
                        'cookies' in order to collect information on how our website is being used. Our
                        use of cookies complies with EU regulations that govern the use of cookies in
                        websites.</span></p>
                    <p>

                    </p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">In the following sections, you can find a brief list of the cookies we use
                        and their purpose:</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">•<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Analytic
                        Cookies: to store information such as the time you visited our website, whether
                        or not you have visited the site before and sometimes the website you visited
                        prior to our website.</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">&nbsp;</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">•<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Personalization
                        Cookies: these cookies are for tailoring the website based on how you interact
                        with it so you are presented with more relevant and useful information.</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">&nbsp;</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">•<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Social
                        Media Cookies: these are set by social networks such as Meta, Twitter and
                        YouTube and identify which visitors use social media sites when viewing content
                        on our website.</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">&nbsp;</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">•<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>ASP
                        Session ID Cookies: this type of cookie is a temporary cookie that includes a
                        randomly created anonymous identifier and expires at the end of your visit to
                        our website.</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">It needs to be noted that the majority of
                        cookies will expire within 30 days although the Google Analytics cookies may
                        remain for longer.</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 16pt; line-height: 150%;">&nbsp;</span></p>
                    <p style="text-align: left; line-height: 150%;"><b><span style="font-size: 18pt; line-height: 150%;">What Are Cookies?</span></b></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">In essence, a cookie is described as a very
                        small piece of information that is stored on your computer or device. When a
                        user visits a website, that website will ask their computer for permission to
                        store a cookie which often contains an anonymous unique identifier. The cookie,
                        amongst other things, can be used to improve your experience by displaying
                        information on the website which is considered to be most relevant to you. </span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 16pt; line-height: 150%;">&nbsp;</span></p>
                    <p style="text-align: left; line-height: 150%;"><b><span style="font-size: 18pt; line-height: 150%;">How Cookies Are Used at Xbit Lotto</span></b></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">Cookies are implemented by Xbit Lotto in order
                        to determine your interaction with our website for the purpose of improving
                        customer experience. Information gathered through cookies may include the date
                        and time of your visit, the duration of time spent on the site, the pages
                        visited and sometimes the website visited before you arrived at Xbit Lotto. However,
                        it is paramount to point out that the information provided by these cookies
                        does not include any data that could be used to identify you.</span></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">&nbsp;</span></p>
                    <p style="text-align: left; line-height: 150%;"><b><span style="font-size: 16pt; line-height: 150%;">Don't Want to Accept Cookies?</span></b></p>
                    <p style="text-align: left; line-height: 150%;"><span style="font-size: 12pt; line-height: 150%;">The options provided by your web browser can
                        be implemented to delete cookies from your browsing history at any time. You
                        also have the option to disable cookies altogether, although, it needs to be
                        mentioned that doing so may restrict or inhibit certain parts of our website
                        and impact your experience at Xbit Lotto.</span></p></span>
            </div>
        </>
    );
}

export default CookiePolicyPage;