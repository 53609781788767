import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {apiUrl} from '../../utils/config';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash"; 






const ForgetPasswordPage = () => {
    const [Email,setEmail] = useState('');
    const [Pass,setPass] = useState('');
    const [RePass,setRePass] = useState('');

    const [_2fa,set_2fa] = useState('');
    const [PassValidate, setPassValidate] = useState({})


    const emailInput = useRef(null);
    const passInput = useRef(null);
    const rePassInput = useRef(null);


    const modalTemp = useRef(null);


  let history = useHistory();


  const handleEditWalletAddress = (index = -1) =>{
    modalTemp.current.style.opacity = 1;
    modalTemp.current.style.visibility = 'visible';

}

const handleCloseEditWallet = () =>{
    modalTemp.current.style.opacity = 0;
    modalTemp.current.style.visibility = 'hidden';

}

const handleCheckPass = () =>{
    // var validation = passwordStrength(Pass);
    // console.log(validation)
    setPassValidate({id:2 , value : "strong"});
    console.log(PassValidate.id)
}

const handlShowPassIn = (refIn) =>{
    if(refIn.current.type == 'password'){
        refIn.current.type= 'text'
    }else{
        refIn.current.type= 'password'
    }
}


    const handleAuthReq = () =>{
        console.log(Email);

        if(_.isEmpty(Email)){
            toast.error("please complete empty inputs" )
            emailInput.current.style.border='1px solid red'
        }
        else{
            axios({
                url:apiUrl + '/auth/forget',
                method:'post',
                headers:{
                    "Access-Control-Expose-Headers": "Access-Token"
                },
                data:{email : Email.toString().toLowerCase()}
            }).then((res) =>{
                toast.success(`${res.data.message}`);
                handleEditWalletAddress();
                // document.cookie = `token=${res.headers['x-auth-token']}`;
                // window.location.href = '/'
                // history.push('/')
    
                // res.json();
                // console.log(res);
                // console.log(res.headers);
            }).catch((error) => {
                if(error.response.status === 403){
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                }else{
                    toast.error(`${error.response.data}`)
                }
            })
        }

    }

    const handleVerficationEmail = () =>{

        if(_.isEmpty(Pass)){
            toast.error("please complete empty inputs" )
            passInput.current.style.border='1px solid red'
        }
        else if(_.isEmpty(RePass)){
            toast.error("please complete empty inputs" )
            rePassInput.current.style.border='1px solid red'
        }
        else if(Pass !== RePass){
            toast.error("your password and repeat password not match" )
        }
        else if(PassValidate.id<1){
            toast.error(`your password must be Better` )
        }
        else if(_.isEmpty(_2fa)){
            toast.error("Enter Your 2FA Code" )
        }
        
        else{
            axios({
                url:apiUrl + '/auth/forget/' + _2fa,
                method:'put',
                data:{password:Pass}
            }).then((res) =>{
                console.log(res);
                toast.success('Your Password Changed Successfully!');
                document.cookie = `token=${res.headers['x-auth-token']}`;

                window.location.href = "/";
            }).catch((error)=>{
                if(error.response.status === 403){
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                }else{
                    toast.error(`${error.response.data.message}`)
                }
            })
        }
    }

    useEffect(() =>{handleCheckPass()},[Pass])




    return(
        <>
                   
            <div className="siteModalmain" ref={modalTemp}>
                <div className="siteModalBox">

                        <div className="modalClosebtn" onClick={handleCloseEditWallet}>
                            &#10006;
                        </div>

                        <div className="modalImage">
                            <div className="modalImageTitle"> 2FA Forget Password </div>
                        </div>


                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <div className="registerInputTitle forgetPassRules"> 
                                    {/* <div>Password</div> */}
                                    {PassValidate.id <= '2' ? 
                                        <>

                                                
                                            <div className="inputError">
                                                {PassValidate.value}
                                            </div>

                                        </>
                                    
                                    :<><div className="inputSuccess">{PassValidate.value}</div></>}
                                    <div class="tooltip1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                        </svg>
                                        <span class="tooltiptext1">
                                            <div>
                                                <div className="passwordRules">
                                                    <div className="passwordRulesTitle">Password Rules</div>
                                                    <div>use numeric character</div>
                                                    <div>use uppercase alphabetical character</div>
                                                    <div>use lowercase alphabetical character</div>
                                                    <div>use special character ([!@#$%^&]))</div>
                                                </div>
                                            </div>
                                            {/* <div>
                                            your tips play until the jackpot has been hit and you have enough money in your wallet.
                                            </div> */}
                                        </span>
                                    </div>
                                </div>
                                <div className="passInWithShowPass">
                                    <div className="showPassIcon" onClick={() => handlShowPassIn(passInput)}>
                                        <img src="/assets/image/eye.svg" alt="" />
                                    </div>
                                    <input type="password" id="authPass" className="inputSite" placeholder={`New Password`}
                                        onChange={e => setPass(e.target.value)}
                                        ref={passInput}
                                    />
                                </div>
                            
                        </div>

                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            
                            <div className="passInWithShowPass">
                                <div className="showPassIcon" onClick={() => handlShowPassIn(rePassInput)}>
                                    <img src="/assets/image/eye.svg" alt="" />
                                </div>
                                <input type="password" id="authPass" className="inputSite" placeholder={`Repeat New Password`}
                                    onChange={e => setRePass(e.target.value)}
                                    ref={rePassInput}
                                />
                            </div>
                        </div>

                        <div className="authInputBox modalInputBox">
                            {/* <label htmlFor="authPass">First Name</label> */}
                            <input type="text" id="authPass" className="inputSite" placeholder={`Your 2FA Forget Password`}
                                onChange={e => set_2fa(e.target.value)}
                            />
                        </div>

                        <div className="btnTemp1 customRegisterbtn" onClick={() => handleVerficationEmail()}>
                            Submit
                            {/* <img src="/assets/image/arrow4.svg" alt="" /> */}
                        </div>
                


                </div>
            </div>

            <div className="authMainBox">

                <div className="authTitleAndDesc">
                    <div className="authTitle">FORGET PASSWORD</div>
                    <div className="authDesc"> Enter Your Email to Send Forget password Link </div>
                </div>


                <div className="authInputBox">
                    {/* <label htmlFor="authEmail">Email</label> */}
                    <input type="email" id="authEmail" className="inputSite" placeholder="Enter Your Email"
                    onChange={e => setEmail(e.target.value)}
                    ref={emailInput}
                    />
                </div>

                <div className="btnTemp1" onClick={handleAuthReq}> SEND FORGET LINK </div>
            </div>
        </>
    )
}

export default ForgetPasswordPage;
