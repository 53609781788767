import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import _ from "lodash";

import {apiUrl} from '../../utils/config';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

const CrashGamePage = () => {

    const [UserData,setUserData] = useState({});
    const [PaymentAmount, setPaymentAmount] = useState(0);
    const [paymentMethodsGame, setPaymentMethodsGame] = useState('Lottery');

    const [GameLink,setGameLink] = useState('');



    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);
  
    const handleShowText = (keyText) =>{
  
        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];
                      
            if(textObj.key == keyText){
                
                return textObj.value
            }
            
        }
        return null
        
    }
  
    
    const handleShowImage = (keyImage) =>{
      for (let i = 0; i < AllImageSite.length; i++) {
          const imageObj = AllImageSite[i];
                    
          if(imageObj.key == keyImage){
              
              return imageObj.value
          }
          
      }
      return null
    }

    let token = "";
    let history = useHistory();

    const handleCheckUser = (typeCall) => {
      const allCookie = document.cookie;
      // let token = "";
      if (allCookie.search("token") > -1) {
        token = document.cookie
        .split('; ')
        .find(row => row.startsWith('token='))
        .split('=')[1];
            return true;
        }
        if(typeCall !== "check"){
          history.push('/login')
        }
        return false;
    } 

    const getInfoUser = () => {
        axios({
            url:apiUrl + "/client",
            method:'get',
            headers: {
                'x-auth-token': token,
            },
        }).then((response)=>{
            var user = response.data.data;
            setUserData(user);
            // setUserSymbol(user.firstname.charAt(0)+user.surname.charAt(0));
            // setUserWalletVal(user.wallet.toFixed(2))
        }).catch((error) => {
          if(error.response.status === 403){
              document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.href = '/login'
          }
      });
    }

    const handleSetPayment = (index) => {
        // console.log('index :>> ', index);
        if(index == 0){
            setPaymentMethodsGame('Lottery');
        }else if(index == 1){
            setPaymentMethodsGame('Game');
        }
        // setSelectCoinId(0);
        // console.log('paymentMethods :>> ', paymentMethods);
    }

    const handleGameToLottery = () =>{
        if(PaymentAmount == 0){
            toast.error("please enter transfer Amount" )
        }else{
            handleCheckUser("check");
            axios({
                url : `${apiUrl}/crash/deposit_from_crash`,
                headers: { 
                    'x-auth-token': token, 
                },
                method: 'post',
                data:{amount : parseInt(PaymentAmount)}
            }).then((res) => {
                // console.log(res);
                toast.success(`Transfered ${PaymentAmount} XBIT `);
                setPaymentAmount(0);
                history.push('/crash');
            }).catch((err) =>{
                console.log(err);
                toast.error(`${err.response.data.message}`)
            })
        }
    }

    const handleLotteryToGame = () => {

        if(PaymentAmount == 0){
            toast.error("please enter transfer Amount" )
        }else{
            handleCheckUser("check");
            axios({
                url : `${apiUrl}/crash/withdraw_to_crash`,
                headers: { 
                    'x-auth-token': token, 
                },
                method: 'post',
                data:{amount : parseInt(PaymentAmount)},
            }).then((res) => {
                // console.log(res);
                toast.success(`Transfered ${PaymentAmount} XBIT `);
                setPaymentAmount(0);
                history.push('/crash');
            }).catch((err) =>{
                console.log(err);
                toast.error(`${err.response.data.message}`)
            })
        }
    }

    const handleGetGameLink = () =>{
        handleCheckUser("check");
     
        axios({
            url : `${apiUrl}/crash/enter`,
            headers: { 
                'x-auth-token': token, 
            },
            method: 'get',
        }).then((res) => {
            // console.log(res);
            setGameLink(res.data.data)
            // window.open(res.data.data, '_blank');
        }).catch((err) =>{
            console.log(err);
            toast.error(`${err.response.data.message}`)
        })
    }

    const handleActivateGame = () => {
        handleCheckUser("check");
        axios({
            url : `${apiUrl}/crash/activate`,
            headers: { 
                'x-auth-token': token, 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'get',
        }).then((res) => {
            toast.info(`Proccessing!`);
            getInfoUser();

        }).catch((err) =>{
            console.log(err);
            toast.error(`${err.response.data.message}`)
        }) 
    }

    useEffect(()=>{
        handleCheckUser("check");
        handleGetGameLink();
        getInfoUser();
    },[])

    return ( 
        <>

            <Helmet>
                <title>Crash Account</title>
            </Helmet>

            <div className="withdrawalTexture">
                <img src={`${handleShowImage('withrawalTexture')}`} alt="" />
            </div>

            
            <div className="withdrawalMainBox">

                <div className="gameMainTitle">
                    Crash Account
                </div>


                {UserData.is_crash_active ? 
                
                    <>
                        <Tabs defaultIndex={0} onSelect={index => handleSetPayment(index)}>
                        <div className="gameMainText">
                            {handleShowText("crashGame_textActive")}
                        </div>
                        <div className="transferToTitle">
                            Transfer To
                        </div>
                        <div className="paymentType gamePaymentType">


                            <TabList>
                                <Tab className="PT-card">Lottery</Tab>
                                <Tab className="PT-card">Crash</Tab>
                            </TabList>
                        </div>

                        <div className="paymentBody GamePaymentBody">
                            <TabPanel>
                                <div className="paymentAmount">
                                    <div className="paymentAmountTitle gamePaymentAmountTitle">Transfer Amount</div>
                                    <div className="paymentAmountIInput gamePaymentAmountIInput">
                                        <label for="depositAmount">XBit</label>
                                        <input type="text" id="depositAmount" className="inputSite" placeholder="Amount"
                                        onChange={e => setPaymentAmount(e.target.value)}
                                        Value={PaymentAmount}
                                        />
                                    </div>
                                </div>
                                
                                <div className="paymentBodyFooter">
                                    <div className="btnTemp1 gameSubmitTransferBtn" onClick={handleGameToLottery}>
                                        Submit Transfer
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="paymentAmount">
                                    <div className="paymentAmountTitle gamePaymentAmountTitle">Transfer Amount</div>
                                    <div className="paymentAmountIInput gamePaymentAmountIInput">
                                        <label for="WithdrawalAmount">XBit</label>
                                        <input type="text" id="WithdrawalAmount" className="inputSite" placeholder="Amount" 
                                        onChange={e => setPaymentAmount(e.target.value)} 
                                        Value={PaymentAmount}/>
                                    </div>
                                </div>
                                
                                <div className="paymentBodyFooter">
                                    <div className="btnTemp1 gameSubmitTransferBtn" onClick={handleLotteryToGame}>
                                        Submit Transfer
                                    </div>
                                </div>
                            </TabPanel>

                        </div>

                        </Tabs>

                        <a href={GameLink} target={'_blank'}>

                            <div className="btnTemp1 gameSubmitTransferBtn playGameBtn" onClick={handleGetGameLink}>
                                Play Game
                            </div>
                        </a>

                    </>
                :
                
                    <>
                        

                        <div className="gameMainText">
                            {handleShowText("crashGame_text")}
                        </div>

                        <div className="btnTemp1 gameActiveBtn" onClick={handleActivateGame}>
                            Connect Account
                        </div>

                    </>
                
                }
            </div>


        </>
     );
}
 
export default CrashGamePage;