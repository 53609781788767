import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useRef } from 'react';

const ReceiptPage = (props) => {

    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);

    const componentRef = useRef(null);
  
    const handleShowText = (keyText) =>{
  
        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];
                      
            if(textObj.key == keyText){
                
                return textObj.value
            }
            
        }
        return null
        
    }
  
    
    const handleShowImage = (keyImage) =>{
      for (let i = 0; i < AllImageSite.length; i++) {
          const imageObj = AllImageSite[i];
                    
          if(imageObj.key == keyImage){
              
              return imageObj.value
          }
          
      }
      return null
    }

    // console.log(props);

    var history = useHistory(); 

    const [ReceiptTicket,setReceiptTicket] = useState({});

    const handlePrintReceipt = () =>{
        var printContent = document.getElementsByClassName('receiptPageMainBody')[0];
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(printContent.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    useEffect(() => {

        console.log(props.location);
        if(props.location.aboutProps == 'undefined' || props.location.aboutProps == undefined){
            if(props.location.state != undefined){
                setReceiptTicket(props.location.state[0].aboutProps.ticketReceipt);
            }else{
                history.push('/')
            }
        }else{
            setReceiptTicket(props.location.aboutProps.ticketReceipt);
        }
    },[])
    return ( 
        <>
            {_.isEmpty(ReceiptTicket)?
            null
            :
            <>
            
                <div className="withdrawalTexture">
                    <img src={`${handleShowImage('withrawalTexture')}`} alt="" />
                </div>
                <div className="receiptPageMainBody" ref={componentRef}>
                    <div className="receiptPageMainTitle"> Your Receipt </div>

                    <div className="RPMB-mainBox">

                        <div className="receiptMyTicket">
                            <div className="HLR-card">
                                <img src={`${handleShowImage('ticketHome')}`} alt="" className="HLR-cardImage" />
                                <div className="HLR-cardDate">
                                    {(() =>{

                                        var newDate = moment(ReceiptTicket.created_at).format("DD , MMMM , YYYY");

                                        return newDate
                                    })()}
                                </div>
                                <div className="HLR-cardNumbers">
                                    <div className="HLR-cardNumbers5Box">
                                        {ReceiptTicket.numbers.map((Number,i) =>
                                            <div className={`HLR-cardNumbers5 ${ReceiptTicket.lottery_id.numbers.indexOf(Number) >-1  ? "active" : ""}`}>{Number}</div>
                                        )}
                                    </div>
                                    <div className="HLR-cardNumbers2Box">
                                        {ReceiptTicket.jokers.map((Number,i) =>
                                            <div className={`HLR-cardNumbers2 ${ReceiptTicket.lottery_id.jokers.indexOf(Number) >-1 ? "active" : ""}`}>
                                                <img src="/assets/image/PolygonStar.png" alt="" />
                                                {Number}
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="receiptResultBox">
                            <div className="receiptResultTitle">Result</div>
                            <div className="receiptResultBody">

                                <div className="SSRM-RRSN-ticket">
                                    <img className='SSRM-RRSN-ticketImage' src={`/assets/image/resultTicket.png`} alt="" />
                                    <div className="SSRM-RRSN-ticketMain">
                                        <div className="SSRM-RRSN-ticket5No">
                                            {ReceiptTicket.lottery_id.numbers.map((Number,i) =>
                                                <div className={`SSRM-RRSN-ticket5NoCard`}>{Number}</div>
                                            )}
                                            {/* <div className="SSRM-RRSN-ticket5NoCard">1</div>
                                            <div className="SSRM-RRSN-ticket5NoCard">2</div>
                                            <div className="SSRM-RRSN-ticket5NoCard">3</div>
                                            <div className="SSRM-RRSN-ticket5NoCard">4</div>
                                            <div className="SSRM-RRSN-ticket5NoCard">5</div> */}
                                        </div>
                                        <div className="SSRM-RRSN-ticket2No">
                                            {ReceiptTicket.lottery_id.jokers.map((Number,i) =>
                                                <div className="SSRM-RRSN-ticket2NoCard">
                                                <img src="/assets/image/polygonStar3.png" alt="" />
                                                <div className="SSRM-RRSN-ticket2NoCardNo">{Number}</div>
                                            </div> 
                                            )}
                                            {/* <div className="SSRM-RRSN-ticket2NoCard">
                                                <img src="/assets/image/polygonStar3.png" alt="" />
                                                <div className="SSRM-RRSN-ticket2NoCardNo">1</div>
                                            </div> 
                                            <div className="SSRM-RRSN-ticket2NoCard">
                                                <img src="/assets/image/polygonStar3.png" alt="" />
                                                <div className="SSRM-RRSN-ticket2NoCardNo">2</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="receiptDetailMain">

                            <div className="RDM-card">
                                <div className="RDM-cardTitle"> Particpation : </div>
                                <div className="RDM-cardText">
                                    {(() =>{

                                        var newDate = moment(ReceiptTicket.created_at).format("dddd , MMMM DD , YYYY");

                                        return newDate
                                    })()}
                                </div>
                            </div>

                            <div className="RDM-card">
                                <div className="RDM-cardTitle"> Lottery : </div>
                                <div className="RDM-cardText">
                                    {(() =>{
                                        let endLottery = ReceiptTicket.lottery_id.end_date;
                            
                                        var result = new Date(endLottery);
                                        var newDate = moment(result).format("dddd , MMMM DD , YYYY");

                                        return newDate
                                    })()}
                                </div>
                            </div>

                            <div className="RDM-card">
                                <div className="RDM-cardTitle"> Status : </div>
                                <div className="RDM-cardText"> {ReceiptTicket.lottery_id.has_ended? "Paid" : "Hold On"} </div>
                            </div>

                            {/* <div className="RDM-card">
                                <div className="RDM-cardTitle"> Receipt No. :</div>
                                <div className="RDM-cardText"> 15645651561 </div>
                            </div> */}

                            <div className="RDM-card">
                                <div className="RDM-cardTitle"> Stake : </div>
                                <div className="RDM-cardText"> {ReceiptTicket.lottery_id.draw_price} XBIT </div>
                            </div>

                        </div>

                        <ReactToPrint content={() => componentRef.current}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <div className="printReceiptBtn" onClick={handlePrint}>
                                        <img src="/assets/image/print.svg" alt="" />
                                        <div>print receipt</div>
                                    </div>
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>

                        

                    </div>




                </div>
                
            </>
            }
        </>
     );
}
 
export default ReceiptPage;