import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';

import { apiUrl } from '../../utils/config';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getLotteryPlayerTicket, getLotteryPlayers, getTotalLotteryPlayersCount } from '../../utils/contractWeb3';
import { lastUserMockData } from '../../mock/lastUser';
import { LoadingData } from '../../components/loading';

const LotteryPlayersPage = () => {

    const appDetail = useSelector(state => state.app);

    const [WithdrawArr, setWithdrawArr] = useState([]);
    const [WithdrawDetail, setWithdrawDetail] = useState({});
    const [LotteryPlayers, setLotteryPlayers] = useState([]);
    const [LoadingDataPlayers, setLoadingDataPlayers] = useState(true)
    const [TotalPlayerCount, setTotalPlayerCount] = useState(0)
    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10)

    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);

    const handleShowText = (keyText) => {

        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];

            if (textObj.key === keyText) {

                return textObj.value
            }

        }
        return null

    }


    const handleShowImage = (keyImage) => {
        for (let i = 0; i < AllImageSite.length; i++) {
            const imageObj = AllImageSite[i];

            if (imageObj.key === keyImage) {

                return imageObj.value
            }

        }
        return null
    }


    const handleSystemWithdraw = (pageNo) => {
        axios({
            url: apiUrl + `/withdraw?page=${pageNo}&per_page=12`,
            method: 'get',
        }).then((res) => {

            setWithdrawArr(res.data.data);
            setWithdrawDetail(res.data.message);

        }).catch((error) => {
            toast.error(error)
        });
    }

    const handleGetLotteryPlayers = () => {
        // console.log(appDetail.lottery.currentId)
        setLoadingDataPlayers(true)
        getLotteryPlayers(appDetail.lottery.currentId, CurrentPage, PerPage)
            .then((res) => {
                let promises = [];
                let helpArr = [];

                for (let i = 0; i < res?.length; i++) {
                    const element = res[i];
                    if (element !== nullAddress) {
                        const promise = getLotteryPlayerTicket(element, appDetail.lottery.currentId)
                            .then((resTicket) => {
                                helpArr.push({
                                    player_address: element,
                                    numbers: resTicket
                                });
                            })
                            .catch((err) => {
                                console.log({ LottoValue: "Error", err });
                            });
                        promises.push(promise);
                    }
                }


                Promise.all(promises)
                    .then(() => {
                        if (helpArr.length < 3) {
                            helpArr = [...helpArr, ...lastUserMockData]
                        }
                        console.log(helpArr)
                        setLotteryPlayers(helpArr);
                        setLoadingDataPlayers(false)
                    })
                    .catch((err) => {
                        console.log({ LottoValue: "Error", err });
                    });
            })
            .catch((err) => {
                console.log({ LottoValue: "Error", err });
            });
    };

    const handleGetTotalPlayers = () => {
        getTotalLotteryPlayersCount(appDetail.lottery.currentId).then(res => {
            console.log({ res });
            setTotalPlayerCount(parseInt(res) || 0)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handlePageClick = (event) => {
        // handleSystemWithdraw(event.selected);
        setCurrentPage(event.selected)
    }

    useEffect(() => {
        if (appDetail.lottery.currentId !== -1) {
            handleGetLotteryPlayers();
            handleGetTotalPlayers();
        }
    }, [appDetail.lottery, CurrentPage])

    return (
        <>
            <Helmet>
                <title>Players</title>
            </Helmet>
            <div className="withdrawalTexture">
                <img src={`${handleShowImage('withrawalTexture')}`} alt="" />
            </div>

            <div className="withdrawalMainBox">

                <div className="withdrawalTitle">
                    Players
                </div>

                <div className="withdrawalText">
                    {handleShowText("withdrawal_text")}
                </div>

                {/* <div className="withdrawalBox">
                    <div className="totalWithdra">
                        <div className="WB-title"> Total Withdrawal </div>
                        <div className="WB-totalVal">
                             <img src="/assets/image/dollorWhite.svg" alt="" /> 
                            <div>{WithdrawDetail.sum_withdraw}</div>
                            <div>XBIT</div>
                        </div>
                    </div>
                    <img src={`${handleShowImage('cashWallet')}`} alt="" className='WB-image' />
                    <div className="totalTransaction">
                        <div className="WB-title"> All Transaction </div>
                        <div className="WB-totalVal">
                            <div>{WithdrawDetail.total_withdraw}</div>
                        </div>
                    </div>
                </div> */}

                <div className="withdrawalTable">

                    {LoadingDataPlayers ?
                        <>
                            <div className="d-flex justify-content-center mt-4">
                                <LoadingData />
                            </div>
                        </>
                    :
                        <>
                            <table className="secondSiteTable">
                                <tr>
                                    <th>Adress</th>
                                    <th>Amount</th>
                                    <th>Last Ticket</th>
                                </tr>

                                {LotteryPlayers.map((player, index) =>

                                    <tr className="homeTableTr">
                                        <td>
                                            {`${player.player_address.substring(0, 9)}....${player.player_address.substring(player.player_address.length - 9, player.player_address.length)}`}
                                        </td>
                                        <td>
                                            {/* <img src="/assets/image/redDollar.svg" alt="" /> */}
                                            <span>{(player?.numbers?.length * 0.01)}</span>
                                            <span>{appDetail.siteCoin.coin}</span>
                                        </td>
                                        <td>
                                            <div className="HLR-cardNumbers5Box fullPlayer">
                                                {player?.numbers?.[player?.numbers?.length - 1]?.map((Number, i) =>
                                                    <div className={`HLR-cardNumbers5`}>{Number}</div>
                                                )}

                                            </div>
                                        </td>
                                    </tr>

                                )}

                            </table>
                        </>
                    }


                    <ReactPaginate
                        pageClassName="sitePaginationCard"
                        containerClassName="sitePagination"
                        activeClassName="active"
                        breakLabel="..."
                        nextLabel=""
                        onPageChange={(event) => handlePageClick(event)}
                        pageRangeDisplayed={5}
                        pageCount={TotalPlayerCount}
                        previousLabel=""
                        renderOnZeroPageCount={null}

                    />


                    {/* <div className="sitePagination">
                        <div className="sitePaginationCard active">
                            <a href="#">1</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">2</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">3</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">4</a>
                        </div>
                        <div className="sitePaginationCard">
                            <a href="#">5</a>
                        </div>
                        
                    </div> */}
                </div>

            </div>

        </>
    );
}

export default LotteryPlayersPage;