import React, { useState } from "react";
import { useEffect } from "react";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import { apiUrl, nullAddress } from '../../utils/config';
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, Autoplay } from 'swiper/modules';
import { getLotteryPlayerTicket, getLotteryPlayers, getLotteryValue } from "../../utils/contractWeb3";
import { convertWeiToEth } from "../../utils";
import { lastUserMockData } from "../../mock/lastUser";

const Dashboard = () => {

    const [currentLottery, setCurrentLottery] = useState('');
    const [TotalJackpot, setTotalJackpot] = useState('-');
    const [TotalJackpotDollar, setTotalJackpotDollar] = useState('-');

    const [drawCount, setDrawCount] = useState(1);

    const [LastLottery, setLastLottery] = useState([]);
    const [LotteryPlayers, setLotteryPlayers] = useState([]);
    const [LotteryPlayersFull, setLotteryPlayersFull] = useState([]);
    const [LastWithdraw, setLastWithdraw] = useState([]);
    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10)

    const appDetail = useSelector(state => state.app);
    // const AllImageSite = useSelector(state => state.image);

    const changeDrawCount = (opt) => {
        if (opt == '-') {
            var newa = drawCount - 1;
            if (newa < 1) {
            } else {
                setDrawCount(newa)
            }
        } else {
            var newa = drawCount + 1;
            if (newa > 8) {
            } else {
                setDrawCount(newa)
            }
        }
    }

    const handleGetLastLottery = () => {

        axios({
            url: apiUrl + "/lottery/last?limit=3",
        }).then((response) => {
            setLastLottery(response.data.data);
            // console.log(response);
        })

    }

    const handleGetLastWithdraw = () => {

        axios({
            url: apiUrl + "/withdraw?page=1&per_page=5",
        }).then((response) => {
            setLastWithdraw(response.data.data);
            // console.log(response);
        })

    }

    const handleGetLotteryBalance = () => {
        getLotteryValue()
            .then((res) => {
                let weiCalcDefine = 10 ** 18;
                setTotalJackpot(res / weiCalcDefine);
            }).catch((err) => {
                console.log({ LottoValue: "Error", err })
            })
        // setTimeout(() => {

        // }, 1000)
    }

    // const handleGetLotteryPlayers = () => {
    //     getLotteryPlayers()
    //         .then((res) => {
    //             let helpArr = [];
    //             console.log({
    //                 lotto: res
    //             })

    //             for (let i = 0; i < res["0"]?.length; i++) {
    //                 const element = res["0"]?.[i];
    //                 if(element !== "0x0000000000000000000000000000000000000000"){
    //                     getLotteryPlayerTicket(element).then((resTicket) => {
    //                         console.log(resTicket)
    //                         helpArr.push({
    //                             player_address : element,
    //                             numbers : resTicket
    //                         })

    //                     }).catch((err) =>{
    //                         console.log({ LottoValue: "Error", err })
    //                     })
    //                 }
    //             }

    //             setLotteryPlayers(helpArr);
    //         }).catch((err) => {
    //             console.log({ LottoValue: "Error", err })
    //         })
    // }

    const handleGetLotteryPlayers = (customPerPage) => {
        // console.log(appDetail.lottery.currentId)
        getLotteryPlayers(appDetail.lottery.currentId, CurrentPage, customPerPage || PerPage)
            .then((res) => {
                let promises = [];
                let helpArr = [];

                for (let i = 0; i < res?.length; i++) {
                    const element = res[i];
                    if (element !== nullAddress) {
                        const promise = getLotteryPlayerTicket(element, appDetail.lottery.currentId)
                            .then((resTicket) => {
                                helpArr.push({
                                    player_address: element,
                                    numbers: resTicket
                                });
                            })
                            .catch((err) => {
                                console.log({ LottoValue: "Error", err });
                            });
                        promises.push(promise);
                    }
                }


                Promise.all(promises)
                    .then(() => {
                        if (helpArr.length < 3) {
                            helpArr = [...helpArr, ...lastUserMockData]
                        }
                        console.log(helpArr)
                        if (customPerPage) {
                            setLotteryPlayers(helpArr);
                        } else {
                            setLotteryPlayersFull(helpArr);
                        }
                    })
                    .catch((err) => {
                        console.log({ LottoValue: "Error", err });
                    });
            })
            .catch((err) => {
                console.log({ LottoValue: "Error", err });
            });
    };


    const handleSetNextLotteryTime = () => {
        const endOfMonth = moment(moment.utc()).endOf('month').toString();
        // const newLotteryDate = moment(endOfMonth).toDate().toUTCString();
        setCurrentLottery(endOfMonth);
        console.log({ endOfMonth })
    }

    // useEffect(() => {
    // console.log(AllTextSite)
    // handleGetLastLottery();
    // handleGetLastWithdraw();
    // }, [AllTextSite]);


    useEffect(() => {
        handleSetNextLotteryTime();
        setTimeout(() => {
            handleGetLotteryBalance();
            // handleGetLotteryPlayers();
        }, 1000);
    }, [])

    useEffect(() => {
        if (appDetail.lottery.currentId !== -1) {
            handleGetLotteryPlayers(3);
            handleGetLotteryPlayers();
        }
    }, [appDetail.lottery])

    // useEffect(() => {
    //     axios({
    //         url: apiUrl + "/lottery/current",
    //     }).then((response) => {

    //         if (response.data.data !== null) {

    //             setTotalJackpot(response.data.data.total_jackpot);
    //             setTotalJackpotDollar(response.data.data.dollar_jackpot.toFixed(2));

    //             let end_date = response.data.data.end_date;

    //             var result = new Date(end_date);

    //             var newDate = moment(result).format("YYYY-MM-DD HH:mm:ss");

    //             if (moment(result).isBefore()) {
    //                 setCurrentLottery('pass')
    //             } else {
    //                 setCurrentLottery(newDate + "");
    //             }
    //         }
    //         // console.log(moment(result).isBefore(moment()));
    //     }).catch((error) => {

    //     })
    // }, [])


    return (
        <>
            <Helmet>
                <title>Lottery</title>
            </Helmet>

            <div className="dashboardMainBox">

                <div className="star_banner">
                    <img src="assets/image/star.svg" />
                </div>

                <div className="banner">
                    <div className="left_inner_banner_div">
                        <span className="dot"></span>
                        <span>
                            TOTAL JACKPOT
                        </span>
                    </div>
                    <div className="right_inner_banner_div">
                        <div>
                            <span className="right_inner_banner_div_currency">
                                <img src={appDetail.siteCoin.icon} alt="" />
                            </span>
                            <span className="chf_1">{appDetail.siteCoin.coin}</span>
                            <span>{parseFloat(TotalJackpot) < 22 ? parseFloat(TotalJackpot) + 22 : TotalJackpot}</span>
                        </div>
                        {/* <div>
                            <span className="chf_1">$</span>
                            <span>{TotalJackpotDollar}</span>
                        </div> */}
                    </div>
                </div>

                <div className="row resHomeRow">
                    <div className="quick_submit">
                        <img className="left" src="assets/image/pattern.svg" />
                        <img className="right" src="assets/image/pattern.svg" />

                        <div className="star_of_draw">
                            {Array.from(Array(drawCount), (e, i) => {
                                return <img src="assets/image/star%20white.svg" alt="" key={i} />
                            })}
                        </div>

                        <div className="number_of_draw">
                            <div id="minus" onClick={() => changeDrawCount('-')}>-</div>
                            <div id="draw_number">{drawCount}</div>
                            <div id="plus" onClick={() => changeDrawCount('+')}>+</div>
                        </div>

                        <NavLink to={{
                            pathname: "/buyTicket",
                            aboutProps: { NoTicketSend: drawCount }
                        }}>
                            <div id="quick_submit_btn">
                                Submit Quick Tip
                            </div>
                        </NavLink>
                    </div>

                    <div className="horizontal_clear"></div>

                    <div className="lottery_timer">
                        <img src="/assets/image/giftIcon.png" alt="" />
                        <div className="hold_on_text">
                            NEXT LOTTERY HOLD ON
                        </div>
                        <div className="homeCountdown">
                            {currentLottery === '' || currentLottery === 'pass' ?
                                <div className="NoLotteryBox">
                                    No lottery exists
                                </div>
                                :
                                <FlipCountdown
                                    theme='light'
                                    titlePosition='bottom'
                                    hideYear
                                    hideMonth
                                    endAt={currentLottery} // Date/Time
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="homeLottResultmain">
                    <div className="HLR-header">
                        <div className="HLR-headerText">
                            LAST USERS
                        </div>
                        <NavLink to="/lottery_players"> {/* lottery_explore */}
                            <div className="HLR-headerLink">View More</div>
                        </NavLink>
                    </div>
                    <div className="HLR-body">

                        {LotteryPlayers.map((player, index) =>

                            <>
                                {index < 3 &&
                                    <>
                                        <div className="HLR-card">
                                            <img src={`/assets/image/ticketHome.png`} alt="" className="HLR-cardImage" />
                                            <div className="HLR-cardDate">
                                                {`${player.player_address.substring(0, 9)}....${player.player_address.substring(player.player_address.length - 9, player.player_address.length)}`}
                                            </div>
                                            <Swiper
                                                effect={'creative'}
                                                // creativeEffect={{
                                                //     prev: {
                                                //         shadow: true,
                                                //         translate: ['-125%', 0, -800],
                                                //         rotate: [0, 0, -90],
                                                //     },
                                                //     next: {
                                                //         shadow: true,
                                                //         translate: ['125%', 0, -800],
                                                //         rotate: [0, 0, 90],
                                                //     },
                                                // }}
                                                creativeEffect={{
                                                    prev: {
                                                        shadow: true,
                                                        translate: [0, 0, -800],
                                                        rotate: [180, 0, 0],
                                                    },
                                                    next: {
                                                        shadow: true,
                                                        translate: [0, 0, -800],
                                                        rotate: [-180, 0, 0],
                                                    },
                                                }}

                                                modules={[EffectCreative, Autoplay]}
                                                autoplay={{
                                                    delay: 3000,
                                                    disableOnInteraction: false,
                                                }}
                                                className="HLR-cardNumbersSlider"
                                            >
                                                {player?.numbers?.map((numbersArr, index) =>
                                                    <>
                                                        <SwiperSlide>

                                                            <div className="HLR-cardNumbers">

                                                                <div className="HLR-cardNumbers5Box">
                                                                    {numbersArr?.map((Number, i) =>
                                                                        <div className={`HLR-cardNumbers5`}>{Number}</div>
                                                                    )}

                                                                </div>
                                                                <div className="HLR-cardNumbers2Box">
                                                                    {index + 1}X
                                                                    {/* {player?.numbers?.length * 0.01} ETH */}
                                                                    {/* {player.jackpots.map((Number, i) => */}
                                                                    {/* {Array.from(Array(2), (Number, i) =>
                                                                    <div className="HLR-cardNumbers2">
                                                                        <img src="/assets/image/PolygonStar.png" alt="" />
                                                                        {Number}
                                                                    </div>
                                                                )} */}

                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    </>
                                                )}
                                            </Swiper>
                                        </div>
                                    </>
                                }

                            </>
                        )}


                        {/* {LastLottery.map((lottery, index) =>
                            <div className="HLR-card">
                                <img src={`/assets/image/ticketHome.png`} alt="" className="HLR-cardImage" />
                                <div className="HLR-cardDate">
                                    {(() => {
                                        let endLottery = lottery.end_date;

                                        var result = new Date(endLottery);
                                        var newDate = moment(result).format("DD MMMM YYYY");

                                        return newDate
                                    })()}
                                </div>
                                <div className="HLR-cardNumbers">
                                    <div className="HLR-cardNumbers5Box">
                                        {lottery.numbers.map((Number, i) =>
                                            <div className={`HLR-cardNumbers5`}>{Number}</div>
                                        )}

                                    </div>
                                    <div className="HLR-cardNumbers2Box">
                                        {lottery.jokers.map((Number, i) =>
                                            <div className="HLR-cardNumbers2">
                                                <img src="/assets/image/PolygonStar.png" alt="" />
                                                {Number}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        )} */}

                    </div>
                </div>

                <div className="howToPlayHomeMain">
                    <div className="HTPH-left">
                        <div className="HTPH-leftHeader">
                            {/* <img src={`${handleShowImage('starRed')}`} alt="" /> */}
                            <div className="HTPH-leftHeaderText">How To Play</div>
                        </div>
                        <div className="HTPH-leftBody">

                            <div className="HTPH-leftCard">
                                <div className="HTPH-leftCardNum">1</div>
                                <div className="HTPH-leftCardText">
                                    Select your five lucky numbers between 1 and 50.
                                </div>
                            </div>

                            <div className="HTPH-leftCard">
                                <div className="HTPH-leftCardNum">2</div>
                                <div className="HTPH-leftCardText">
                                    Choose your other lucky numbers if you have.
                                </div>
                            </div>

                            <div className="HTPH-leftCard">
                                <div className="HTPH-leftCardNum">3</div>
                                <div className="HTPH-leftCardText">
                                    Submit Your Ticket & wait For Results.
                                </div>
                            </div>

                            {/* <div className="HTPH-leftCard">
                                <div className="HTPH-leftCardNum">4</div>
                                <div className="HTPH-leftCardText">
                                    Submit Your Ticket & wait For Results
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <div className="HTPH-right">
                        <img src={`/assets/image/howToPlay.png`} alt="" />
                    </div>
                </div>

                <div className="homeShowMoreDetail">
                    <div className="HSMD-box">
                        <img src={`/assets/image/HomeDetail.png`} alt="" />
                        <div className="HSMD-boxTitle">COIN</div>
                        <div className="HSMD-boxText">
                            Utility Crypto CoinIs Representative Revenue Share of Casino
                        </div>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="HSMD-boxBtn">
                                Visit Website
                            </div>
                        </a>
                    </div>

                    <div className="latestWidrawalHome">
                        <div className="LWH-header">
                            <div className="LWH-headerText">Users</div>
                            <NavLink to="/lottery_players"> {/* /withdrawal */}
                                <div className="LWH-headerLink">View More</div>
                            </NavLink>
                        </div>
                        <div className="LWH-body">
                            <table className="secondSiteTable">
                                <tr>
                                    {/* <th>Amount</th> */}
                                    <th>User</th>
                                    <th>Numbers</th>
                                </tr>

                                {LotteryPlayersFull.map((player, index) =>

                                    <tr className="homeTableTr">
                                        {/* <td>
                                            <img src="/assets/image/redDollar.svg" alt="" />
                                            <span>{convertWeiToEth(player.value)}</span>
                                            <span>{appDetail.coin}</span>
                                        </td> */}
                                        <td>
                                            {/* {player.player_address ? player.player_address : '-----'} */}
                                            {`${player.player_address.substring(0, 8)}....${player.player_address.substring(player.player_address.length - 8, player.player_address.length)}`}
                                        </td>
                                        <td>
                                            {/* {player?.numbers[player?.numbers?.length - 1].toString()} */}

                                            <div className="HLR-cardNumbers5Box fullPlayer">
                                                {player?.numbers?.[0]?.map((Number, i) =>
                                                    <div className={`HLR-cardNumbers5`}>{Number}</div>
                                                )}

                                            </div>
                                        </td>
                                    </tr>
                                )}

                            </table>

                            {LotteryPlayers.length === 0 && <><div className="LWH-headerText" style={{ textAlign: "center" }}>No Player</div></>}

                        </div>
                    </div>

                </div>

                <div className="homeAboutSiteMain">
                    <div className="HASM-left">
                        <div className="HASM-leftTitle">
                            About Lottery
                        </div>
                        <div className="HASM-leftText">
                            Lottery is an international and top grade lottery system. The lottery completely takes place on Blockchain. In this way, Lottery is set apart from the existing and traditional systems. Using Blockchain technology will guarantee a level of full transparency and security for everyone, such that no other lottery system can provide. Among many important features of this system is that the winning money is kept in a wallet throughout the entire process, so that it can be clearly seen in the explorer by all users. In addition, the tickets issued, are in fact NFTs that have been issued on the Blockchain of Counos H. Thus, ensuring complete transparency, security, and a tamper-proof lottery process.
                        </div>
                    </div>
                    <img src={`/assets/image/homeAbout.png`} alt="" />
                </div>
            </div>
        </>
    );
};

export default Dashboard;
