import React, {useEffect, useRef, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import _, {cloneDeep} from "lodash";
import {Helmet} from 'react-helmet';
import axios from 'axios';
import Select from 'react-select';

import {apiUrl} from '../../utils/config';
import {useHistory, useLocation} from 'react-router';
import {toast} from 'react-toastify';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getBalance} from '../../utils/contractWeb3';
import {convertWeiToEth} from "../../utils";


const Wallet = (props) => {

    const appDetail = useSelector(state => state.app);

    const [Coins, setCoins] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState('Deposit');
    const [ErrorPage, setErrorPage] = useState('');
    const [PaymentAmount, setPaymentAmount] = useState(0);
    const [SelectCoinId, setSelectCoinId] = useState(0);
    const [WalletBalance, setWalletBalance] = useState(0);

    const [DepositDetail, setDepositDetail] = useState([]);

    const [DepositBox, setDepositBox] = useState(false);

    const [UserTransactions, setUserTransactions] = useState({
        "withdraws": [],
        "deposits": [],
        "buy_ticket": [],
        "win": []
    });

    const [_2FAWithdraw, set_2FAWithdraw] = useState('')
    const modalTemp = useRef(null);

    const [CreateAllArray, setCreateAllArray] = useState([]);

    const SelectOptions = [
        {value: 'ALL', label: 'ALL'},
        {value: 'DEPOSIT', label: 'DEPOSIT'},
        {value: 'WITHDRAWAL', label: 'WITHDRAWAL'},
        {value: 'Buy Ticket', label: 'Buy Ticket'},
        {value: 'WIN', label: 'WIN'},
    ];

    const defaultOption = SelectOptions[0];


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#111',
            backgroundColor: state.isSelected ? '#b92f28' : '',
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 160,
            display: 'flex',
            borderRadius: '30px',
            backgroundColor: 'rgba(0,0,0,.2)',
            color: "#fff"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#fff'
        })
    }


    const AllTextSite = useSelector(state => state.text);

    const handleShowText = (keyText) => {

        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];

            if (textObj.key == keyText) {

                return textObj.value
            }

        }
        return null

    }


    let token = "";

    const location = useLocation();
    let history = useHistory();

    const handleCheckUser = (typeCall) => {
        const allCookie = document.cookie;
        // let token = "";
        if (allCookie.search("token") > -1) {
            token = document.cookie
                .split('; ')
                .find(row => row.startsWith('token='))
                .split('=')[1];
            return true;
        }
        if (typeCall !== "check") {
            history.push('/login')
        }
        return false;
    }

    const handleEditWalletAddress = (index = -1) => {
        modalTemp.current.style.opacity = 1;
        modalTemp.current.style.visibility = 'visible';

    }

    const handleCloseEditWallet = () => {
        modalTemp.current.style.opacity = 0;
        modalTemp.current.style.visibility = 'hidden';

    }


    const handleSetPayment = (index) => {
        // console.log('index :>> ', index);
        if (index == 0) {
            setPaymentMethods('Deposit');
        } else if (index == 1) {
            setPaymentMethods('withdraw');
        }
        setSelectCoinId(0);
        // console.log('paymentMethods :>> ', paymentMethods);
    }

    const handleGetCoins = () => {

        axios({
            url: apiUrl + "/coin",
            headers: {
                'x-auth-token': token,
            },
        }).then((response) => {
            setCoins(response.data.data)
            // console.log(moment(result).isBefore(moment()));
        }).catch((error) => {
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            }
        });
    }

    const handleSelectCoin = (id) => {
        setSelectCoinId(id)
    }

    const handleShowDepositDetail = () => {
        // 
        if (SelectCoinId == 0 || PaymentAmount == 0) {
            toast.error("please complete all inputs");
        } else {
            handleCheckUser('check');
            axios({
                url: apiUrl + "/deposit",
                method: 'post',
                headers: {
                    'x-auth-token': token,
                },
                data: {'amount': PaymentAmount, 'coin_id': SelectCoinId}
            }).then((response) => {
                console.log(response)
                toast.success(`${response.data.message}`)
                setDepositDetail(response.data.data);
                setDepositBox(true);
            }).catch((error) => {
                if (error.response.status === 403) {
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                } else {
                    toast.error(`${error.response.data.message}`)
                }
            })
        }
    }

    const handleSubmitReqWallet = () => {
        handleCheckUser('check')
        if (SelectCoinId == 0 || PaymentAmount == 0) {
            toast.error("please complete all inputs")
        } else {
            var data = {
                'amount': PaymentAmount,
                'coin_id': SelectCoinId
            };
            axios({
                url: apiUrl + "/withdraw",
                method: 'post',
                headers: {
                    'x-auth-token': token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            }).then((response) => {
                toast.success(`${response.data.message}`);
                handleEditWalletAddress()
            }).catch((error) => {
                if (error.response.status === 403) {
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                } else {
                    toast.error(`${error.response.data.message}`)
                }
            })
        }
    }

    const handleVerifyWithdraw = () => {
        handleCheckUser('check')

        if (_2FAWithdraw == 0) {
            toast.error("please Enter your 2FA Code")
        } else {

            axios({
                url: apiUrl + "/withdraw/verify/" + _2FAWithdraw,
                method: 'get',
                headers: {
                    'x-auth-token': token,
                },
            }).then((response) => {
                toast.success(`${response.data.message}`);
                handleCloseEditWallet();
                window.location.href = '/';
            }).catch((error) => {
                if (error.response.status === 403) {
                    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    Window.location.href = '/login'
                } else {
                    toast.error(`${error.response.data.message}`)
                }
            })
        }
    }

    const compareArray = (a, b) => {
        if (a.created_at.split('T')[0] < b.created_at.split('T')[0]) {
            return -1;
        }
        if (a.created_at.split('T')[0] > b.created_at.split('T')[0]) {
            return 1;
        }
        return 0;
    }

    const handleGetTransactions = () => {

        axios({
            url: apiUrl + "/transaction",
            method: 'get',
            headers: {
                'x-auth-token': token,
            },
        }).then((response) => {

            var allTransaction = [];
            var userAllTransaction = {"withdraws": [], "deposits": [], "buy_ticket": [], "win": []};


            for (let i = 0; i < response.data.data['withdraws'].length; i++) {
                const element = response.data.data['withdraws'][i];
                if (element.lottery_id !== null) {
                    element.type = "withdraws";
                    allTransaction.push(element);
                    userAllTransaction["withdraws"].push(element);
                } else {
                }
            }
            for (let i = 0; i < response.data.data['deposits'].length; i++) {
                const element = response.data.data['deposits'][i];
                if (element.lottery_id !== null) {
                    element.type = "deposits";
                    allTransaction.push(element);
                    userAllTransaction["deposits"].push(element);
                } else {
                }
            }
            for (let i = 0; i < response.data.data['buy_ticket'].length; i++) {
                const element = response.data.data['buy_ticket'][i];
                if (element.lottery_id !== null) {
                    element.type = "buy_ticket";
                    allTransaction.push(element);
                    userAllTransaction["buy_ticket"].push(element);
                } else {
                }
            }
            for (let i = 0; i < response.data.data['win'].length; i++) {
                const element = response.data.data['win'][i];
                if (element.lottery_id !== null) {
                    element.type = "win";
                    allTransaction.push(element);
                    userAllTransaction["win"].push(element);
                } else {
                }
            }


            allTransaction.sort(compareArray);
            allTransaction.reverse();


            // compareArray

            setCreateAllArray(allTransaction);
            setUserTransactions(userAllTransaction);

        }).catch((error) => {
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            } else {
                toast.error(`${error.response.data.message}`)
            }
        })

    }


    const getInfoUser = () => {
        axios({
            url: apiUrl + "/client",
            method: 'get',
            headers: {
                'x-auth-token': token,
            },
        }).then((response) => {
            setWalletBalance(response.data.data.wallet.toFixed(0))
        }).catch((error) => {
            if (error.response.status === 403) {
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                Window.location.href = '/login'
            } else {
                toast.error(`${error.response.data.message}`)
            }
        })
    }

    const resChangeTabs = (e) => {
        var index = SelectOptions.indexOf(e);
        document.getElementsByClassName("HPN-sortByTabsCard")[index].click();
        // document.getElementsByClassName("HPN-sortByTabsCard")[e.target.selectedIndex].click();
    }

    useEffect(() => {
        // handleCheckUser('check');
        // getInfoUser();
        // handleGetCoins();
        // handleGetTransactions();
        // if(props.location.state != null ){

        //     setPaymentAmount(props.location.state);

        // }
    }, [])

    return (
        <>
            <Helmet>
                <title>Wallet</title>
            </Helmet>


            <div className="siteModalmain" ref={modalTemp}>
                <div className="siteModalBox">

                    <div className="modalClosebtn" onClick={handleCloseEditWallet}>
                        &#10006;
                    </div>

                    <div className="modalImage">
                        <div className="modalImageTitle"> 2FA WithDraw</div>
                    </div>


                    <div className="authInputBox modalInputBox">
                        {/* <label htmlFor="authPass">First Name</label> */}
                        <input type="text" id="authPass" className="inputSite" placeholder={`Your 2FA WithDraw`}
                               onChange={e => set_2FAWithdraw(e.target.value)}
                        />
                    </div>

                    <div className="btnTemp1 customRegisterbtn" onClick={() => handleVerifyWithdraw()}>
                        Submit
                        {/* <img src="/assets/image/arrow4.svg" alt="" /> */}
                    </div>


                </div>
            </div>


            {!DepositBox ?
                <>
                    <div className="walletBalance">
                        <div className="walletBalanceTitle">Wallet Balance</div>
                        <div className="walletBalanceVal">
                            <div>{appDetail.wallet.balance}</div>
                            <div>{appDetail.siteCoin.coin}</div>
                        </div>
                    </div>
                    {/* <div className="alertNoteSite">
                    <div className="ANS-title">Note :</div>
                    <div className="ANS-text">
                        {handleShowText("wallet_note")}
                    </div>
                </div> */}
                    {/* <Tabs defaultIndex={0} onSelect={index => handleSetPayment(index)}>
                    <div className="paymentBox">
                        <div className="paymentType">
                            <TabList>
                                <Tab className="PT-card">Deposit</Tab>
                                <Tab className="PT-card">Withdrawal</Tab>
                            </TabList>
                        </div>
                        <div className="paymentBody">
                            <TabPanel>
                                <div className="paymentAmount">
                                    <div className="paymentAmountTitle">Deposit Amount</div>
                                    <div className="paymentAmountIInput">
                                        <label for="depositAmount">XBit</label>
                                        <input type="text" id="depositAmount" className="inputSite" placeholder="Amount"
                                        onChange={e => setPaymentAmount(e.target.value)}
                                        defaultValue={PaymentAmount > 0 ? PaymentAmount : ''}
                                        />
                                    </div>
                                </div>
                                <div className="paymentMethodBox">
                                    <div className="paymentMethodBoxTitle">Select Payment method</div>
                                    <div className="paymentMethodBoxBody">
                                        {
                                            Coins.map((coin,index) =>
                                                <div className={`PMBB-card ${coin.id == SelectCoinId ? "activeCoin" : ""}`} onClick={() => handleSelectCoin(coin.id)}>
                                                    <div className="PMBB-cardImage">
                                                        <img src={coin.icon} alt={coin.name} />
                                                    </div>
                                                    <div className="PMBB-cardTitle">{coin.symbol}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="paymentBodyFooter">
                                    <div className="PBF-error">
                                        {!_.isEmpty(ErrorPage)? 
                                                <div className="alertErrorSite1">
                                                    <div className="AES-title">Error :</div>
                                                    <div className="AES-text">
                                                        select payment methode
                                                    </div>
                                                </div>
                                        : null}
                                    </div>
                                    <div className="btnTemp PBF-btn" onClick={handleShowDepositDetail}>
                                        Continue
                                        <img src="/assets/image/arrow4.svg" alt="" />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="paymentAmount">
                                    <div className="paymentAmountTitle">Withdrawal Amount</div>
                                    <div className="paymentAmountIInput">
                                        <label for="WithdrawalAmount">XBit</label>
                                        <input type="text" id="WithdrawalAmount" className="inputSite" placeholder="Amount" 
                                        onChange={e => setPaymentAmount(e.target.value)} />
                                    </div>
                                </div>
                                <div className="paymentMethodBox">
                                    <div className="paymentMethodBoxTitle">Select Payment method</div>
                                    <div className="paymentMethodBoxBody">
                                    {
                                            Coins.map((coin,index) =>
                                            <>
                                                {coin.symbol == 'xbt' ? 
                                                    
                                                    <div className={`PMBB-card ${coin.id == SelectCoinId ? "activeCoin" : ""}`} onClick={() => handleSelectCoin(coin.id)}>
                                                        <div className="PMBB-cardImage">
                                                            <img src={coin.icon} alt={coin.name} />
                                                        </div>
                                                        <div className="PMBB-cardTitle">{coin.symbol}</div>
                                                    </div>
                                                :null}
                                            </>
                                                
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="paymentBodyFooter">
                                    <div className="PBF-error">
                                        {!_.isEmpty(ErrorPage)? 
                                                <div className="alertErrorSite1">
                                                    <div className="AES-title">Error :</div>
                                                    <div className="AES-text">
                                                        select payment methode
                                                    </div>
                                                </div>
                                        : null}
                                    </div>
                                    <div className="btnTemp PBF-btn" onClick={handleSubmitReqWallet}>
                                        Continue
                                        <img src="/assets/image/arrow4.svg" alt="" />
                                    </div>
                                </div>
                            </TabPanel>

                        </div>
                    </div>

                </Tabs> */}

                    <div className="historyPaymentMain">

                        <Tabs>
                            <div className="HPN-sortBox">
                                <div className="HPN-sortBy">
                                    <div className="HPN-sortByTitle">Sort By</div>
                                    <div className="HPN-sortByTabs">
                                        <TabList>
                                            <Tab className="HPN-sortByTabsCard">ALL</Tab>
                                            <Tab className="HPN-sortByTabsCard">DEPOSIT</Tab>
                                            <Tab className="HPN-sortByTabsCard">WITHDRAWAL</Tab>
                                            <Tab className="HPN-sortByTabsCard">Buy Ticket</Tab>
                                            <Tab className="HPN-sortByTabsCard">WIN</Tab>
                                        </TabList>
                                    </div>

                                    <div className="HPN-sortByTabsRes">

                                        <Select
                                            styles={customStyles}
                                            options={SelectOptions}
                                            onChange={(e) => resChangeTabs(e)}
                                            defaultValue={defaultOption}
                                            placeholder="Select an option"
                                        />

                                    </div>
                                </div>
                                <div className="HPN-sortBoxRight">
                                    {/* <img src="/assets/image/CAL.svg" alt="" /> */}
                                    <NavLink to="/buyTicket">
                                        <div className="btnTemp HPN-sortBtn">Buy Ticket</div>
                                    </NavLink>
                                </div>
                            </div>

                            <TabPanel>
                                <table className="siteTable historyPaymentTable">
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Coin name</th>
                                    </tr>
                                    <tbody>
                                    {CreateAllArray.map((transaction, index) =>
                                        <>
                                            {transaction.type == 'withdraws' ?

                                                <tr className="siteTableTr">
                                                    <td>{transaction.created_at.split('T')[0]}</td>
                                                    <td>Withdraw , Tx id : {transaction.tx_id}</td>
                                                    <td>- {transaction.amount}</td>
                                                    <td>{transaction.coin_name}</td>
                                                </tr>

                                                :
                                                transaction.type == 'deposits' ?
                                                    <tr className="siteTableTr">
                                                        <td>{transaction.created_at.split('T')[0]}</td>
                                                        <td>Deposit , Tx id : {transaction.tx_id}</td>
                                                        <td>+ {transaction.amount}</td>
                                                        <td>{transaction.coin_name}</td>
                                                    </tr>

                                                    : transaction.type == 'buy_ticket' ?
                                                        <tr className="siteTableTr">
                                                            <td>{transaction.created_at.split('T')[0]}</td>
                                                            <td>Buy Ticket</td>
                                                            <td>- {transaction.lottery_id.draw_price}</td>
                                                            <td>XBit</td>
                                                        </tr>
                                                        : transaction.type == 'win' ?
                                                            <tr className="siteTableTr">
                                                                <td>{transaction.created_at.split('T')[0]}</td>
                                                                <td>Win</td>
                                                                <td>{transaction.prize}</td>
                                                                <td>XBit</td>
                                                            </tr>
                                                            : null}

                                        </>
                                    )}


                                    </tbody>
                                </table>


                                {CreateAllArray.map((transaction, index) =>
                                    <>
                                        {transaction.type == 'withdraws' ?
                                            <>
                                                {transaction.tx_id ?

                                                    <div className="historyPaymentResCard">

                                                        <div className="HPRC-box">
                                                            <div className="HPRC-boxTitle">Date</div>
                                                            <hr className="HPRC-boxHr"/>
                                                            <div
                                                                className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                                        </div>

                                                        <div className="HPRC-box HPRC-trans">
                                                            <div>
                                                                <div className="HPRC-boxTitle">Description</div>
                                                                <hr className="HPRC-boxHr"/>
                                                            </div>
                                                            <div className="HPRC-boxvalue HPRC-tx">Withdraw , Tx id
                                                                : {transaction.tx_id}</div>
                                                        </div>

                                                        <div className="HPRC-box HPRC-trans">
                                                            <div>
                                                                <div className="HPRC-boxTitle">amount</div>
                                                                <hr className="HPRC-boxHr"/>
                                                            </div>
                                                            <div className="HPRC-boxvalue">- {transaction.amount}</div>
                                                        </div>

                                                        <div className="HPRC-box">
                                                            <div className="HPRC-boxTitle">coin name</div>
                                                            <hr className="HPRC-boxHr"/>
                                                            <div className="HPRC-boxvalue">{transaction.coin_name}</div>
                                                        </div>

                                                    </div>
                                                    : null}
                                            </>

                                            :
                                            transaction.type == 'deposits' ?
                                                <>
                                                    {transaction.tx_id ?
                                                        <div className="historyPaymentResCard">

                                                            <div className="HPRC-box">
                                                                <div className="HPRC-boxTitle">Date</div>
                                                                <hr className="HPRC-boxHr"/>
                                                                <div
                                                                    className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                                            </div>

                                                            <div className="HPRC-box HPRC-trans">
                                                                <div>
                                                                    <div className="HPRC-boxTitle">Description</div>
                                                                    <hr className="HPRC-boxHr"/>
                                                                </div>
                                                                <div className="HPRC-boxvalue HPRC-tx">Deposit , Tx id
                                                                    : {transaction.tx_id}</div>
                                                            </div>

                                                            <div className="HPRC-box HPRC-trans">
                                                                <div>
                                                                    <div className="HPRC-boxTitle">amount</div>
                                                                    <hr className="HPRC-boxHr"/>
                                                                </div>
                                                                <div
                                                                    className="HPRC-boxvalue">+ {transaction.amount}</div>
                                                            </div>

                                                            <div className="HPRC-box">
                                                                <div className="HPRC-boxTitle">coin name</div>
                                                                <hr className="HPRC-boxHr"/>
                                                                <div
                                                                    className="HPRC-boxvalue">{transaction.coin_name}</div>
                                                            </div>

                                                        </div>
                                                        : null}
                                                </>

                                                : transaction.type == 'buy_ticket' ?
                                                    <div className="historyPaymentResCard">

                                                        <div className="HPRC-box">
                                                            <div className="HPRC-boxTitle">Date</div>
                                                            <hr className="HPRC-boxHr"/>
                                                            <div
                                                                className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                                        </div>

                                                        <div className="HPRC-box">
                                                            <div className="HPRC-boxTitle">Description</div>
                                                            <hr className="HPRC-boxHr"/>
                                                            <div className="HPRC-boxvalue HPRC-BuyTicket">Buy Ticket</div>
                                                        </div>

                                                        <div className="HPRC-box">
                                                            <div className="HPRC-boxTitle">amount</div>
                                                            <hr className="HPRC-boxHr"/>
                                                            <div
                                                                className="HPRC-boxvalue">-{transaction.lottery_id.draw_price}</div>
                                                        </div>

                                                        <div className="HPRC-box">
                                                            <div className="HPRC-boxTitle">coin name</div>
                                                            <hr className="HPRC-boxHr"/>
                                                            <div className="HPRC-boxvalue">XBit</div>
                                                        </div>

                                                    </div>
                                                    : transaction.type == 'win' ?
                                                        <div className="historyPaymentResCard">

                                                            <div className="HPRC-box">
                                                                <div className="HPRC-boxTitle">Date</div>
                                                                <hr className="HPRC-boxHr"/>
                                                                <div
                                                                    className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                                            </div>

                                                            <div className="HPRC-box">
                                                                <div className="HPRC-boxTitle">Description</div>
                                                                <hr className="HPRC-boxHr"/>
                                                                <div className="HPRC-boxvalue">Win</div>
                                                            </div>

                                                            <div className="HPRC-box">
                                                                <div className="HPRC-boxTitle">amount</div>
                                                                <hr className="HPRC-boxHr"/>
                                                                <div className="HPRC-boxvalue">{transaction.prize}</div>
                                                            </div>

                                                            <div className="HPRC-box">
                                                                <div className="HPRC-boxTitle">coin name</div>
                                                                <hr className="HPRC-boxHr"/>
                                                                <div className="HPRC-boxvalue">XBit</div>
                                                            </div>

                                                        </div>
                                                        : null}

                                    </>
                                )}


                                {/* <div className="sitePagination">
                                    <div className="sitePaginationCard active">1</div>
                                    <div className="sitePaginationCard">2</div>
                                    <div className="sitePaginationCard">3</div>
                                    <div className="sitePaginationCard">4</div>
                                    <div className="sitePaginationCard">5</div>
                                </div> */}
                            </TabPanel>

                            <TabPanel>
                                <table className="siteTable historyPaymentTable">
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Coin name</th>
                                    </tr>
                                    <tbody>
                                    {UserTransactions["deposits"].sort(compareArray).reverse().map((transaction, index) =>
                                        <>
                                            {transaction.tx_id ?

                                                <tr className="siteTableTr">
                                                    <td>{transaction.created_at.split('T')[0]}</td>
                                                    <td>Deposit , Tx id : {transaction.tx_id}</td>
                                                    <td>+ {transaction.x_amount}</td>
                                                    <td>{transaction.coin_name}</td>
                                                </tr>
                                                : null}
                                        </>
                                    )}

                                    </tbody>
                                </table>

                                {UserTransactions["deposits"].sort(compareArray).reverse().map((transaction, index) =>
                                    <>
                                        {transaction.tx_id ?

                                            <div className="historyPaymentResCard">

                                                <div className="HPRC-box">
                                                    <div className="HPRC-boxTitle">Date</div>
                                                    <hr className="HPRC-boxHr"/>
                                                    <div
                                                        className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                                </div>

                                                <div className="HPRC-box HPRC-trans">
                                                    <div>
                                                        <div className="HPRC-boxTitle">Description</div>
                                                        <hr className="HPRC-boxHr"/>
                                                    </div>
                                                    <div className="HPRC-boxvalue HPRC-tx">Deposit , Tx id
                                                        : {transaction.tx_id}</div>
                                                </div>

                                                <div className="HPRC-box HPRC-trans">
                                                    <div>
                                                        <div className="HPRC-boxTitle">amount</div>
                                                        <hr className="HPRC-boxHr"/>
                                                    </div>
                                                    <div className="HPRC-boxvalue">+ {transaction.amount}</div>
                                                </div>

                                                <div className="HPRC-box">
                                                    <div className="HPRC-boxTitle">coin name</div>
                                                    <hr className="HPRC-boxHr"/>
                                                    <div className="HPRC-boxvalue">{transaction.coin_name}</div>
                                                </div>

                                            </div>
                                            : null}
                                    </>
                                )}


                            </TabPanel>

                            <TabPanel>
                                <table className="siteTable historyPaymentTable">
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Coin name</th>
                                    </tr>
                                    <tbody>
                                    {UserTransactions["withdraws"].sort(compareArray).reverse().map((transaction, index) =>
                                        <>
                                            {transaction.tx_id ?

                                                <tr className="siteTableTr">
                                                    <td>{transaction.created_at.split('T')[0]}</td>
                                                    <td>Withdraw , Tx id : {transaction.tx_id}</td>
                                                    <td>- {transaction.amount}</td>
                                                    <td>{transaction.coin_name}</td>
                                                </tr>

                                                : null}
                                        </>
                                    )}


                                    </tbody>
                                </table>

                                {UserTransactions["withdraws"].sort(compareArray).reverse().map((transaction, index) =>
                                    <>
                                        {transaction.tx_id ?

                                            <div className="historyPaymentResCard">

                                                <div className="HPRC-box">
                                                    <div className="HPRC-boxTitle">Date</div>
                                                    <hr className="HPRC-boxHr"/>
                                                    <div
                                                        className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                                </div>

                                                <div className="HPRC-box HPRC-trans">
                                                    <div>
                                                        <div className="HPRC-boxTitle">Description</div>
                                                        <hr className="HPRC-boxHr"/>
                                                    </div>
                                                    <div className="HPRC-boxvalue HPRC-tx">Withdraw , Tx id
                                                        : {transaction.tx_id}</div>
                                                </div>

                                                <div className="HPRC-box HPRC-trans">
                                                    <div>
                                                        <div className="HPRC-boxTitle">amount</div>
                                                        <hr className="HPRC-boxHr"/>
                                                    </div>
                                                    <div className="HPRC-boxvalue">- {transaction.amount}</div>
                                                </div>

                                                <div className="HPRC-box">
                                                    <div className="HPRC-boxTitle">coin name</div>
                                                    <hr className="HPRC-boxHr"/>
                                                    <div className="HPRC-boxvalue">{transaction.coin_name}</div>
                                                </div>

                                            </div>


                                            : null}

                                    </>
                                )}
                            </TabPanel>

                            <TabPanel>
                                <table className="siteTable historyPaymentTable">
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Coin name</th>
                                    </tr>
                                    <tbody>
                                    {UserTransactions["buy_ticket"].sort(compareArray).reverse().map((transaction, index) =>
                                        <>


                                            <tr className="siteTableTr">
                                                <td>{transaction.created_at.split('T')[0]}</td>
                                                <td>Buy Ticket</td>
                                                <td>{transaction.lottery_id.draw_price}</td>
                                                <td>XBit</td>
                                            </tr>

                                        </>
                                    )}


                                    </tbody>
                                </table>

                                {UserTransactions["buy_ticket"].sort(compareArray).reverse().map((transaction, index) =>
                                    <>

                                        <div className="historyPaymentResCard">

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">Date</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div
                                                    className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                            </div>

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">Description</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div className="HPRC-boxvalue HPRC-BuyTicket">Buy Ticket</div>
                                            </div>

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">amount</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div className="HPRC-boxvalue">{transaction.lottery_id.draw_price}</div>
                                            </div>

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">coin name</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div className="HPRC-boxvalue">XBit</div>
                                            </div>

                                        </div>

                                    </>
                                )}
                            </TabPanel>

                            <TabPanel>
                                <table className="siteTable historyPaymentTable">
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Coin name</th>
                                    </tr>
                                    <tbody>
                                    {UserTransactions["win"].sort(compareArray).reverse().map((transaction, index) =>
                                        <>


                                            <tr className="siteTableTr">
                                                <td>{transaction.created_at.split('T')[0]}</td>
                                                <td>Win</td>
                                                <td>{transaction.prize}</td>
                                                <td>XBit</td>
                                            </tr>

                                        </>
                                    )}


                                    </tbody>
                                </table>

                                {UserTransactions["win"].sort(compareArray).reverse().map((transaction, index) =>
                                    <>

                                        <div className="historyPaymentResCard">

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">Date</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div
                                                    className="HPRC-boxvalue">{transaction.created_at.split('T')[0]}</div>
                                            </div>

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">Description</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div className="HPRC-boxvalue">Win</div>
                                            </div>

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">amount</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div className="HPRC-boxvalue">{transaction.prize}</div>
                                            </div>

                                            <div className="HPRC-box">
                                                <div className="HPRC-boxTitle">coin name</div>
                                                <hr className="HPRC-boxHr"/>
                                                <div className="HPRC-boxvalue">XBit</div>
                                            </div>

                                        </div>

                                    </>
                                )}
                            </TabPanel>

                        </Tabs>

                    </div>
                </>
                :
                <div className="mainDepositBox">
                    <div className="depositQrCode">
                        <img src={DepositDetail.qr_code} alt=""/>
                    </div>

                    <div className="depositAddress">
                        Coin : {DepositDetail.coin.name}
                    </div>

                    <div className="depositAddress">
                        Amount : {DepositDetail.amount}
                    </div>

                    <div className="depositAddress">
                        Wallet : {DepositDetail.address}
                    </div>

                    <div className="btnTemp1" onClick={() => setDepositBox(false)}>Go Back</div>

                </div>
            }

        </>
    );
}

export default Wallet;