import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from "axios";

import { apiUrl } from '../../utils/config';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import Cookies from "universal-cookie";
import { createTransaction, enterToLottery, enterToLotteryArr, getDetailOfLottery, getIsDrawingCompleted, signWallet, updateActiveBalance } from '../../utils/contractWeb3';

const cookies = new Cookies();


const BuyTicket = (props) => {

    const [NoTicket, setNoTicket] = useState(1);

    const [arrOfNoTicket, setArrOfNoTicket] = useState([{ '5No': [], '2No': [] }])

    const [emptyTicketNo, setEmptyTicketNo] = useState([]);

    const [fullTicketNo, setfullTicketNo] = useState([]);

    const [ClickTicketNo, setClickTicketNo] = useState(0);

    const [ticketPrice, setTicketPrice] = useState(0);

    const [UserWalletNo, setUserWalletNo] = useState(0);

    const [ActiveJackpotSub, setActiveJackpotSub] = useState(false);

    const [ActiveNumOfDraw, setActiveNumOfDraw] = useState(false);

    const [JackpotVal, setJackpotVal] = useState('50000');

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const jackpotIn = useRef(null);
    const drawIn = useRef(null);
    const PurchaseBtn = useRef(null);

    const [Draw_NumberState, setDraw_NumberState] = useState(1);

    let LotteryID = -1;
    let Draw_Number = 1;

    let token = "";
    let history = useHistory();

    const appDetail = useSelector(state => state.app);
    const AllTextSite = useSelector(state => state.text);
    const AllImageSite = useSelector(state => state.image);


    const handleShowText = (keyText) => {

        for (let i = 0; i < AllTextSite.length; i++) {
            const textObj = AllTextSite[i];

            if (textObj.key == keyText) {

                return textObj.value
            }

        }
        return null

    }
    const handleShowImage = (keyImage) => {
        for (let i = 0; i < AllImageSite.length; i++) {
            const imageObj = AllImageSite[i];

            if (imageObj.key == keyImage) {

                return imageObj.value
            }

        }
        return null
    }

    const handleCheckUser = (typeCall) => {
        const allCookie = document.cookie;
        // let token = "";
        if (allCookie.search("token") > -1) {
            token = document.cookie
                .split('; ')
                .find(row => row.startsWith('token='))
                .split('=')[1];
            return true;
        }
        if (typeCall !== "check") {
            history.push('/login')
        }
        return false;
    }

    const getInfoUser = () => {
        axios({
            url: apiUrl + "/client",
            method: 'get',
            headers: {
                'x-auth-token': token,
            },
        }).then((response) => {
            var user = response.data.data;

            setUserWalletNo(user.wallet.toFixed(0))
        }).catch(error => {
            console.log(error)
        })
    }

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }


    const changeDrawCount = function (opt) {
        if (opt == '-') {
            var newa = NoTicket - 1;
            if (newa < 1) {
            } else {
                let editArr = [...arrOfNoTicket];
                let emptyCopy = [...emptyTicketNo];
                let fullArrNo = [...fullTicketNo];


                editArr.pop();
                setArrOfNoTicket(editArr);
                setNoTicket(newa);

                emptyCopy.pop();
                setEmptyTicketNo(emptyCopy);

                if (fullArrNo.indexOf(newa) > -1) {
                    fullArrNo.splice(fullArrNo.indexOf(newa), 1);
                }
                setfullTicketNo(fullArrNo)


            }
        } else {
            var newa = NoTicket + 1;
            if (newa > 8) {
            } else {
                let editArr = [...arrOfNoTicket];

                editArr[newa - 1] = { '5No': [], '2No': [] }

                setNoTicket(newa)
                setArrOfNoTicket(editArr);

                let emptyCopy = [...emptyTicketNo];
                emptyCopy.push(arrOfNoTicket.length);
                setEmptyTicketNo(emptyCopy);
                setClickTicketNo(emptyCopy[0]);
            }
        }
    }

    const clearNoClicked = () => {
        for (let i = 0; i < document.getElementsByClassName('buyTicketBox5NoCard').length; i++) {
            const ele = document.getElementsByClassName('buyTicketBox5NoCard')[i];
            ele.classList.remove('active');
        }
        for (let i = 0; i < document.getElementsByClassName('buyTicketBox2NoCard').length; i++) {
            const ele = document.getElementsByClassName('buyTicketBox2NoCard')[i];
            ele.classList.remove('active');
        }
    }

    const checkFullTicket = (checkNo = -1, checkType = '') => {
        let editArr = [...arrOfNoTicket];
        let checkIndex = ClickTicketNo;

        if (checkNo !== -1) {
            checkIndex = checkNo;
        }

        // console.log(checkIndex);
        // console.log(editArr);


        if (editArr[checkIndex]["5No"].length > 4) {
            // && editArr[checkIndex]["2No"].length > 1
            let emptyCopy = [...emptyTicketNo];
            let fullArrNo = [...fullTicketNo];

            if (checkType == 'clear') {
                clearNoClicked();
                return;
            } else {



                fullArrNo.push(checkIndex);
                fullArrNo.sort();

                let unique = fullArrNo.filter(onlyUnique);


                setfullTicketNo(unique);

                // emptyCopy.shift();

                if (emptyCopy.indexOf(checkIndex) > -1) {
                    emptyCopy.splice(emptyCopy.indexOf(checkIndex), 1);
                }

                setEmptyTicketNo(emptyCopy);

                setClickTicketNo(emptyCopy[0]);

                clearNoClicked();
                forceUpdate();
                if (window.innerWidth < 1000) {

                    $('.showChooseNoInRes').slideUp(400);
                    if (emptyCopy.length > 0) {
                        handleClickTicket('e', emptyCopy[0])
                    }

                }
                return true
            }
        } else {
            if (checkType == 'checkticket') {
                toast.error("please Fill Your Selected Ticket");
                return false;
            }
        }
    }

    const generate_random_unique_numbers = (min, max, count) => {
        let arr = [];

        for (let i = min; i <= max; i++) {
            arr.push(i);
        }

        arr = arr.shuffle();

        return arr.slice(0, count)
    }

    const setNumberShuffle = (indexOfTicket = -1) => {
        let NoTicket = 0;

        if (indexOfTicket !== -1) {
            // NoTicket1 = emptyTicketNo.indexOf(indexOfTicket) ;
            NoTicket = indexOfTicket;
        }



        let editArr = [...arrOfNoTicket];

        let GetTicketEmptyIndex = emptyTicketNo.indexOf(NoTicket);

        editArr[emptyTicketNo[GetTicketEmptyIndex]]["5No"] = generate_random_unique_numbers(1, 50, 5);
        editArr[emptyTicketNo[GetTicketEmptyIndex]]["2No"] = generate_random_unique_numbers(1, 12, 2);

        const index = emptyTicketNo.indexOf(NoTicket);
        if (index > -1) {
            emptyTicketNo.splice(index, 1);
        }

        setArrOfNoTicket(editArr)
        checkFullTicket(NoTicket);
        // checkFullTicket(NoTicket,'check')
    }

    const delNumberTicket = (indexOfTicket) => {
        let editArr = [...arrOfNoTicket];
        let arrayEmptyNumber = [...emptyTicketNo];
        let fullArrNo = [...fullTicketNo];

        if (fullArrNo.indexOf(indexOfTicket) > -1) {
            fullArrNo.splice(fullArrNo.indexOf(indexOfTicket), 1);
        }

        fullArrNo.sort();
        editArr[indexOfTicket] = { '5No': [], '2No': [] };

        arrayEmptyNumber.push(indexOfTicket);
        arrayEmptyNumber.sort();
        arrayEmptyNumber = arrayEmptyNumber.filter((v, i, a) => a.indexOf(v) === i);

        setEmptyTicketNo(arrayEmptyNumber)
        setArrOfNoTicket(editArr);
        setfullTicketNo(fullArrNo);
        setClickTicketNo(arrayEmptyNumber[0]);
        if (window.innerWidth < 1000) {

            $('.showChooseNoInRes').slideUp(400);

        }

        clearNoClicked()
    }

    const selectNo5 = (e) => {
        let ele = e.target;
        let eleNo = ele.textContent;
        let editArr = [...arrOfNoTicket];


        if (ClickTicketNo == -1 || ClickTicketNo == undefined) {

        } else {

            if (ele.classList.contains("active")) {
                ele.classList.remove("active");

                if (editArr[ClickTicketNo]["5No"].indexOf(eleNo) > -1) {
                    editArr[ClickTicketNo]["5No"].splice(editArr[ClickTicketNo]["5No"].indexOf(eleNo), 1);
                }

                setArrOfNoTicket(editArr)

            } else {
                if (emptyTicketNo.length == 0) {

                } else {
                    // emptyTicketNo[0]
                    if (editArr[ClickTicketNo]["5No"].length !== 5) {
                        editArr[ClickTicketNo]["5No"].push(eleNo);
                        setArrOfNoTicket(editArr)
                        ele.classList.add("active");
                        if (editArr[ClickTicketNo]["5No"].length == 5) {
                            checkFullTicket();
                        }

                    } else {
                        // checkFullTicket(-1,'clear');
                        checkFullTicket();
                    }
                }
            }

        }

    }

    const selectNo2 = (e) => {
        let ele = e.target;
        let eleNo = ele.textContent;
        let editArr = [...arrOfNoTicket];

        if (ClickTicketNo == -1 || ClickTicketNo == undefined) {

        } else {

            if (ele.classList.contains("active")) {
                ele.classList.remove("active");

                if (editArr[ClickTicketNo]["2No"].indexOf(eleNo) > -1) {
                    editArr[ClickTicketNo]["2No"].splice(editArr[ClickTicketNo]["2No"].indexOf(eleNo), 1);
                }

                setArrOfNoTicket(editArr)
            } else {
                // let editArr = [...arrOfNoTicket];
                if (editArr[ClickTicketNo]["2No"].length !== 2) {
                    editArr[ClickTicketNo]["2No"].push(eleNo);
                    setArrOfNoTicket(editArr)
                    ele.classList.add("active");

                    if (editArr[ClickTicketNo]["2No"].length == 2) {
                        checkFullTicket();
                    }

                } else {
                    checkFullTicket();
                }
            }
        }

    }

    const handleChangeDraw = (e) => {
        const ele = e.target.value;
        // for (let i =0 ; i < document.getElementsByClassName('TPB-NoDrawsCard').length ; i++){
        //     const element = document.getElementsByClassName('TPB-NoDrawsCard')[i];
        //     element.classList.remove("active")
        // }
        // ele.classList.add("active");
        // Draw_Number = parseInt(ele.textContent);
        Draw_Number = parseInt(ele);
        if (Draw_Number > 8) {
            setDraw_NumberState(8);
            Draw_Number = 8;

        } else if (Draw_Number < 2) {
            setDraw_NumberState(2);
            Draw_Number = 2;
        } else {

            setDraw_NumberState(Draw_Number);

        }

    }

    const handleClearChangeDraw = (typeOfCheck = '') => {
        if (typeOfCheck == 'check') {

        } else {
            for (let i = 0; i < document.getElementsByClassName('TPB-NoDrawsCard').length; i++) {
                const element = document.getElementsByClassName('TPB-NoDrawsCard')[i];
                element.classList.remove("active")
            }
            document.getElementsByClassName('TPB-NoDrawsCard')[0].classList.add('active')
        }
        Draw_Number = 1;
        setDraw_NumberState(1);

    }

    const handleSetActiveTicket = (indexOfTicket) => {

        checkFullTicket(indexOfTicket);
    }

    const handleClickTicket = (e, indexOfTicket) => {

        if (indexOfTicket !== emptyTicketNo[0] && emptyTicketNo.indexOf(indexOfTicket) > -1) {
            toast.error("please fill first Empty ticket")
        } else {
            if (window.innerWidth < 1000) {

                if ($('.showChooseNoInRes').eq(indexOfTicket).css('display') == 'block') {

                } else {
                    $('.showChooseNoInRes').slideUp(400);
                    $('.showChooseNoInRes').eq(indexOfTicket).slideDown(400);
                    if (indexOfTicket !== undefined || indexOfTicket !== 'undefined') {
                        $('html, body').animate({
                            scrollTop: $('.showChooseNoInRes').eq(indexOfTicket).offset().top - 55
                        }, 1000);
                    }
                }


            }

            setClickTicketNo(indexOfTicket);
        }


    }

    const handleSubmitBuyTicket = async () => {
        if (!PurchaseBtn.current.disabled) {

            if (emptyTicketNo.length !== 0) {
                toast.error("Please select number!")
                return
            }

            try {
    
                PurchaseBtn.current.disabled = true;
                PurchaseBtn.current.style.opacity = '.5';

                if (appDetail.wallet.active === false) {
                    // console.log(data);
                    PurchaseBtn.current.disabled = true;
                    cookies.set('t', data);
                    console.log(cookies.get('t'));
                    toast.info("Please Connect your wallet To Submit your Ticket!");
                    PurchaseBtn.current.disabled = false;
                    PurchaseBtn.current.style.opacity = '1';
                    // history.push('/login')
                    return
                }
    
                const completedLottory = await getDetailOfLottery(appDetail.lottery.currentId);
    
                console.log({completedLottory});
    
                if (!completedLottory?.isDrawingCompleted) {
    
                    
    
                    /*if(LotteryID == -1){
                        await axios({
                            url:apiUrl + "/lottery/current",
                        }).then( (response) => {
                            if(response.data.data !== null){
                                LotteryID = response.data.data['_id'];
                                setTicketPrice(response.data.data.draw_price)
                            }
                        }).catch((error) => {
                            if(error.response.status === 403){
                                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                                Window.location.href = '/login'
                            }else{
                                if(error.response.status === 408){
                                    toast.error(`${error.response.data.message}`);
                                    history.push('/wallet',(NoTicket*ticketPrice)-UserWalletNo);
                                }else{
                                    toast.error(`${error.response.data.message}`);
                                }
                                PurchaseBtn.current.disabled = false;
                                PurchaseBtn.current.style.opacity = '1';
                                return
                            }
                        })
                    }*/
                    // getInfoUser
                    var data = { "numbers": [], "jackpot": [] };
                    if (ActiveNumOfDraw) {
                        data["draw_number"] = Draw_NumberState;
                    } else if (ActiveJackpotSub) {
                        data["min"] = JackpotVal;
                    }
                    for (let index = 0; index < arrOfNoTicket.length; index++) {
                        const element = arrOfNoTicket[index];
                        // data.numbers = element["5No"];
                        data.numbers.push(element["5No"]);
                        // data.jackpot = element["2No"];
                    }
    
                    console.log({
                        fff: data
                    })
    
                    if (appDetail.wallet.active === false) {
                        // console.log(data);
                        PurchaseBtn.current.disabled = true;
                        cookies.set('t', data);
                        console.log(cookies.get('t'));
                        toast.info("Please Connect your wallet To Submit your Ticket!");
                        PurchaseBtn.current.disabled = false;
                        PurchaseBtn.current.style.opacity = '1';
                        // history.push('/login')
                    } else {
    
                        if (data.numbers.length === 1) {
                            enterToLottery(data.numbers[0], "0.01").then((res) => {
                                updateActiveBalance();
                                toast.success(`Your ticker submitted successfully!`);
                                PurchaseBtn.current.disabled = false;
                                PurchaseBtn.current.style.opacity = '1';
                                cookies.remove('t');
                                history.push('/myTicket');
                            }).catch((error) => {
                                PurchaseBtn.current.disabled = false;
                                PurchaseBtn.current.style.opacity = '1';
                                toast.error(error?.response?.message);
                                console.log(error)
                            })
    
                        } else {
                            enterToLotteryArr(data.numbers, (data.numbers.length * 0.01).toString()).then((res) => {
                                updateActiveBalance();
                                toast.success(`Your ticker submitted successfully!`);
                                PurchaseBtn.current.disabled = false;
                                PurchaseBtn.current.style.opacity = '1';
                                cookies.remove('t');
                                history.push('/myTicket');
                            }).catch((error) => {
                                PurchaseBtn.current.disabled = false;
                                PurchaseBtn.current.style.opacity = '1';
                                toast.error(error?.response?.message);
                                console.log(error)
                            })
                        }
    
    
                    }
    
                }
                
            } catch (error) {
                toast.error("Your purchase has been cancelled")
                console.log(error)
                PurchaseBtn.current.disabled = false;
                PurchaseBtn.current.style.opacity = '1';
            }
        }
    }

    let cheackJackpot = 0;
    let cheackDraw = 0;

    const handleCheckBoxShow = (ele, No) => {

        if (ele == 'jackpot' && cheackJackpot == 0) {
            setActiveNumOfDraw(false);
            handleClearChangeDraw('check');
            drawIn.current.checked = false;
            cheackJackpot++;
        } else if (ele == 'draw' && cheackDraw == 0) {
            // handleClearChangeDraw('check');
            setActiveJackpotSub(false);
            setDraw_NumberState(2);
            jackpotIn.current.checked = false;
            cheackDraw++;
        } else {
            setActiveJackpotSub(false);
            setActiveNumOfDraw(false);
            setDraw_NumberState(1);
            cheackJackpot = 0;
            cheackDraw = 0;
            jackpotIn.current.checked = false;
            drawIn.current.checked = false;
        }
    }


    let NumberTicket = 1;

    useEffect(() => {

        var emptyTicketNoCopy = [];
        if (props.location.aboutProps == 'undefined' || props.location.aboutProps == undefined) {
            for (let i = 0; i < NoTicket; i++) {
                emptyTicketNoCopy.push(i);
            }
        } else {
            NumberTicket = props.location.aboutProps.NoTicketSend;

            setNoTicket(NumberTicket);
        }


        setEmptyTicketNo(emptyTicketNoCopy);
    }, [])

    useEffect(() => {
        var emptyTicketNoCopy = [];
        var editArr = [];



        for (let i = 0; i < NumberTicket; i++) {
            emptyTicketNoCopy.push(i);
        }

        for (let i = 0; i < NumberTicket; i++) {
            editArr.push({ '5No': [], '2No': [] });
        }

        setArrOfNoTicket(editArr);

        setEmptyTicketNo(emptyTicketNoCopy);



        // axios({
        //     url:apiUrl + "/lottery/current",
        // }).then( (response) => {
        //     if(response.data.data !== null){
        //         LotteryID = response.data.data['_id'];
        //         setTicketPrice(response.data.data.draw_price)
        //     }
        // }).catch((error) => {
        //     if(error.response.status === 403){
        //         document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //         Window.location.href = '/login'
        //     }else{
        //         toast.error(`${error.response.data.message}`);
        //     }
        // })

    }, []);

    useEffect(() => {
        if (handleCheckUser('check')) {
            getInfoUser();
        }

        if (cookies.get('t')) {
            var NumArrayC = [];
            var savedTicket = cookies.get('t');

            for (let i = 0; i < savedTicket.numbers.length; i++) {
                const element = savedTicket.numbers[i];
                // const element1 = savedTicket.jokers[i];
                var ob = { 
                    '5No': element,
                //  '2No': element1 
                };
                NumArrayC.push(ob);
            }

            if (savedTicket['draw_number']) {
                setDraw_NumberState(savedTicket['draw_number']);
                setActiveJackpotSub(false);
                setActiveNumOfDraw(true)
                drawIn.current.checked = true;
                cheackDraw++;
            }

            if (savedTicket['min']) {
                setActiveNumOfDraw(false);
                handleClearChangeDraw('check');
                jackpotIn.current.checked = true;
                setJackpotVal(savedTicket['min'])
                cheackJackpot++;
            }

            setArrOfNoTicket(NumArrayC);
            // setNoTicket(savedTicket.jokers.length)
        }

        if (window.innerWidth < 1000) {

            $('.showChooseNoInRes').eq(0).slideDown(400);
            $('html, body').animate({
                scrollTop: $('.resaddBuyTicket').offset().top
            }, 1000);

        }
    }, []);

    useEffect(() => {
        setTimeout(() => {

            if (cookies.get('t')) {


                $(".TCBC-tick").each(function (index) {
                    $(this).click();
                })

            }
        }, 500);
    }, [])


    return (
        <>
            <Helmet>
                <title>Buy Ticket</title>
            </Helmet>

            <div className="resaddBuyTicket">
                <div id="minus" onClick={() => changeDrawCount('-')}>-</div>
                <div> Buy {NoTicket} Ticket </div>
                <div id="plus" onClick={() => changeDrawCount('+')}>+</div>
            </div>

            {/* <img className="buyTicketImage" src={`/assets/image/ticket5star.png`} alt="" /> */}
            <div className="buyTicketMainBox">

                <div className="buyTicketDetails">
                    {/* <div> */}

                    <div className="ticketPriceAndNo">
                        <div className="ticketPriceBox">
                            <span>Ticket Price : </span>
                            <span className="TPB-Price">
                                {/* <span className="price_of_draw">{ticketPrice}</span> */}
                                <span className="price_of_draw">0.01</span>
                                <span>{appDetail.siteCoin.coin}</span>
                            </span>
                        </div>
                        <div className="ticketNoBox">
                            {/* <img src="/assets/image/Path193.svg" alt="" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.027" height="5.444"
                                viewBox="0 0 31.027 5.444" onClick={() => changeDrawCount('-')}>
                                <path id="Path_193" data-name="Path 193"
                                    d="M35.124,51.149a5.92,5.92,0,0,1,.217-.7,2.639,2.639,0,0,1,2.577-1.662h9.611l6.19-.024h9.635a2.722,2.722,0,1,1,.048,5.444H37.894a2.72,2.72,0,0,1-2.746-2.192.446.446,0,0,1-.048-.169A6.54,6.54,0,0,0,35.124,51.149Z"
                                    transform="translate(-35.1 -48.765)" fill="#fff" />
                            </svg>

                            <div>{NoTicket}</div>

                            <svg id="plus" xmlns="http://www.w3.org/2000/svg" width="19.577" height="19.56"
                                viewBox="0 0 19.577 19.56" onClick={() => changeDrawCount('+')}>
                                <path id="Path_193" data-name="Path 193"
                                    d="M35.115,45.558a3.735,3.735,0,0,1,.137-.441,1.665,1.665,0,0,1,1.626-1.049h6.307V37.747a1.718,1.718,0,1,1,3.435-.015v6.322h6.307a1.718,1.718,0,1,1,.03,3.435H46.6v6.17a1.731,1.731,0,0,1-1.459,1.885c-.015,0-.015.015-.03.015H44.66c-.015,0-.015-.015-.03-.015a1.731,1.731,0,0,1-1.459-1.885v-6.17H36.863a1.716,1.716,0,0,1-1.733-1.383A.282.282,0,0,1,35.1,46,4.126,4.126,0,0,0,35.115,45.558Z"
                                    transform="translate(-35.1 -35.999)" fill="#a5a5a5" />
                            </svg>


                            {/* <img src="/assets/image/plus.svg" alt="" /> */}
                        </div>
                    </div>

                    <div className="ticketchooseBox">
                        {Array.from(Array(NoTicket), (e, i) => {
                            return <div className={`ticketchooseBoxCard ${ClickTicketNo == i ? "written" : "deactive"} ${fullTicketNo.indexOf(i) > -1 ? "active" : ""}`}>
                                {/* ${ClickTicketNo == i ? "written" : "deactive"} */}
                                {/* {i == 0 ? <img className="TCBC-star" src="/assets/image/star.svg" alt=""/> : null} */}

                                <div className="ticketTemp ticketTemp1" onClick={(e) => handleClickTicket(e, i)}>
                                    <div className="ticketTemp ticketTemp1-5No">
                                        {Array.from(Array(5), (e, i1) => {
                                            return <div className="ticketTemp1-5NoCard">
                                                {arrOfNoTicket[i]["5No"][i1]}
                                            </div>
                                        })}

                                    </div>
                                    {/* <div className="ticketTemp ticketTemp1-2No">
                                            {Array.from(Array(2), (e, i1) => {
                                                return <div className="ticketTemp1-2NoCard">
                                                    {arrOfNoTicket[i]["2No"][i1]}
                                                </div>
                                            })}

                                        </div> */}
                                </div>
                                <img className="TCBC-trash" src="/assets/image/trash-bin.svg" alt="" onClick={() => delNumberTicket(i)} />
                                {fullTicketNo.indexOf(i) > -1 ? null :
                                    <>
                                        {/* <img className="TCBC-random" src="/assets/image/random.svg" alt="" onClick={() => setNumberShuffle(i)}/> */}
                                        {/* <img className="TCBC-tick" src="/assets/image/tick.svg" alt="" onClick={() => handleSetActiveTicket(i)}/> */}
                                    </>
                                }
                                <div className="showChooseNoInRes">
                                    <div className="buyTicketBoxBody">
                                        <div className="buyTicketBox5No">
                                            <div className="buyTicketBox5NoBox">

                                                {/* ${arrOfNoTicket[ClickTicketNo]["5No"] === i ? "active" : ""} */}

                                                {Array.from(Array(50), (e, i) => {
                                                    return <div className={`buyTicketBox5NoCard `} onClick={selectNo5}>{i + 1}</div>
                                                })}

                                            </div>
                                            {/* <div className="buyTicketBox5NoTitle">Select 5 Number</div> */}
                                        </div>
                                        <div className="buyTicketBox2No">
                                            <div className="buyTicketBox2NoBox">
                                                {Array.from(Array(6), (e, i) => {
                                                    return <div className="buyTicketBox2NoCard" onClick={selectNo2}>{i + 1}</div>
                                                })}

                                            </div>
                                            {/* <div className="buyTicketBox2NoTitle">Select 2 Number</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        })}

                    </div>
                    {/* </div> */}




                </div>

                <div className="buyTicketBox">
                    {/* <div className="buyTicketBoxTitle">BUY TICKET</div> */}
                    <div className="buyTicketBoxBody">
                        <div className="buyTicketBox5No">
                            <div className="buyTicketBox5NoBox">

                                {/* ${arrOfNoTicket[ClickTicketNo]["5No"] === i ? "active" : ""} */}

                                {Array.from(Array(50), (e, i) => {
                                    return <div className={`buyTicketBox5NoCard `} onClick={selectNo5}>{i + 1}</div>
                                })}

                            </div>
                            {/* <div className="buyTicketBox5NoTitle">Select 5 Number</div> */}
                        </div>
                        <div className="buyTicketBox2No">
                            <div className="buyTicketBox2NoBox">
                                {Array.from(Array(6), (e, i) => {
                                    return <div className="buyTicketBox2NoCard" onClick={selectNo2}>{i + 1}</div>
                                })}

                            </div>
                            {/* <div className="buyTicketBox2NoTitle">Select 2 Number</div> */}
                        </div>

                    </div>

                </div>


                <div className="ticketPurchaseBox">

                    <div className="TPB-footer">

                        <div className="btnTemp TPB-btn" onClick={handleSubmitBuyTicket} ref={PurchaseBtn}>
                            Purchase
                            <img src="/assets/image/arrow4.svg" alt="" />
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default BuyTicket;