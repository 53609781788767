
const initialState = {
    siteCoin: {
        name:"BNB",
        coin:"BNB",
        icon:"/assets/image/bnb.png",
    },
    wallet: {
        active: false,
        address: "",
        balance : 0,
    },
    lottery : {
        currentId : -1 ,
        endTime : "",
    }
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'connectWalletSite': {
            return {
                ...state,
                wallet: {
                    active: true,
                    address: action.payload
                },
            }
        }
        case 'disconnectWallet': {
            return {
                ...state,
                wallet: {
                    active: false,
                    address: ""
                },
            }
        }
        case 'updateWalletBalance': {
            return {
                ...state,
                wallet: {...state.wallet , balance : action.payload}
            }
        }
        case 'SET_LOTTERY_ID': {
            return {
                ...state,
                lottery: {...state.lottery , currentId : action.payload}
            }
        }
        default:
            return state
    }
}